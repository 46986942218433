import React, { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../LoadingMessage";
import { v } from "../utils";
import Notes from "./Notes";
import ReferralDetails from "./ReferralDetails";
import Consent from "./Consent";
import { Button, Grid, Icon, Step } from "semantic-ui-react";
import { Link, useHistory, useParams } from "react-router-dom";
import classNames from "classnames";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToasts } from "react-toast-notifications";
import { format } from "date-fns";
import ProfessionalsInvolved from "./ProfessionalsInvolved";
import ReferralCase from "./ReferralCase";
import ParentCarerDetails from "./ParentCarerDetails";
import { useTranslation } from "react-i18next";

const GET_LIST = gql`
  query {
    portalUser {
      person {
        personId
        portalReferrals {
          id
          referralDate
          referralType
          completedInd
          portalChild {
            id
            fullName
          }
          portalHubProcessQueue {
            message
          }
        }
      }
    }
  }
`;

const GET_CAV_DATA = gql`
  query ($houseNo: String, $postcode: String!) {
    searchCAV(houseNo: $houseNo, postcode: $postcode) {
      formatted
      houseNo
      unitNo
      unitName
      street
      district
      town
      county
      postcode
      easting
      northing
      leaNo
      wardCode
      wardName
      uprn
      country
      azRef
    }
  }
`;

const GET_ESTAB_DATA = gql`
  query ($query: String!) {
    searchEstab(query: $query) {
      leaNo
      dfeeNo
      estabName
      estabId
      establishmentUnitV {
        estabId
        unitType
        leaNo
        dfeeNo
      }
    }
  }
`;

const GET_DATA = gql`
  query getData($referralId: Int!) {
    lookupCodes(codeTypes: [1001, 1126, 729, 730, 5132, 6080, 5007]) {
      codeType
      codeValue
      codeDescription
    }
    portalReferral(referralId: $referralId) {
      referralType
      completedInd
      referralDate
      referralTime
      initialAction
      referralReason
      consentGiven
      consentConditions
      portalReferralProfs {
        id
        profType
        dateLastSeen
        name
        portalAddress {
          formatted
        }
      }
      portalChild {
        id
        yearGroup
        forename
        surname
        middleNames
        birthdate
        gender
        ethnicity
        currEstabLeaNo
        currEstabDfeeNo
        currEstabUnitType
        currEstabStartDate
        establishment {
          estabId
          leaNo
          dfeeNo
          estabName
          establishmentUnitV {
            estabId
            unitType
            leaNo
            dfeeNo
          }
        }
        portalNote {
          noteTitle
          noteHtml
          portalNoteDocuments {
            id
            attachedFileId
            files {
              id
              filename
              mimeType
              docSize
              base64
              application
            }
          }
        }

        portalAddressId
        portalAddress {
          uprn
          postcode
        }
      }
      portalPerson {
        id
        title
        forename
        surname
        addSameAsChildInd
        addressId
        portalAddress {
          uprn
          postcode
        }
        portalPersonContacts {
          id
          contactValue
          contactCode
          alternativeInd
          preferredInd
        }
      }
      portalPersonChild {
        id
        relationship
        responsibility
      }
    }
  }
`;

const GET_INFO_MAND = gql`
  query {
    info(infoId: "ADD_IN_MAND") {
      active
      infoText
    }
  }
`;

const schema = yup.object({
  chForename: yup
    .string()
    .required("Required")
    .max(30, "30 character limit exceeded"),
  chSurname: yup
    .string()
    .required("Required")
    .max(30, "30 character limit exceeded"),
  chMiddleNames: yup.string().nullable().max(40, "40 character limit exceeded"),
  chBirthdate: yup.string().required("Required"),
  chCurrEstabStartDate: yup.string().required("Required"),
  gender: yup.string().required("Required"),
  ethnicity: yup.string().required("Required"),
  chUprn: yup.string().required("Required"),
  pcForename: yup
    .string()
    .nullable()
    .when("action", {
      is: (val) => ["submit", ""].includes(val),
      then: yup.string().required("Required"),
    })
    .max(30, "30 character limit exceeded"),
  pcSurname: yup
    .string()
    .nullable()
    .when("action", {
      is: (val) => ["submit", ""].includes(val),
      then: yup.string().required("Required"),
    })
    .max(30, "30 character limit exceeded"),
  pcResponsibility: yup
    .string()
    .nullable()
    .when("action", {
      is: (val) => ["submit", ""].includes(val),
      then: yup.string().required("Required"),
    }),
  pcRelationship: yup
    .string()
    .nullable()
    .when("action", {
      is: (val) => ["submit", ""].includes(val),
      then: yup.string().required("Required"),
    }),
  referralDate: yup.string().when("action", {
    is: (val) => ["submit", ""].includes(val),
    then: yup.string().required("Required"),
  }),
  referralTime: yup.string().when("action", {
    is: (val) => ["submit", ""].includes(val),
    then: yup.string().required("Required"),
  }),
  referralReason: yup
    .string()
    .nullable()
    .when("action", {
      is: (val) => ["submit", ""].includes(val),
      then: yup.string().required("Required"),
    }),
  initialAction: yup
    .string()
    .nullable()
    .when("action", {
      is: (val) => ["submit", ""].includes(val),
      then: yup.string().required("Required"),
    })
    .max(500, "500 character limit exceeded"),

  noteTitle: yup
    .string()
    .nullable()
    .when("action", {
      is: (val) => ["submit", ""].includes(val),
      then: yup.string().required("Required"),
    })
    .max(250, "250 character limit exceeded"),
  noteHtml: yup
    .string()
    .nullable()
    .when("action", {
      is: (val) => ["submit", ""].includes(val),
      then: yup.string().required("Required"),
    }),
  consentGiven: yup
    .string()
    .nullable()
    .when("action", {
      is: (val) => ["submit", ""].includes(val),
      then: yup.string().required("Required"),
    }),
  consentConditions: yup
    .string()
    .nullable()
    .max(4000, "4000 character limit exceeded"),
  personContact: yup.array().of(
    yup.object({
      contactCode: yup.string().required("Required"),
      contactValue: yup.string().required("Required"),
      preferredInd: yup.string().required("Required"),
      alternativeInd: yup.string().required("Required"),
    })
  ),
  professionals: yup.array().of(
    yup.object({
      type: yup.string().required("Required"),
      name: yup
        .string()
        .required("Required")
        .max(100, "100 character limit exceeded"),
      dateLastSeen: yup.string().required("Required"),
    })
  ),
});

function ReferralsScreen() {
  const { addToast } = useToasts();
  const history = useHistory();
  const [step, setStep] = useState("case");
  const [hasUprn, setHasUprn] = useState(false);
  const [hasDfeeNo, setHasDfeeNo] = useState(false);
  const [reset, setReset] = useState(false);
  const { referralId, referralType } = useParams();
  const { t } = useTranslation();
  const [dataValid, setDataValid] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useQuery(GET_INFO_MAND, {
    onCompleted: (d) => {
      setAdditionalInfo(d);
    },
  });
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  function isValid() {
    schema.isValid(methods.getValues()).then(function (valid) {
      setDataValid(valid);
    });
  }

  function getHasUprn() {
    setHasUprn(
      methods.getValues().chUprn ? methods.getValues().chUprn.length > 0 : false
    );
  }

  function getDfeeNo() {
    setHasDfeeNo(
      methods.getValues().chCurrEstabDfeeNo
        ? methods.getValues().chCurrEstabDfeeNo.length > 0
        : false
    );
  }

  const [searchChildCAV, { loading: searchingCCAV, data: cavChildData }] =
    useLazyQuery(GET_CAV_DATA);

  const [searchParentCAV, { loading: searchingPCAV, data: cavParentData }] =
    useLazyQuery(GET_CAV_DATA);

  const [searchChildEstab, { loading: searchingEstab, data: estabChildData }] =
    useLazyQuery(GET_ESTAB_DATA);

  const [referralTime, setReferralTime] = useState(format(new Date(), "HH:mm"));

  const { loading, data } = useQuery(GET_DATA, {
    variables: { referralId },
    onCompleted: (d) => {
      methods.reset({
        referralDate: d.portalReferral.referralDate,
        initialAction: d.portalReferral.initialAction,
        referralReason: d.portalReferral.referralReason,
        childId: d.portalReferral.portalChild.id,
        personId: d.portalReferral.portalPerson.id,
        referralType: d.portalReferral.referralType,
        referralTime: d.portalReferral.referralTime,
        consentGiven: d.portalReferral.consentGiven,
        consentConditions: d.portalReferral.consentConditions,
        completedInd: d.portalReferral.completedInd,
        chForename: d.portalReferral.portalChild.forename,
        chSurname: d.portalReferral.portalChild.surname,
        chMiddleNames: d.portalReferral.portalChild.middleNames,
        currEstabDfeeNo: d.portalReferral.portalChild.currEstabDfeeNo,
        chCurrEstabDfeeNo: d.portalReferral.portalChild.currEstabDfeeNo,
        chCurrEstabLeaNo: d.portalReferral.portalChild.currEstabLeaNo,
        chCurrEstabUnitType: d.portalReferral.portalChild.currEstabUnitType,
        gender: d.portalReferral.portalChild.gender,
        chBirthdate: d.portalReferral.portalChild.birthdate,
        chCurrEstabStartDate: d.portalReferral.portalChild.currEstabStartDate,
        ethnicity: d.portalReferral.portalChild.ethnicity,
        chUprn: d.portalReferral.portalChild.portalAddress.uprn,
        chPortalAddressId: d.portalReferral.portalChild.portalAddressId,
        pcTitle: d.portalReferral.portalPerson.title,
        pcForename: d.portalReferral.portalPerson.forename,
        pcSurname: d.portalReferral.portalPerson.surname,
        pcAddSameAsChildInd:
          d.portalReferral.portalPerson.addSameAsChildInd !== ""
            ? d.portalReferral.portalPerson.addSameAsChildInd
            : "Y",
        pcRelationship: d.portalReferral.portalPersonChild.relationship,
        pcResponsibility: d.portalReferral.portalPersonChild.responsibility,
        pcPortalAddressId: d.portalReferral.portalPerson.portalAddressId,
        pcUprn: d.portalReferral.portalPerson.portalAddress.uprn,
        noteTitle: d.portalReferral.portalChild.portalNote.noteTitle,
        noteHtml: d.portalReferral.portalChild.portalNote.noteHtml,
      });
      searchChildCAV({
        variables: {
          postcode: d.portalReferral.portalChild.portalAddress.postcode,
        },
      });
      searchChildEstab({
        variables: {
          query: d.portalReferral.portalChild.currEstabDfeeNo,
        },
      });
      if (d.portalReferral.portalPerson.addSameAsChildInd !== "Y") {
        searchParentCAV({
          variables: {
            postcode: d.portalReferral.portalPerson.portalAddress.postcode,
          },
        });
      }
      referralId.valueOf() !== "new" ? setHasUprn(true) : setHasUprn(false);
      referralId.valueOf() !== "new" ? setHasDfeeNo(true) : setHasDfeeNo(false);
      setReferralTime(d.portalReferral.referralTime);
    },
  });

  const [completePortalReferral, { loading: completingReferral }] = useMutation(
    gql`
      mutation ($referralId: Int!) {
        completePortalReferral(referralId: $referralId) {
          id
          completedInd
        }
      }
    `,
    {
      onCompleted: () => {
        methods.reset({ completedInd: "Y" });
        addToast(t("Referral submitted"), { appearance: "success" });
      },
      onError: (error) => {
        console.log(error);
        addToast(t("Referral not submitted"), { appearance: "success" });
      },
    }
  );

  const handleComplete = (data) => {
    completePortalReferral({
      variables: {
        referralId: referralId.valueOf(),
      },
    });
  };

  const refetch = [];

  if (referralId !== "new") {
    refetch.push({
      query: GET_DATA,
      variables: { referralId: referralId.valueOf() },
    });
  }

  const [createPortalReferral, { loading: creatingReferral }] = useMutation(
    gql`
      mutation ($input: CreateReferralInput!, $parts: [Upload]!) {
        createPortalReferral(input: $input, parts: $parts) {
          id
          referralType
          portalReferralProfs {
            id
            profType
            dateLastSeen
            name
          }
          portalChild {
            id
            portalNote {
              noteTitle
              noteHtml
              portalNoteDocuments {
                id
                attachedFileId
                files {
                  id
                  filename
                  mimeType
                  docSize
                  base64
                  application
                }
              }
            }
          }
          portalPerson {
            id
            portalPersonContacts {
              id
              contactValue
              contactCode
              alternativeInd
              preferredInd
            }
          }
        }
      }
    `,
    {
      onCompleted: ({ createPortalReferral: { id, referralType } }) => {
        if (!isSubmitting) {
          addToast(t("Referral saved"), { appearance: "success" });
        }
        setIsSubmitting(false);
        history.push(`/referral/${id}/${referralType}`);
      },
      onError: (error) => {
        console.log(error);
        addToast(t("Referral not saved"), { appearance: "error" });
      },

      refetchQueries: refetch.concat({ query: GET_LIST }),
    }
  );

  const handleCreate = (data) => {
    createPortalReferral({
      variables: {
        input: {
          id: referralId.valueOf() === "new" ? 0 : referralId.valueOf(),
          referralType: referralType.valueOf(),
          referralReason: data.referralReason,
          referralTime: data.referralTime ? data.referralTime : referralTime,
          referralDate: data.referralDate ? data.referralDate : new Date(),
          initialAction: data.initialAction,
          noteTitle: data.noteTitle,
          noteHtml: data.noteHtml,
          consentGiven: data.consentGiven,
          consentConditions: data.consentConditions,
          nonConsentingAgencies: data.nonConsentingAgencies,
          portalChild: {
            id: Number(data.childId),
            portalAddressId: Number(data.chPortalAddressId),
            forename: data.chForename,
            surname: data.chSurname,
            middleNames: data.chMiddleNames,
            currEstabDfeeNo: data.currEstabDfeeNo ? data.currEstabDfeeNo : null,
            currEstabLeaNo: data.chCurrEstabLeaNo
              ? data.chCurrEstabLeaNo
              : null,
            currEstabStartDate: data.currEstabStartDate
              ? data.currEstabStartDate
              : new Date(),
            currEstabUnitType: data.chCurrEstabUnitType,
            birthdate: data.chBirthdate ? data.chBirthdate : new Date(),
            gender: data.gender,
            ethnicity: data.ethnicity,
            address: {
              houseNo: data.chHouseNo,
              unitNo: data.chUnitNo,
              unitName: data.chUnitName,
              street: data.chStreet,
              district: data.chDistrict,
              town: data.chTown,
              county: data.chCounty,
              postcode: data.chPostcode,
              easting: Number(data.chEasting),
              northing: Number(data.chNorthing),
              leaNo: Number(data.chLeaNo),
              uprn: data.chUprn,
            },
          },
          portalPerson: {
            id: Number(data.personId),
            title: data.pcTitle,
            forename: data.pcForename,
            surname: data.pcSurname,
            responsibility: data.pcResponsibility,
            relationship: data.pcRelationship,
            sameAddressAsChild: data.pcAddSameAsChildInd,
            portalPersonContact: !!data.personContact
              ? data.personContact.map((pc) => ({
                  contactCode: pc.contactCode,
                  contactValue: pc.contactValue,
                  preferredInd: pc.preferredInd,
                  alternativeInd: pc.alternativeInd,
                }))
              : [],
            address: {
              houseNo: data.pcHouseNo,
              unitNo: data.pcUnitNo,
              unitName: data.pcUnitName,
              street: data.pcStreet,
              district: data.pcDistrict,
              town: data.pcTown,
              county: data.pcCounty,
              postcode: data.pcPostcode,
              easting: Number(data.pcEasting),
              northing: Number(data.pcNorthing),
              leaNo: Number(data.pcLeaNo),
              uprn: data.pcUprn,
            },
          },
          referralProfs: v(data, "professionals", []).map((referralProf) => ({
            profType: referralProf.type,
            profName: referralProf.name,
            dateLastSeen: referralProf.dateLastSeen,
            address: {
              houseNo: referralProf.houseNo,
              unitNo: referralProf.unitNo,
              unitName: referralProf.unitName,
              street: referralProf.street,
              district: referralProf.district,
              town: referralProf.town,
              county: referralProf.county,
              postcode: referralProf.postcode,
              easting: Number(referralProf.easting),
              northing: Number(referralProf.northing),
              leaNo: Number(referralProf.leaNo),
              uprn: referralProf.uprn,
            },
          })),
        },
        parts: !data.files
          ? []
          : data.files.map(({ file }) => file).filter((file) => file !== ""),
      },
    }).then((result) => {
      remove();
      methods.setValue("action", "submit");
      isValid();
      profArray.remove();
    });
  };

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "personContact",
  });

  const profArray = useFieldArray({
    control: methods.control,
    name: "professionals",
  });

  if (loading) return <LoadingMessage />;
  isValid();

  return (
    <div>
      <Grid>
        <Grid.Column width={4}>
          <Step.Group vertical>
            <Step active={step === "case"} onClick={() => setStep("case")}>
              <Step.Content>
                <Step.Title>{t("Case Details")}</Step.Title>
                <Step.Description>
                  {t("Please enter case details")}
                </Step.Description>
              </Step.Content>
            </Step>
            <Step
              active={step === "pcDetails"}
              onClick={() => setStep("pcDetails")}
              disabled={referralId === "new"}
            >
              <Step.Content>
                <Step.Title>{t("Parent/Carer Details")}</Step.Title>
                <Step.Description>
                  {t("Please enter parent/carer details")}
                </Step.Description>
              </Step.Content>
            </Step>
            <Step
              active={step === "details"}
              onClick={() => setStep("details")}
              disabled={referralId === "new"}
            >
              <Step.Content>
                <Step.Title>{t("Referral Details")}</Step.Title>
                <Step.Description>
                  {t("Enter the referral details")}
                </Step.Description>
              </Step.Content>
            </Step>
            <Step
              active={step === "notes"}
              onClick={() => setStep("notes")}
              disabled={referralId === "new"}
            >
              <Step.Content>
                <Step.Title>{t("Additional Information")}</Step.Title>
                <Step.Description>{t("Add notes")}</Step.Description>
              </Step.Content>
            </Step>
            <Step
              active={step === "professionals"}
              onClick={() => setStep("professionals")}
              disabled={referralId === "new"}
            >
              <Step.Content>
                <Step.Title>{t("Professionals Involved")}</Step.Title>
                <Step.Description>
                  {t("Enter known professionals")}
                </Step.Description>
              </Step.Content>
            </Step>
            <Step
              active={step === "consent"}
              onClick={() => setStep("consent")}
              disabled={referralId === "new"}
            >
              <Step.Content>
                <Step.Title>{t("Consent")}</Step.Title>
                <Step.Description>
                  {t("Enter consent obtained")}
                </Step.Description>
              </Step.Content>
            </Step>
          </Step.Group>
          {methods.watch("completedInd") !== "Y" && (
            <Button
              type="button"
              onClick={() => {
                methods.setValue("action", "save");
                methods.handleSubmit(handleCreate)();
              }}
              disabled={creatingReferral}
              className={"green"}
            >
              <Icon name="save outline" />
              {t("Save")}
            </Button>
          )}
          <Button
            onClick={() => {
              setReset(true);
            }}
            className={"orange"}
            as={Link}
            to={"/referral"}
          >
            <Icon name="arrow circle left" />
            {t("Back")}
          </Button>
          {methods.watch("completedInd") !== "Y" && (
            <Button
              style={{ marginTop: ".5rem" }}
              className={"green"}
              onClick={() => {
                setIsSubmitting(true);
                methods.handleSubmit(handleCreate)();
                methods.setValue("action", "submit");
                return methods.handleSubmit(handleComplete)();
              }}
              disabled={!dataValid || completingReferral}
            >
              <Icon name="check circle" />
              {t("Submit")}
            </Button>
          )}
        </Grid.Column>

        <Grid.Column width={12}>
          <FormProvider {...methods}>
            <input type="text" name="action" ref={methods.register} hidden />
            <div
              className={classNames({
                hidden: step !== "case",
              })}
            >
              <input type="text" hidden ref={methods.register} name="foo" />
              <ReferralCase
                searchCAV={searchChildCAV}
                searchEstab={searchChildEstab}
                searching={searchingCCAV}
                searchingEstab={searchingEstab}
                setReset={setReset}
                reset={reset}
                cavData={cavChildData}
                estabChildData={estabChildData}
                referralId={referralId}
                create={methods.handleSubmit(handleCreate)}
                creatingReferral={creatingReferral}
                getHasUprn={getHasUprn}
                getDfeeNo={getDfeeNo}
                methods={methods}
              />
            </div>
            <div
              className={classNames({
                hidden: step !== "pcDetails",
              })}
            >
              <ParentCarerDetails
                fields={fields}
                append={append}
                remove={remove}
                portalPerson={v(data, "portalReferral.portalPerson", {})}
                searchCAV={searchParentCAV}
                searching={searchingPCAV}
                cavData={cavParentData}
                referralId={referralId}
              />
            </div>
            <div
              className={classNames({
                hidden: step !== "details",
              })}
            >
              <ReferralDetails
                update={methods.handleSubmit(handleCreate)}
                referralTime={referralTime}
                setReferralTime={setReferralTime}
              />
            </div>
            <div
              className={classNames({
                hidden: step !== "notes",
              })}
            >
              <Notes
                files={v(data, "portalReferral.portalChild.portalNote", {})}
                additionalInfo={additionalInfo}
              />
            </div>
            <div
              className={classNames({
                hidden: step !== "professionals",
              })}
            >
              <ProfessionalsInvolved
                professional={v(data, "portalReferral", {})}
                profArray={profArray}
                referralId={referralId}
              />
            </div>
            <div
              className={classNames({
                hidden: step !== "consent",
              })}
            >
              <Consent lookupCodes={v(data, "lookupCodes", [])} />
            </div>
          </FormProvider>
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default ReferralsScreen;
