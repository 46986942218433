import React from "react";
import { gql } from "apollo-boost";
import { Menu } from "semantic-ui-react";
import { useQuery } from "@apollo/react-hooks";
import { NavLink, Route, Switch, useParams } from "react-router-dom";
import LoadingMessage from "../LoadingMessage";
import PersonalDetails from "./PersonalDetails";
import AdviceList from "./AdviceList";
import Advice from "./Advice";
import PlanList from "./PlanList";
import Plan from "./Plan";
import ContributionsScreen from "./ContributionsScreen";
import {useTranslation} from "react-i18next";
import Dropdown from "../Dropdown";
import EducationHistory from "./EducationHistory";

const QUERY = gql`
  query($pupilId: Int!) {
    pupil(pupilId: $pupilId) {
      pupilId
      fullName
    }
  }
`;

function Case() {
  const { pupilId } = useParams();
  const { t } = useTranslation();

  const { loading, data } = useQuery(QUERY, {
    variables: {
      pupilId,
    },
  });

  if (loading) return <LoadingMessage />;

  return (
    <React.Fragment>
      <Menu>
        <Menu.Item header>{data.pupil.fullName}</Menu.Item>
      </Menu>
      <Menu secondary pointing>
        <Menu.Item as={NavLink} to={`/cases/${pupilId}/personal`}>
          {t('Personal')}
        </Menu.Item>
        <Menu.Item as={NavLink} to={`/cases/${pupilId}/education-history`}>
          {t("Education History")}
        </Menu.Item>
        <Menu.Item as={NavLink} to={`/cases/${pupilId}/advice`}>
          {t('Advice')}
        </Menu.Item>
        <Menu.Item as={NavLink} to={`/cases/${pupilId}/plan`}>
          {t('Plans')}
        </Menu.Item>
        <Menu.Item as={NavLink} to={`/cases/${pupilId}/contributions`}>
          {t('Contributions')}
        </Menu.Item>
      </Menu>
      <Switch>
        <Route path="/cases/:pupilId/personal">
          <PersonalDetails />
        </Route>
        <Route path="/cases/:pupilId/education-history">
          <EducationHistory />
        </Route>
        <Route path="/cases/:pupilId/advice" exact>
          <AdviceList Dropdowns={Dropdown} />
        </Route>
        <Route path="/cases/:pupilId/advice/:adviceId">
          <Advice />
        </Route>
        <Route path="/cases/:pupilId/plan" exact>
          <PlanList />
        </Route>
        <Route path="/cases/:pupilId/plan/:planId">
          <Plan />
        </Route>
        <Route path="/cases/:pupilId/contributions">
          <ContributionsScreen Dropdowns={Dropdown}/>
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default Case;
