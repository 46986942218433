import React, { useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Message,
  Pagination,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../LoadingMessage";
import dateFormatter from "../DateFormatter";
import LookupCode from "../LookupCode";
import { useTranslation } from "react-i18next";
import HelpText from "../components/HelpText";
import { lookupToOptions, v } from "../utils";
import { mergeDeepRight } from "ramda";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../Input";

const QUERY = gql`
  query GetAdvice(
    $pupilId: Int!
    $page: Int!
    $size: Int!
    $processStage: String
    $adviceType: String
    $dateRequested: LocalDate
    $dateDue: LocalDate
  ) {
    lookupCodes(codeTypes: [6062, 111, 6054]) {
      codeType
      codeValue
      codeDescription
    }
    pupil(pupilId: $pupilId) {
      senProcessAdvicesPage(
        page: $page
        size: $size
        processStage: $processStage
        adviceType: $adviceType
        dateRequested: $dateRequested
        dateDue: $dateDue
      ) {
        number
        totalPages
        content {
          ... on SENProcessAdvice {
            id
            processStage
            adviceType
            dateRequested
            dateDue
            dateReminderSent
            contributionType
          }
        }
      }
    }
  }
`;

function AdviceList({ Dropdowns }) {
  const { pupilId } = useParams();
  const { t } = useTranslation();
  const searchForm = useForm();
  const [pageSize, setPageSize] = useState(10);

  const { loading, data, fetchMore } = useQuery(QUERY, {
    variables: {
      pupilId,
      size: pageSize,
      page: 0,
      processStage: null,
      adviceType: null,
      dateRequested: null,
      dateDue: null,
    },
  });

  const submitSearch = ({
    processStage,
    adviceType,
    dateRequested,
    dateDue,
  }) => {
    fetchMore({
      variables: {
        pupilId,
        size: pageSize,
        page: 0,
        processStage: processStage ? processStage : null,
        adviceType: adviceType ? adviceType : null,
        dateRequested: dateRequested ? dateRequested : null,
        dateDue: dateDue ? dateDue : null,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return mergeDeepRight(prev, {
          pupil: {
            senProcessAdvicesPage: fetchMoreResult.pupil.senProcessAdvicesPage,
          },
        });
      },
    });
  };

  if (loading) return <LoadingMessage />;

  if (v(data, "pupil.senProcessAdvicesPage.content", []).length <= 0)
    return (
      <div>
        <FormProvider {...searchForm}>
          <Form
            onSubmit={searchForm.handleSubmit(submitSearch)}
            style={{ marginBottom: 14 }}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column width={3}>
                  <Dropdowns
                    name="processStage"
                    label={t("Advice Stage")}
                    options={lookupToOptions(6062)(v(data, "lookupCodes", []))}
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Dropdowns
                    name="adviceType"
                    label={t("Advice Type")}
                    options={lookupToOptions(111)(v(data, "lookupCodes", []))}
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Input
                    label={t("Requested Date")}
                    name={"dateRequested"}
                    type="date"
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Input label={t("Date Due")} name={"dateDue"} type="date" />
                </Grid.Column>
                <Grid.Column width={3} style={{ marginTop: "5" }}>
                  <br />
                  <Button icon color={"green"} type={"submit"}>
                    <Icon name="search" />
                  </Button>
                  <Popup
                    trigger={
                      <Button
                        icon
                        color={"red"}
                        type="submit"
                        onClick={() => {
                          searchForm.reset({
                            processStage: "",
                            adviceType: "",
                            dateRequested: "",
                            dateDue: "",
                          });
                        }}
                      >
                        <Icon name="remove circle" />
                      </Button>
                    }
                    content={t("Clear")}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormProvider>
        <Message info icon color='purple'>
          <Icon name="info" />
          <Message.Header>{t("No records have been found")}</Message.Header>
        </Message>
      </div>
    );

  return (
    <div>
      <>
        {" "}
        <FormProvider {...searchForm}>
          <Form
            onSubmit={searchForm.handleSubmit(submitSearch)}
            style={{ marginBottom: 14 }}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column width={3}>
                  <Dropdowns
                    name="processStage"
                    label={t("Advice Stage")}
                    options={lookupToOptions(6062)(v(data, "lookupCodes", []))}
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Dropdowns
                    name="adviceType"
                    label={t("Advice Type")}
                    options={lookupToOptions(111)(v(data, "lookupCodes", []))}
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Input
                    label={t("Requested Date")}
                    name={"dateRequested"}
                    type="date"
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Input label={t("Date Due")} name={"dateDue"} type="date" />
                </Grid.Column>
                <Grid.Column width={3} style={{ marginTop: "5" }}>
                  <br />
                  <Button icon color={"green"} type={"submit"}>
                    <Icon name="search" />
                  </Button>
                  <Popup
                      trigger={
                        <Button
                            icon
                            color={"red"}
                            type="submit"
                            onClick={() => {
                              searchForm.reset({
                                processStage: "",
                                adviceType: "",
                                dateRequested: "",
                                dateDue: "",
                              });
                            }}
                        >
                          <Icon name="remove circle" />
                        </Button>
                      }
                      content={t("Clear")}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormProvider>
      </>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("Advice ID")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Advice Stage")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Advice Type")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Requested Date")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Date Due")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Reminder Date")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Contribution Type")}</Table.HeaderCell>
            <Table.HeaderCell>
              {t("Actions")}
              <HelpText
                contentModule="Cases"
                contentType="HELP"
                contentArea="Case"
                contentItem="Advice List"
                accessLevel="Private"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {v(data, "pupil.senProcessAdvicesPage.content", []).map(
            (senProcessAdvice) => (
              <Table.Row key={senProcessAdvice.id}>
                <Table.Cell>{senProcessAdvice.id}</Table.Cell>
                <Table.Cell>
                  <LookupCode
                    codeType={6062}
                    codeValue={senProcessAdvice.processStage}
                  />
                </Table.Cell>
                <Table.Cell>
                  <LookupCode
                    codeType={111}
                    codeValue={senProcessAdvice.adviceType}
                  />
                </Table.Cell>
                <Table.Cell>
                  {dateFormatter(senProcessAdvice.dateRequested)}
                </Table.Cell>
                <Table.Cell>
                  {dateFormatter(senProcessAdvice.dateDue)}
                </Table.Cell>
                <Table.Cell>
                  {dateFormatter(senProcessAdvice.dateReminderSent)}
                </Table.Cell>
                <Table.Cell>
                  <LookupCode
                    codeType={6054}
                    codeValue={senProcessAdvice.contributionType}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Button
                    size="mini"
                    as={Link}
                    className={"blue"}
                    to={`/cases/${pupilId}/advice/${senProcessAdvice.id}`}
                  >
                    {t("View")}
                  </Button>
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <br />
            <br />
            <Pagination
              boundaryRange={1}

              activePage={data.pupil?.senProcessAdvicesPage.number + 1}
              siblingRange={1}
              totalPages={data.pupil?.senProcessAdvicesPage.totalPages}
              onPageChange={(e, { activePage }) =>
                fetchMore({
                  variables: {
                    page: activePage - 1,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;

                    return mergeDeepRight(prev, {
                      pupil: {
                        senProcessAdvicesPage:
                          fetchMoreResult.pupil.senProcessAdvicesPage,
                      },
                    });
                  },
                })
              }
            />
          </Grid.Column>
          <Grid.Column width={2} style={{ textAlign: "center" }}>
            <label style={{ fontSize: "0.9rem", color: "#81929B" }}>
              {t("Record per Page")}
            </label>
            <Segment style={{ padding: "10px" }}>
              <Dropdown
                value={pageSize}
                name="pageSize"
                options={[
                  { value: 10, text: "10" },
                  { value: 25, text: "25" },
                  { value: 50, text: "50" },
                  { value: 100, text: "100" },
                ]}
                onChange={(_, { value }) => setPageSize(value)}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default AdviceList;
