import React from "react";
import { Button, Icon, Segment, Table } from "semantic-ui-react";
import { useFormContext } from "react-hook-form";
import ProfessionalInvolvedDetails from "./ProfessionalInvolvedDetails";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { v } from "../utils";
import LookupCode from "../LookupCode";
import { Trans, useTranslation } from "react-i18next";
import dateFormatter from "../DateFormatter";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import HelpText from "../components/HelpText";

const GET_DATA = gql`
  query {
    lookupCodes(codeTypes: [6080]) {
      codeType
      codeValue
      codeDescription
    }
  }
`;

const GET_PROFS = gql`
  query($referralId: Int!) {
    portalReferral(referralId: $referralId) {
      portalReferralProfs {
        id
        profType
        dateLastSeen
        name
        portalAddress {
          formatted
        }
      }
    }
  }
`;

function ProfessionalsInvolved({ professional, profArray, referralId }) {
  const { data } = useQuery(GET_DATA);
  const { control, errors, setValue, register } = useFormContext();
  const { t } = useTranslation();

  const [deletePortalReferralProf, { loading: deleting }] = useMutation(
    gql`
      mutation DeletePortalReferralProf($id: Int!) {
        deletePortalReferralProf(id: $id)
      }
    `,
    {
      refetchQueries: [{ query: GET_PROFS, variables: { referralId } }],
    }
  );

  return (
    <React.Fragment>
      <h5>
        <Trans t={t} i18nKey="All fields marked with a * are mandatory, you will not be able to submit the referral until these fields have been completed.">
          All fields marked with a <span style={{color: 'red'}}>*</span> are mandatory, you will not be able to submit the referral until these fields have been completed.
        </Trans>
      </h5>
      <Segment attached="top">
        <h3>
          {t("Professionals Involved")}
          <HelpText
            contentModule="Referrals"
            contentType="HELP"
            contentArea="Referrals"
            contentItem="Professionals Involved"
            accessLevel="Private"
          />
        </h3>
      </Segment>
      <Segment attached>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("Professional Type")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Address")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Date Last Seen")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Actions")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {v(professional, "portalReferralProfs", []).map((prof) => (
              <Table.Row key={prof.id}>
                <Table.Cell>
                  {<LookupCode codeType={6080} codeValue={prof.profType} />}
                </Table.Cell>
                <Table.Cell>{prof.name}</Table.Cell>
                <Table.Cell>{prof.portalAddress.formatted}</Table.Cell>
                <Table.Cell>{dateFormatter(prof.dateLastSeen)}</Table.Cell>
                <Table.Cell collapsing textAlign="center">
                  <DeleteConfirmationModal
                    onDelete={() =>
                      deletePortalReferralProf({
                        variables: { id: prof.id },
                      })
                    }
                  >
                    <Button icon size="tiny" disabled={deleting} color="red">
                      <Icon name="trash" />
                    </Button>
                  </DeleteConfirmationModal>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <br />
        <Button onClick={profArray.append} style={{backgroundColor:'#9D3293', color:'#FFF'}}>{t("Add Professional")}</Button>
      </Segment>

      {profArray.fields.map((field, index) => (
        <Segment>
          <ProfessionalInvolvedDetails
            key={field.id}
            field={field}
            index={index}
            control={control}
            errors={errors}
            setValue={setValue}
            register={register}
            remove={profArray.remove}
            lookupCodes={data.lookupCodes}
          />
        </Segment>
      ))}
    </React.Fragment>
  );
}

export default ProfessionalsInvolved;
