import React, { useState, useEffect } from "react";
import { Button, Divider, Form, Message } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import request, { setAuthorizationHeader } from "./request";
import fatherhood from "./assets/fatherhood.svg";
import { useTranslation } from "react-i18next";
import { useQuery } from "./utils";
import LanguageList from "./LanguageList";

function MfaScreen() {
  const { t } = useTranslation();
  const query = useQuery();
  const history = useHistory();

  const [otp, setOtp] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [error, setError] = useState("");

  const mfa = () => {
    setIsAuthenticating(true);
    request
      .post("/api/mfa", { token: query.get("token"), otp })
      .then(({ data }) => {
        setIsAuthenticating(false);
        setError("");
        setAuthorizationHeader(data);
        history.push("/");
      })
      .catch(() => {
        setError("Incorrect verification code, please try again.");
        setIsAuthenticating(false);
      });
  };

  useEffect(() => {
    if (!query.get("token")) {
      history.push("/login");
    }
  });

  return (
    <div className="flex">
      <div className="flex items-center min-h-screen bg-white w-1/3">
        <div className="flex-grow p-16">
          <div className="text-4xl font-bold mb-6">{t("Professionals Portal")}</div>
          <Message error={!!error}>
            <Message.Header>Verification code</Message.Header>
            {!error && <>A verification code has been sent to you.</>}
            {!!error && error}
          </Message>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              mfa();
            }}
          >
            <Form.Input
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter your verification code..."
            />
            <Button
              positive
              disabled={isAuthenticating || !otp}
              loading={isAuthenticating}
            >
              {t("Submit")}
            </Button>
          </Form>
          <div className="mt-6">
            <Link className="underline" to="/login">
              {t("Click here to return to the login screen")}
            </Link>
          </div>
          <div className="mt-6">
            <Divider />
            <LanguageList />
          </div>
        </div>
      </div>
      <div className="flex items-center bg-purple-100 w-2/3 p-16 min-h-screen">
        <img src={fatherhood} alt="" />
      </div>
    </div>
  );
}

export default MfaScreen;
