import React, { useState } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import { useFormContext } from "react-hook-form";
import Dropdown from "../Dropdown";
import Input from "../Input";
import {lookupToOptions, lookupToOptionsTranslation, v} from "../utils";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import YesNoFormatter from "../YesNoFormatter";
import LookupCode from "../LookupCode";
import { Trans, useTranslation } from "react-i18next";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import HelpText from "../components/HelpText";

const GET_DATA = gql`
  query {
    lookupCodes(codeTypes: [103, 108, 5001]) {
      codeType
      codeValue
      codeDescription
    }
  }
`;
const GET_CONTACTS = gql`
  query($referralId: Int!) {
    portalReferral(referralId: $referralId) {
      portalPerson {
        id
        title
        forename
        surname
        addSameAsChildInd
        addressId
        portalAddress {
          uprn
          postcode
        }
        portalPersonContacts {
          id
          contactValue
          contactCode
          alternativeInd
          preferredInd
        }
      }
    }
  }
`;

function ParentCarerDetails({
  portalPerson,
  searchCAV,
  searching,
  cavData,
  fields,
  append,
  remove,
  referralId,
}) {
  const [postcode, setPostcode] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const { data } = useQuery(GET_DATA);
  const { register, setValue, watch } = useFormContext();
  const { t } = useTranslation();

  const onSubmit = () => {
    searchCAV({
      variables: {
        houseNo,
        postcode,
      },
    });
  };

  const [deletePortalPersonContact, { loading: deleting }] = useMutation(
    gql`
      mutation DeletePortalPersonContact($id: Int!) {
        deletePortalPersonContact(id: $id)
      }
    `,
    {
      refetchQueries: [{ query: GET_CONTACTS, variables: { referralId } }],
    }
  );

  const yesNoOptions = [
    { value: "Y", text: t("Yes") },
    { value: "N", text: t("No") }
  ];

  return (
    <React.Fragment>
      <Grid>
        <Grid.Row style={{paddingBottom: '0px'}}>
          <Grid.Column>
            <h5>
              <Trans t={t} i18nKey="All fields marked with a * are mandatory, you will not be able to submit the referral until these fields have been completed.">
                All fields marked with a <span style={{color: 'red'}}>*</span> are mandatory, you will not be able to submit the referral until these fields have been completed.
              </Trans>
            </h5>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            <Segment attached="top">
              <h3>
                {t("Parent/Carer Details")}
                <HelpText
                  contentModule="Referrals"
                  contentType="HELP"
                  contentArea="Referrals"
                  contentItem="Parent/Carer Details"
                  accessLevel="Private"
                />
              </h3>
            </Segment>
            <Segment attached>
              <Form>
                <Dropdown
                  required
                  name="pcTitle"
                  label={t("Title")}
                  options={lookupToOptionsTranslation(103,t)(v(data, "lookupCodes", []))}
                />
                <Input required name="pcForename" label={t("First Name")} />
                <Input required name="pcSurname" label={t("Surname")} />
                <Dropdown
                  required
                  name="pcRelationship"
                  label={t("Relationship to case")}
                  options={lookupToOptionsTranslation(108,t)(v(data, "lookupCodes", []))}
                />
                <Dropdown
                  required
                  name="pcResponsibility"
                  label={t("Parental Responsibility")}
                  options={yesNoOptions}
                />
                <input name="pcPortalAddressId" hidden ref={register} />
                <input name="personId" hidden ref={register} />
              </Form>
            </Segment>
          </Grid.Column>
          <Grid.Column width={10}>
            <Segment attached="top">
              <h3>
                {t("Address")}
                <HelpText
                  contentModule="Referrals"
                  contentType="HELP"
                  contentArea="Referrals"
                  contentItem="Parent/Carer Address"
                  accessLevel="Private"
                />
              </h3>
            </Segment>
            <Segment attached>
              <Form onSubmit={onSubmit}>
                <Dropdown
                  label={t("Same address as case")}
                  options={yesNoOptions}
                  name={`pcAddSameAsChildInd`}
                  clearValue={false}
                  defaultValue="Y"
                />
                {watch("pcAddSameAsChildInd") !== "Y" && (
                  <div>
                    <Form.Input
                      label={t("House/Unit Number")}
                      value={houseNo}
                      autoComplete="off"
                      onChange={(e) => setHouseNo(e.target.value)}
                    />
                    <Form.Field style={{ marginBottom: "0rem" }}>
                      <label>{t("Postcode")}</label>
                    </Form.Field>
                    <Form.Group inline>
                      <Form.Input
                        value={postcode}
                        autoComplete="off"
                        onChange={(e) =>
                          setPostcode(e.target.value.toUpperCase())
                        }
                      />
                      <Popup
                        trigger={
                          <Form.Button
                            icon="search"
                            type="submit"
                            disabled={searching}
                            loading={searching}
                            style={{backgroundColor:'#00AEEF', color:'#FFF'}}
                          />
                        }
                        content={t("Search address")}
                      />
                    </Form.Group>

                    <Dropdown
                      label={t("Address")}
                      name="pcUprn"
                      options={v(cavData, "searchCAV", []).map(
                        ({ formatted, uprn }) => ({
                          value: uprn,
                          text: formatted,
                        })
                      )}
                      cb={(value) => {
                        const found = v(cavData, "searchCAV", []).find(
                          ({ uprn }) => uprn === value
                        );
                        if (!!found) {
                          setValue(`pcHouseNo`, found.houseNo);
                          setValue(`pcUnitNo`, found.unitNo);
                          setValue(`pcUnitName`, found.unitName);
                          setValue(`pcStreet`, found.street);
                          setValue(`pcDistrictNo`, found.district);
                          setValue(`pcTown`, found.town);
                          setValue(`pcCounty`, found.county);
                          setValue(`pcPostcode`, found.postcode);
                          setValue(`pcEasting`, found.easting);
                          setValue(`pcNorthing`, found.northing);
                          setValue(`pcLeaNo`, found.leaNo);
                          setValue(`pcOsapr`, found.osapr);
                          setValue(`pcWardCode`, found.wardCode);
                          setValue(`pcWardName`, found.wardName);
                          setValue(`pcUprn`, found.uprn);
                          setValue(`pcCountry`, found.country);
                          setValue(`pCAzRefs`, found.azRef);
                        }
                      }}
                    />
                    <input name={`pcHouseNo`} hidden ref={register} />
                    <input name={`pcUnitNo`} hidden ref={register} />
                    <input name={`pcUnitName`} hidden ref={register} />
                    <input name={`pcStreet`} hidden ref={register} />
                    <input name={`pcDistrict`} hidden ref={register} />
                    <input name={`pcTown`} hidden ref={register} />
                    <input name={`pcCounty`} hidden ref={register} />
                    <input name={`pcPostcode`} hidden ref={register} />
                    <input name={`pcEasting`} hidden ref={register} />
                    <input name={`pcNorthing`} hidden ref={register} />
                    <input name={`pcLeaNo`} hidden ref={register} />
                    <input name={`pcOsapr`} hidden ref={register} />
                    <input name={`pcWardCode`} hidden ref={register} />
                    <input name={`pcWardName`} hidden ref={register} />
                    <input name={`pcCountry`} hidden ref={register} />
                    <input name={`pcAzRefs`} hidden ref={register} />
                  </div>
                )}
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Segment attached>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t("Contact Type")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("Contact Value")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("Preferred")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("Alternative")}</Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("Actions")}
                      <HelpText
                        contentModule="Referrals"
                        contentType="HELP"
                        contentArea="Referrals"
                        contentItem="Parent/Carer Contact Details"
                        accessLevel="Private"
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {v(portalPerson, "portalPersonContacts", []).map((c) => (
                    <Table.Row key={`${c.id}`}>
                      <Table.Cell>
                        <LookupCode codeType={5001} codeValue={c.contactCode} />
                      </Table.Cell>
                      <Table.Cell>{c.contactValue}</Table.Cell>
                      <Table.Cell>
                        <YesNoFormatter value={c.preferredInd} />
                      </Table.Cell>
                      <Table.Cell>
                        <YesNoFormatter value={c.alternativeInd} />
                      </Table.Cell>
                      <Table.Cell collapsing textAlign="center">
                        <DeleteConfirmationModal
                          onDelete={() =>
                            deletePortalPersonContact({
                              variables: { id: c.id },
                            })
                          }
                        >
                          <Button
                            icon
                            size="tiny"
                            disabled={deleting}
                            color="red"
                          >
                            <Icon name="trash" />
                          </Button>
                        </DeleteConfirmationModal>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Button className={'purple'} onClick={append}>
                {t("New")}
              </Button>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            {fields.map((field, index) => {
              return (
                <Segment key={field.id}>
                  <Form>
                    <Dropdown
                      required
                      label={t("Contact Type")}
                      name={`personContact[${index}].contactCode`}
                      path={["personContact", index, "contactCode"]}
                      options={lookupToOptionsTranslation(5001,t)(data.lookupCodes)}
                    />
                    <Input
                      required
                      label={t("Contact Value")}
                      name={`personContact[${index}].contactValue`}
                      path={["personContact", index, "contactValue"]}
                    />
                    <Dropdown
                      required
                      label={t("Preferred")}
                      name={`personContact[${index}].preferredInd`}
                      path={["personContact", index, "preferredInd"]}
                      options={yesNoOptions}
                    />
                    <Dropdown
                      required
                      label={t("Alternative")}
                      name={`personContact[${index}].alternativeInd`}
                      path={["personContact", index, "alternativeInd"]}
                      options={yesNoOptions}
                    />
                  </Form>
                  <br />
                  <Button type="button" onClick={() => remove(index)} className={'red'}>
                    {t("Remove")}
                  </Button>
                </Segment>
              );
            })}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
}

export default ParentCarerDetails;
