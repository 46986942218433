import React, { useState } from "react";
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  Message,
  Pagination,
  Segment,
  Table,
  Form,
  Popup,
} from "semantic-ui-react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../LoadingMessage";
import { v } from "../utils";
import LookupCode from "../LookupCode";
import { Link } from "react-router-dom";
import dateFormatter from "../DateFormatter";
import { useTranslation } from "react-i18next";
import HelpText from "../components/HelpText";
import { FormProvider, useForm } from "react-hook-form";
import { mergeDeepRight } from "ramda";
import Input from "../Input";

const QUERY = gql`
  query GetCaseViewList(
    $page: Int!
    $size: Int!
    $query: String!
    $birthdate: LocalDate
  ) {
    lookupCodes(codeTypes: [5007, 111, 5234]) {
      codeType
      codeValue
      codeDescription
    }
    portalUser {
      person {
        pupilProfs(
          page: $page
          size: $size
          query: $query
          birthdate: $birthdate
        ) {
          number
          totalPages
          content {
            ... on PupilProf {
              pupilId
              personType
              startDate
              role
              pupil {
                forenames
                surname
                gender
                birthdate
              }
            }
          }
        }
      }
    }
  }
`;

function CaseList() {
  const { t } = useTranslation();
  const searchForm = useForm();
  const [pageSize, setPageSize] = useState(10);

  const { loading, data, fetchMore } = useQuery(QUERY, {
    variables: { page: 0, size: pageSize, query: "", birthdate: null },
    notifyOnNetworkStatusChange: true,
  });

  const submitSearch = ({ query, birthday }) => {
    fetchMore({
      variables: {
        query: query,
        birthdate: birthday ? birthday : null,
        size: pageSize,
        page: 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return mergeDeepRight(prev, {
          portalUser: {
            person: {
              pupilProfs: fetchMoreResult.portalUser.person.pupilProfs,
            },
          },
        });
      },
    });
  };


  if (loading) return <LoadingMessage />;
  if (v(data, "portalUser.person.pupilProfs.content", []).length <= 0)
    return (
      <div>
        {" "}
        <FormProvider {...searchForm}>
          <Form onSubmit={searchForm.handleSubmit(submitSearch)}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Input label={t("Name")} name="query" />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Input label={t("Birthdate")} name={"birthday"} type="date" />
                </Grid.Column>
                <Grid.Column width={4} style={{ marginTop: "5" }}>
                  <br />
                  <Button icon color={"green"} type={"submit"}>
                    <Icon name="search" />
                  </Button>
                  <Popup
                      trigger={
                        <Button
                            icon
                            color={"red"}
                            type="submit"
                            onClick={() => {
                              searchForm.reset({
                                query: "",
                                birthday: "",
                              });
                            }}
                        >
                          <Icon name="remove circle" />
                        </Button>
                      }
                      content={t("Clear")}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormProvider>
        <Message info icon color='purple'>
          <Icon name="info" />
          <Message.Header>{t("No records have been found")}</Message.Header>
        </Message>
      </div>
    );
  return (
    <React.Fragment>
      <Segment attached="top">
        <h3>{t("Cases")}</h3>
      </Segment>
      <Segment attached>
        <FormProvider {...searchForm}>
          <Form onSubmit={searchForm.handleSubmit(submitSearch)}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Input name="query" label={t("Name")} />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Input label={t("Birthdate")} name={"birthday"} type="date" />
                </Grid.Column>
                <Grid.Column width={4} style={{ marginTop: "5" }}>
                  <br />
                  <Button icon color={"green"} type={"submit"}>
                    <Icon name="search" />
                  </Button>
                  <Popup
                    trigger={
                      <Button
                        icon
                        color={"red"}
                        type="submit"
                        onClick={() => {
                          searchForm.reset({
                            query: "",
                            birthday: "",
                          });
                        }}
                      >
                        <Icon name="remove circle" />
                      </Button>
                    }
                    content={t("Clear")}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormProvider>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("Impulse ID")}</Table.HeaderCell>
              <Table.HeaderCell>{t("First Name")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Surname")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Sex")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Birthdate")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Role")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Date Assigned")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Assignment Type")}</Table.HeaderCell>
              <Table.HeaderCell>
                {t("Actions")}
                <HelpText
                  contentModule="Cases"
                  contentType="HELP"
                  contentArea="Cases List"
                  contentItem="Cases List"
                  accessLevel="Private"
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {v(data, "portalUser.person.pupilProfs.content", []).map(
              (pupilProf, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{pupilProf.pupilId}</Table.Cell>
                  <Table.Cell>{pupilProf.pupil.forenames}</Table.Cell>
                  <Table.Cell>{pupilProf.pupil.surname}</Table.Cell>
                  <Table.Cell>
                    <LookupCode
                      codeType={6187}
                      codeValue={pupilProf.pupil.gender}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {dateFormatter(pupilProf.pupil.birthdate)}
                  </Table.Cell>
                  <Table.Cell>
                    <LookupCode
                      codeType={111}
                      codeValue={pupilProf.personType}
                    />
                  </Table.Cell>
                  <Table.Cell>{dateFormatter(pupilProf.startDate)}</Table.Cell>
                  <Table.Cell>
                    <LookupCode codeType={5234} codeValue={pupilProf.role} />
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      style={{backgroundColor:'#0054A4', color:'#FFF'}}
                      className={"blue"}
                      size="tiny"
                      as={Link}
                      to={`/cases/${pupilProf.pupilId}`}
                    >
                      {t("View")}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              )
            )}
          </Table.Body>
        </Table>
      </Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <br />
            <br />
            <Pagination
              boundaryRange={1}
              activePage={data.portalUser?.person.pupilProfs.number + 1}
              siblingRange={1}
              totalPages={data.portalUser?.person.pupilProfs.totalPages}
              onPageChange={(e, { activePage }) =>
                fetchMore({
                  variables: {
                    page: activePage - 1,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;

                    return mergeDeepRight(prev, {
                      portalUser: {
                        person: {
                          pupilProfs:
                            fetchMoreResult.portalUser.person.pupilProfs,
                        },
                      },
                    });
                  },
                })
              }
            />
          </Grid.Column>
          <Grid.Column width={2} style={{ textAlign: "center" }}>
            <label style={{ fontSize: "0.9rem", color: "#81929B" }}>
              {t("Record per Page")}
            </label>
            <Segment style={{ padding: "10px" }}>
              <Dropdown
                value={pageSize}
                name="pageSize"
                options={[
                  { value: 10, text: "10" },
                  { value: 25, text: "25" },
                  { value: 50, text: "50" },
                  { value: 100, text: "100" },
                ]}
                onChange={(_, { value }) => setPageSize(value)}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
}

export default CaseList;
