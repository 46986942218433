import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./assets/tailwind/main.css";
import "./assets/fomantic/dist/semantic.min.css";
import { HashRouter, Switch, Route } from "react-router-dom";
import LoginScreen from "./LoginScreen";
import MfaScreen from "./MfaScreen";
import ForgotPassword from "./ForgotPassword";
import { setAuthorizationHeader } from "./request";
import AuthenticatedRoute from "./AuthenticatedRoute";
import AnonymousRoute from "./AnonymousRoute";
import { ToastProvider } from "react-toast-notifications";
import RegisterScreen from "./RegisterScreen";
import ActivationScreen from "./ActivationScreen";
import ResetScreen from "./ResetScreen";
import { LanguageContextProvider } from "./LanguageContext";
import { PortalContentPublicProvider } from "./PortalContent";
import RegistrationConfidentiality from "./RegistrationConfidentiality";
import CodeActivationScreen from "./CodeActivationScreen";
import InviteRegistrationScreen from "./InviteRegistration";

!!localStorage.getItem("locale")
  ? localStorage.getItem("locale")
  : localStorage.setItem("locale", "en");

setAuthorizationHeader(sessionStorage.getItem("token"));

ReactDOM.render(
  <>
    <HashRouter>
      <Switch>
        <Route path="/login">
          <AnonymousRoute>
            <LanguageContextProvider>
              <PortalContentPublicProvider>
                <LoginScreen />
              </PortalContentPublicProvider>
            </LanguageContextProvider>
          </AnonymousRoute>
        </Route>
        <Route path="/mfa">
          <AnonymousRoute>
            <LanguageContextProvider>
              <PortalContentPublicProvider>
                <MfaScreen />
              </PortalContentPublicProvider>
            </LanguageContextProvider>
          </AnonymousRoute>
        </Route>
        <Route path="/registration-confidentiality">
          <AnonymousRoute>
            <LanguageContextProvider>
              <PortalContentPublicProvider>
                <RegistrationConfidentiality />
              </PortalContentPublicProvider>
            </LanguageContextProvider>
          </AnonymousRoute>
        </Route>
        <Route path="/register">
          <AnonymousRoute>
            <LanguageContextProvider>
              <PortalContentPublicProvider>
                <RegisterScreen />
              </PortalContentPublicProvider>
            </LanguageContextProvider>
          </AnonymousRoute>
        </Route>
        <Route path="/forgot-password">
          <AnonymousRoute>
            <LanguageContextProvider>
              <PortalContentPublicProvider>
                <ForgotPassword />
              </PortalContentPublicProvider>
            </LanguageContextProvider>
          </AnonymousRoute>
        </Route>
        <Route path="/reset-password/:resetKey">
          <AnonymousRoute>
            <LanguageContextProvider>
              <ResetScreen />
            </LanguageContextProvider>
          </AnonymousRoute>
        </Route>
        <Route path="/activate/:activationKey">
          <AnonymousRoute>
            <LanguageContextProvider>
              <ActivationScreen />
            </LanguageContextProvider>
          </AnonymousRoute>
        </Route>
        <Route path="/invite-code">
          <AnonymousRoute>
            <LanguageContextProvider>
              <PortalContentPublicProvider>
                <CodeActivationScreen />
              </PortalContentPublicProvider>
            </LanguageContextProvider>
          </AnonymousRoute>
        </Route>
        <Route path="/invite-registration">
          <AnonymousRoute>
            <LanguageContextProvider>
              <PortalContentPublicProvider>
                <InviteRegistrationScreen />
              </PortalContentPublicProvider>
            </LanguageContextProvider>
          </AnonymousRoute>
        </Route>
        <Route>
          <AuthenticatedRoute>
            <ToastProvider autoDismiss autoDismissTimeout={6000}>
              <App />
            </ToastProvider>
          </AuthenticatedRoute>
        </Route>
      </Switch>
    </HashRouter>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
