import React, { useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Menu,
  Message,
  Pagination,
  Segment,
  Table,
  Popup,
} from "semantic-ui-react";
import {
  base64ToArrayBuffer,
  createAndDownloadBlobFile,
  formatSize,
  lookupFormatter,
  lookupToOptions,
  v,
} from "../utils";
import LoadingMessage from "../LoadingMessage";
import dateFormatter from "../DateFormatter";
import LookupCode from "../LookupCode";
import { useToasts } from "react-toast-notifications";
import TextArea from "../TextArea";
import FileInput from "../FileInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import HelpText from "../components/HelpText";
import styled from "styled-components";
import Input from "../Input";
import { mergeDeepRight } from "ramda";

export const TableWrapper = styled.div`
  overflow-y: scroll;
  max-height: 550px;
  padding: 0;
  margin: 0;
  border: 1px solid #e2e8f0;
  border-radius: 0.285rem;

  table.ui.table {
    border: none;
  }

  table thead {
    position: sticky;
    top: 0;
  }
`;

const GET_DATA = gql`
  query GetHomeData(
    $page: Int!
    $size: Int!
    $commPage: Int!
    $commSize: Int!
    $locationType: String
    $panelType: String
    $panelDate: LocalDate
    $commInOutType: String
    $commDate: LocalDateTime
    $inOut: String
    $subjectId: Int
  ) {
    lookupCodes(codeTypes: [5181, 6067, 6068, 6145]) {
      codeType
      codeDescription
      codeValue
    }
    standardLetters {
      letterCode
      letterDescription
    }
    standardLetterRecipients {
      recipientCode
      recipientDesc
    }
    portalUser {
      person {
        fullName
        pupilProfsList {
          pupilId
          pupil {
            fullName
          }
        }
        panelAttendees(
          page: $page
          size: $size
          locationType: $locationType
          panelType: $panelType
          panelDate: $panelDate
        ) {
          number
          totalPages
          content {
            ... on PanelAttendee {
              panelId
              chair
              person {
                fullName
              }
              panel {
                chairFullName
                startTime
                panelType
                duration
                panelDate
                isUpcoming
                locationType
                virtualUrl
                virtualText
                address {
                  formatted
                }
              }
            }
          }
        }
        serviceDefinition {
          communications(
            page: $commPage
            size: $commSize
            commInOutType: $commInOutType
            commDate: $commDate
            inOut: $inOut
            subjectId: $subjectId
          ) {
            number
            totalPages
            content {
              ... on Communication {
                id
                inOut
                commDate
                commInType
                commOutType
                commMethod
                recipientCode
                addresseeSenderName
                summary
                inOutServiceDefinition {
                  person {
                    fullName
                  }
                }
                standardLetter {
                  letterDescription
                }
                senderServiceDefinition {
                  person {
                    fullName
                  }
                }
                pupil {
                  fullName
                }
                standardLetterRecipient {
                  recipientDesc
                }
                communicationDocuments {
                  id
                  files {
                    id
                    filename
                    mimeType
                    docSize
                    base64
                    application
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GET_PANEL = gql`
  query Panel($panelId: Int!) {
    panel(panelId: $panelId) {
      panelDate
      panelType
      title
      startTime
      duration
      locationType
      virtualUrl
      locationId
      virtualText
      chairFullName
      chairPersonId
      address {
        formatted
      }
    }
  }
`;

const schema = yup.object({
  commInType: yup.string().required("Required"),
  recipientCode: yup.string().required("Required"),
  pupilId: yup.string().required("Required"),
});

function Home({ Dropdowns }) {
  const [inOut, setInOut] = useState("O");
  const [showInDetails, setShowInDetails] = useState(false);
  const [showOutDetails, setShowOutDetails] = useState(false);
  const [detailsIsNew, setDetailsIsNew] = useState(false);
  const [commDetail, setCommDetail] = useState({});
  const searchForm = useForm();

  const { addToast } = useToasts();
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const [commPageSize, commSetPageSize] = useState(10);
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const submitSearch = ({ panelType, locationType, panelDate }) => {
    fetchMore({
      variables: {
        panelType: panelType ? panelType : null,
        locationType: locationType ? locationType : null,
        panelDate: panelDate ? panelDate : null,
        size: pageSize,
        page: 0,
        commPage: 0,
        commSize: commPageSize,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return mergeDeepRight(prev, {
          portalUser: {
            person: {
              panelAttendees: fetchMoreResult.portalUser.person.panelAttendees,
            },
          },
        });
      },
    });
  };

  const submitSearchComm = ({ commInOutType, commDate, subjectId }) => {
    fetchMore({
      variables: {
        commInOutType: commInOutType || null,
        commDate: commDate || null,
        subjectId: subjectId || null,
        inOut: inOut,
        size: pageSize,
        page: 0,
        commPage: 0,
        commSize: commPageSize,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return mergeDeepRight(prev, {
          portalUser: {
            person: {
              serviceDefinition: {
                communications:
                  fetchMoreResult.portalUser.person.serviceDefinition
                    .communications,
              },
            },
          },
        });
      },
    });
  };

  const { data, loading, fetchMore } = useQuery(GET_DATA, {
    variables: {
      page: 0,
      size: pageSize,
      commPage: 0,
      commSize: commPageSize,
      locationType: null,
      panelType: null,
      panelDate: null,
      commInOutType: null,
      subjectId: null,
      commDate: null,
      inOut: inOut,
    },
  });

  const [getPanel, { data: panelData }] = useLazyQuery(GET_PANEL);

  const panel = v(panelData, "panel", {});

  const [showPanelDetail, setShowPanelDetail] = useState(false);

  const [createCommunication, { loading: isCreating }] = useMutation(
    gql`
      mutation CreateCommunication(
        $subjectId: Int!
        $commInType: String!
        $recipientCode: String!
        $summary: String!
        $parts: [Upload]!
      ) {
        createCommunication(
          subjectId: $subjectId
          commInType: $commInType
          recipientCode: $recipientCode
          summary: $summary
          parts: $parts
        ) {
          id
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: useQuery(GET_DATA, {
            variables: {
              page: 0,
              size: pageSize,
              commPage: 0,
              commSize: commPageSize,
              locationType: "",
              panelType: "",
              panelDate: null,
              commInOutType: "",
              subjectId: null,
              commDate: null,
              inOut: inOut,
            },
          }),
        },
      ],
      onCompleted: (data) => {
        setDetailsIsNew(false);
        setShowOutDetails(false);
        addToast(t("Communication sent"), { appearance: "success" });
      },
      onError: (error) => {
        error.graphQLErrors.forEach((error) => {
          addToast(t("Communication not sent"), { appearance: "error" });
        });
      },
    }
  );

  const onSubmit = ({
    commInType,
    recipientCode,
    pupilId,
    summary,
    parts = [],
  }) => {
    createCommunication({
      variables: {
        subjectId: Number(pupilId),
        commInType: commInType,
        recipientCode: recipientCode,
        summary: summary,
        parts: parts.map(({ file }) => file).filter((file) => file !== ""),
      },
    })
      .then(() => {
        setDetailsIsNew(false);
        setShowOutDetails(false);
        addToast(t("Communication sent"), { appearance: "success" });
      })
      .catch((error) => {
        error.graphQLErrors.forEach((error) => {
          addToast(t("Communication not sent"), { appearance: "error" });
        });
      });
  };

  if (loading) return <LoadingMessage />;

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={16}>
          <Segment attached="top">
            <h3>
              {t("Meetings")}
              <HelpText
                contentModule="Home"
                contentType="HELP"
                contentArea="Home screen"
                contentItem="Home Screen - Meeting"
                accessLevel="Private"
              />
            </h3>
          </Segment>
          <Segment attached>
            {v(data, "portalUser.person.panelAttendees.content", []).length <=
            0 ? (
              <div>
                <FormProvider {...searchForm}>
                  <Form onSubmit={searchForm.handleSubmit(submitSearch)}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={4}>
                          <Input
                            label={t("Date")}
                            name={"panelDate"}
                            type="date"
                          />
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Dropdowns
                            name="panelType"
                            label={t("Type")}
                            options={lookupToOptions(5181)(
                              v(data, "lookupCodes", [])
                            )}
                          />
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Dropdowns
                            name="locationType"
                            label={t("Location Type")}
                            options={lookupToOptions(6145)(
                              v(data, "lookupCodes", [])
                            )}
                          />
                        </Grid.Column>
                        <Grid.Column width={4} style={{ marginTop: "5" }}>
                          <br />
                          <Button icon color={"green"} type={"submit"}>
                            <Icon name="search" />
                          </Button>
                          <Popup
                            trigger={
                              <Button
                                icon
                                color={"red"}
                                type="submit"
                                onClick={() => {
                                  searchForm.reset({
                                    panelDate: "",
                                    panelType: "",
                                    locationType: "",
                                  });
                                }}
                              >
                                <Icon name="remove circle" />
                              </Button>
                            }
                            content={t("Clear")}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </FormProvider>
                <br />
                <Message info icon color="purple">
                  <Icon name="info" />
                  <Message.Header>
                    {t("No records have been found")}
                  </Message.Header>
                </Message>
              </div>
            ) : (
              <div>
                {!showPanelDetail && (
                  <div>
                    <FormProvider {...searchForm}>
                      <Form onSubmit={searchForm.handleSubmit(submitSearch)}>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={4}>
                              <Input
                                label={t("Date")}
                                name={"panelDate"}
                                type="date"
                              />
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Dropdowns
                                name="panelType"
                                label={t("Type")}
                                options={lookupToOptions(5181)(
                                  v(data, "lookupCodes", [])
                                )}
                              />
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Dropdowns
                                name="locationType"
                                label={t("Location Type")}
                                options={lookupToOptions(6145)(
                                  v(data, "lookupCodes", [])
                                )}
                              />
                            </Grid.Column>
                            <Grid.Column width={4} style={{ marginTop: "5" }}>
                              <br />
                              <Button icon color={"green"} type={"submit"}>
                                <Icon name="search" />
                              </Button>
                              <Popup
                                trigger={
                                  <Button
                                    icon
                                    color={"red"}
                                    type="submit"
                                    onClick={() => {
                                      searchForm.reset({
                                        panelDate: "",
                                        panelType: "",
                                        locationType: "",
                                      });
                                    }}
                                  >
                                    <Icon name="remove circle" />
                                  </Button>
                                }
                                content={t("Clear")}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Form>
                    </FormProvider>
                    <br />
                    <TableWrapper>
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>{t("Date")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("Type")}</Table.HeaderCell>
                            <Table.HeaderCell>
                              {t("Start Time")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>{t("Duration")}</Table.HeaderCell>
                            <Table.HeaderCell>
                              {t("Location Type")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>{t("Chair")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("Actions")}</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {v(
                            data,
                            "portalUser.person.panelAttendees.content",
                            []
                          )
                            .slice()
                            .sort(
                              (a, b) =>
                                new Date(b.panel.panelDate) -
                                new Date(a.panel.panelDate)
                            )
                            .map((pa) => (
                              <Table.Row key={`${pa.panelId}-${pa.serviceId}`}>
                                <Table.Cell>
                                  {dateFormatter(pa.panel.panelDate)}
                                </Table.Cell>
                                <Table.Cell>
                                  <LookupCode
                                    codeType={5181}
                                    codeValue={pa.panel.panelType}
                                  />
                                </Table.Cell>
                                <Table.Cell>{pa.panel.startTime}</Table.Cell>
                                <Table.Cell>{pa.panel.duration}</Table.Cell>
                                <Table.Cell>
                                  <LookupCode
                                    codeType={6145}
                                    codeValue={pa.panel.locationType}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  {v(pa, "panel.chairFullName", "")}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                  <Button
                                    style={{
                                      backgroundColor: "#0054A4",
                                      color: "#FFF",
                                    }}
                                    className={"blue"}
                                    onClick={() => {
                                      getPanel({
                                        variables: {
                                          panelId: pa.panelId,
                                        },
                                      });
                                      setShowPanelDetail(true);
                                    }}
                                  >
                                    {t("View")}
                                  </Button>
                                </Table.Cell>
                              </Table.Row>
                            ))}
                        </Table.Body>
                      </Table>
                    </TableWrapper>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={14}>
                          <br />
                          <br />
                          <Pagination
                            boundaryRange={1}
                            activePage={
                              data.portalUser?.person.panelAttendees.number + 1
                            }
                            siblingRange={1}
                            totalPages={
                              data.portalUser?.person.panelAttendees.totalPages
                            }
                            onPageChange={(e, { activePage }) =>
                              fetchMore({
                                variables: {
                                  page: activePage - 1,
                                },
                                updateQuery: (prev, { fetchMoreResult }) => {
                                  if (!fetchMoreResult) return prev;

                                  return mergeDeepRight(prev, {
                                    portalUser: {
                                      person: {
                                        panelAttendees:
                                          fetchMoreResult.portalUser.person
                                            .panelAttendees,
                                      },
                                    },
                                  });
                                },
                              })
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={2} style={{ textAlign: "center" }}>
                          <label
                            style={{ fontSize: "0.9rem", color: "#81929B" }}
                          >
                            {t("Record per Page")}
                          </label>
                          <Segment style={{ padding: "10px" }}>
                            <Dropdown
                              value={pageSize}
                              name="pageSize"
                              options={[
                                { value: 10, text: "10" },
                                { value: 25, text: "25" },
                                { value: 50, text: "50" },
                                { value: 100, text: "100" },
                              ]}
                              onChange={(_, { value }) => setPageSize(value)}
                            />
                          </Segment>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                )}
                {showPanelDetail && (
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <Menu attached="top">
                          <Menu.Item header>{t("Meeting Details")}</Menu.Item>
                        </Menu>
                        <Segment attached>
                          <Form>
                            <Form.Input
                              label={t("Meeting Date")}
                              value={panel.panelDate}
                              type="date"
                              readOnly
                            />
                            <Form.Input
                              label={t("Meeting Type")}
                              value={lookupFormatter(
                                data.lookupCodes,
                                panel.panelType,
                                5181
                              )}
                              readOnly
                            />
                            <Form.Input
                              label={t("Meeting Title")}
                              value={panel.title}
                              readOnly
                            />
                            <Form.Input
                              label={t("Start Time")}
                              value={panel.startTime}
                              readOnly
                            />
                            <Form.Input
                              label={t("Duration")}
                              value={panel.duration}
                              readOnly
                            />
                            <Form.Input
                              label={t("Location Type")}
                              value={lookupFormatter(
                                data.lookupCodes,
                                panel.locationType,
                                6145
                              )}
                              readOnly
                            />
                            {panel.locationType === "ADDRESS" && (
                              <Form.TextArea
                                style={{ minHeight: 135 }}
                                label={t("Location")}
                                value={panel.address.formatted}
                                readOnly
                              />
                            )}
                            {panel.locationType !== "ADDRESS" && (
                              <Form.Input
                                label={t("Location")}
                                value={panel.virtualUrl}
                                readOnly
                              />
                            )}
                            {panel.locationType !== "ADDRESS" && (
                              <Form.TextArea
                                label={t("Additional Details")}
                                value={panel.virtualText}
                                readOnly
                              />
                            )}
                            <Form.Input
                              label={t("Chair")}
                              value={
                                panel.chairFullName
                                  ? panel.chairFullName +
                                    " (" +
                                    panel.chairPersonId +
                                    ")"
                                  : ""
                              }
                              readOnly
                            />
                            <Button
                              style={{ marginBottom: "1rem" }}
                              className={"orange"}
                              onClick={() => {
                                setShowPanelDetail(false);
                              }}
                            >
                              <Icon name="arrow circle left" />
                              {t("Back")}
                            </Button>
                          </Form>
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )}
              </div>
            )}
          </Segment>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <Segment attached="top">
            <h3>
              {t("Communications")}
              <HelpText
                contentModule="Home"
                contentType="HELP"
                contentArea="Home screen"
                contentItem="Home Screen - Communications"
                accessLevel="Private"
              />
            </h3>
          </Segment>
          <Segment attached>
            {!showInDetails && !showOutDetails && (
              <Menu pointing secondary>
                <Menu.Item onClick={() => setInOut("O")} active={inOut === "O"}>
                  {t("Received")}
                </Menu.Item>
                <Menu.Item onClick={() => setInOut("I")} active={inOut === "I"}>
                  {t("Sent")}
                </Menu.Item>
              </Menu>
            )}
            {inOut === "O" && !showInDetails && !showOutDetails && (
              <div>
                <FormProvider {...searchForm}>
                  <Form onSubmit={searchForm.handleSubmit(submitSearchComm)}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={4}>
                          <Input
                            label={t("Date")}
                            name={"commDate"}
                            type="date"
                          />
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Dropdowns
                            name="commInOutType"
                            label={t("Communication Type")}
                            options={v(data, "standardLetters", []).map(
                              (slr) => ({
                                value: slr.letterCode,
                                text: slr.letterDescription,
                              })
                            )}
                          />
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Dropdowns
                            name="subjectId"
                            label={t("Case")}
                            options={v(
                              data,
                              "portalUser.person.pupilProfsList",
                              []
                            ).map(({ pupilId, pupil }) => ({
                              value: pupilId,
                              text: pupil.fullName,
                            }))}
                          />
                        </Grid.Column>
                        <Grid.Column width={4} style={{ marginTop: "5" }}>
                          <br />
                          <Button icon color={"green"} type={"submit"}>
                            <Icon name="search" />
                          </Button>
                          <Popup
                            trigger={
                              <Button
                                icon
                                color={"red"}
                                type="submit"
                                onClick={() => {
                                  searchForm.reset({
                                    commDate: "",
                                    commInOutType: "",
                                    subjectId: null,
                                  });
                                }}
                              >
                                <Icon name="remove circle" />
                              </Button>
                            }
                            content={t("Clear")}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </FormProvider>
                {v(
                  data,
                  "portalUser.person.serviceDefinition.communications.content",
                  []
                ).length <= 0 ? (
                  <Message info icon color="purple">
                    <Icon name="info" />
                    <Message.Header>
                      {t("No records have been found")}
                    </Message.Header>
                  </Message>
                ) : (
                  <div>
                    <br />
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>{t("Date")}</Table.HeaderCell>
                          <Table.HeaderCell>
                            {t("Communication")}
                          </Table.HeaderCell>
                          <Table.HeaderCell>{t("Method")}</Table.HeaderCell>
                          <Table.HeaderCell>
                            {t("Sender Type")}
                          </Table.HeaderCell>
                          {/*<Table.HeaderCell>{t("Sender")}</Table.HeaderCell>*/}
                          <Table.HeaderCell>{t("Case")}</Table.HeaderCell>
                          <Table.HeaderCell>{t("Actions")}</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {v(
                          data,
                          "portalUser.person.serviceDefinition.communications.content",
                          []
                        )
                          .filter((c) => c.inOut === "O")
                          .map((comm) => (
                            <Table.Row key={`${comm.id}`}>
                              <Table.Cell>
                                {dateFormatter(comm.commDate)}
                              </Table.Cell>
                              <Table.Cell>
                                {comm.standardLetter?.letterDescription}
                              </Table.Cell>
                              <Table.Cell>
                                <LookupCode
                                  codeType={6067}
                                  codeValue={comm.commMethod}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                {comm.standardLetterRecipient.recipientDesc}
                              </Table.Cell>
                              {/*<Table.Cell>*/}
                              {/*  {v(*/}
                              {/*    comm,*/}
                              {/*    "senderServiceDefinition.person.fullName",*/}
                              {/*    v(comm, "addresseeSenderName", "")*/}
                              {/*  )}*/}
                              {/*</Table.Cell>*/}
                              <Table.Cell>
                                {v(comm, "pupil.fullName", "")}
                              </Table.Cell>
                              <Table.Cell collapsing>
                                <Button
                                  style={{
                                    backgroundColor: "#0054A4",
                                    color: "#FFF",
                                  }}
                                  className={"blue"}
                                  onClick={() => {
                                    setShowInDetails(true);
                                    setCommDetail(comm);
                                  }}
                                >
                                  {t("View")}
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={14}>
                          <br />
                          <br />
                          <Pagination
                            boundaryRange={1}
                            activePage={
                              data.portalUser?.person.serviceDefinition
                                .communications.number + 1
                            }
                            siblingRange={1}
                            totalPages={
                              data.portalUser?.person.serviceDefinition
                                .communications.totalPages
                            }
                            onPageChange={(e, { activePage }) =>
                              fetchMore({
                                variables: {
                                  commPage: activePage - 1,
                                },
                                updateQuery: (prev, { fetchMoreResult }) => {
                                  if (!fetchMoreResult) return prev;

                                  return mergeDeepRight(prev, {
                                    portalUser: {
                                      person: {
                                        serviceDefinition: {
                                          communications:
                                            fetchMoreResult.portalUser.person
                                              .serviceDefinition.communications,
                                        },
                                      },
                                    },
                                  });
                                },
                              })
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={2} style={{ textAlign: "center" }}>
                          <label
                            style={{ fontSize: "0.9rem", color: "#81929B" }}
                          >
                            {t("Record per Page")}
                          </label>
                          <Segment style={{ padding: "10px" }}>
                            <Dropdown
                              value={commPageSize}
                              name="commPageSize"
                              options={[
                                { value: 10, text: "10" },
                                { value: 25, text: "25" },
                                { value: 50, text: "50" },
                                { value: 100, text: "100" },
                              ]}
                              onChange={(_, { value }) =>
                                commSetPageSize(value)
                              }
                            />
                          </Segment>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                )}
              </div>
            )}

            {inOut === "I" && !showInDetails && !showOutDetails && (
              <div>
                <FormProvider {...searchForm}>
                  <Form onSubmit={searchForm.handleSubmit(submitSearchComm)}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={4}>
                          <Input
                            label={t("Date")}
                            name={"commDate"}
                            type="date"
                          />
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Dropdowns
                            name="commInOutType"
                            label={t("Type")}
                            options={lookupToOptions(6068)(
                              v(data, "lookupCodes", [])
                            )}
                          />
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Dropdowns
                            name="subjectId"
                            label={t("case")}
                            options={v(
                                data,
                                "portalUser.person.pupilProfsList",
                                []
                            ).map(({ pupilId, pupil }) => ({
                              value: pupilId,
                              text: pupil.fullName,
                            }))}
                          />
                        </Grid.Column>
                        <Grid.Column width={4} style={{ marginTop: "5" }}>
                          <br />
                          <Button icon color={"green"} type={"submit"}>
                            <Icon name="search" />
                          </Button>
                          <Popup
                            trigger={
                              <Button
                                icon
                                color={"red"}
                                type="submit"
                                onClick={() => {
                                  searchForm.reset({
                                    commDate: "",
                                    commInOutType: "",
                                    subjectId: null,
                                  });
                                }}
                              >
                                <Icon name="remove circle" />
                              </Button>
                            }
                            content={t("Clear")}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </FormProvider>
                {v(
                  data,
                  "portalUser.person.serviceDefinition.communications.content",
                  []
                ).length <= 0 ? (
                  // {data.portalUser.person.serviceDefinition.communications.content
                  //   .length <= 0 ? (
                  <Message info icon color="purple">
                    <Icon name="info" />
                    <Message.Header>
                      {t("No records have been found")}
                    </Message.Header>
                  </Message>
                ) : (
                  <div>
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>{t("Date")}</Table.HeaderCell>
                          <Table.HeaderCell>
                            {t("Communication")}
                          </Table.HeaderCell>
                          <Table.HeaderCell>{t("Method")}</Table.HeaderCell>
                          <Table.HeaderCell>
                            {t("Recipient Type")}
                          </Table.HeaderCell>
                          {/*<Table.HeaderCell>{t("Recipient")}</Table.HeaderCell>*/}
                          <Table.HeaderCell>{t("Case")}</Table.HeaderCell>
                          <Table.HeaderCell>{t("Actions")}</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {v(
                          data,
                          "portalUser.person.serviceDefinition.communications.content",
                          []
                        )
                          .filter((c) => c.inOut === "I")
                          .map((comm) => (
                            <Table.Row key={`${comm.id}`}>
                              <Table.Cell>
                                {dateFormatter(comm.commDate)}
                              </Table.Cell>
                              <Table.Cell>
                                <LookupCode
                                  codeType={6068}
                                  codeValue={comm.commInType}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <LookupCode
                                  codeType={6067}
                                  codeValue={comm.commMethod}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                {comm?.standardLetterRecipient?.recipientDesc}
                              </Table.Cell>
                              {/*<Table.Cell>*/}
                              {/*  {v(*/}
                              {/*    comm,*/}
                              {/*    "senderServiceDefinition.person.fullName",*/}
                              {/*    v(comm, "addresseeSenderName", "")*/}
                              {/*  )}*/}
                              {/*</Table.Cell>*/}
                              <Table.Cell>
                                {v(comm, "pupil.fullName", "")}
                              </Table.Cell>
                              <Table.Cell collapsing>
                                <Button
                                  style={{
                                    backgroundColor: "#0054A4",
                                    color: "#FFF",
                                  }}
                                  className={"blue"}
                                  onClick={() => {
                                    setShowOutDetails(true);
                                    setCommDetail(comm);
                                  }}
                                >
                                  {t("View")}
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={14}>
                          <br />
                          <br />
                          <Pagination
                            boundaryRange={1}
                            activePage={
                              data.portalUser?.person.serviceDefinition
                                .communications.number + 1
                            }
                            siblingRange={1}
                            totalPages={
                              data.portalUser?.person.serviceDefinition
                                .communications.totalPages
                            }
                            onPageChange={(e, { activePage }) =>
                              fetchMore({
                                variables: {
                                  commPage: activePage - 1,
                                },
                                updateQuery: (prev, { fetchMoreResult }) => {
                                  if (!fetchMoreResult) return prev;

                                  return mergeDeepRight(prev, {
                                    portalUser: {
                                      person: {
                                        serviceDefinition: {
                                          communications:
                                            fetchMoreResult.portalUser.person
                                              .serviceDefinition.communications,
                                        },
                                      },
                                    },
                                  });
                                },
                              })
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={2} style={{ textAlign: "center" }}>
                          <label
                            style={{ fontSize: "0.9rem", color: "#81929B" }}
                          >
                            {t("Record per Page")}
                          </label>
                          <Segment style={{ padding: "10px" }}>
                            <Dropdown
                              value={commPageSize}
                              name="commPageSize"
                              options={[
                                { value: 10, text: "10" },
                                { value: 25, text: "25" },
                                { value: 50, text: "50" },
                                { value: 100, text: "100" },
                              ]}
                              onChange={(_, { value }) =>
                                commSetPageSize(value)
                              }
                            />
                          </Segment>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <br />
                    <br />
                  </div>
                )}
                <Button
                  onClick={() => {
                    setShowOutDetails(true);
                    setDetailsIsNew(true);
                  }}
                  className={"purple"}
                >
                  {t("New")}
                </Button>
              </div>
            )}

            {showInDetails && (
              <Form>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <Segment attached="top">
                        <h3>{t("Communication Details")}</h3>
                      </Segment>
                      <Segment attached="bottom">
                        <Form.Input
                          label={t("Date")}
                          value={commDetail.commDate}
                          type="date"
                          readOnly
                        />
                        <Form.Input
                          label={t("Communication")}
                          value={commDetail.standardLetter?.letterDescription}
                          readOnly
                        />
                        <Form.Input
                          label={t("Method")}
                          value={
                            data.lookupCodes.find(
                              (lc) =>
                                lc.codeType === 6067 &&
                                lc.codeValue === commDetail.commMethod
                            ) !== undefined
                              ? data.lookupCodes.find(
                                  (lc) =>
                                    lc.codeType === 6067 &&
                                    lc.codeValue === commDetail.commMethod
                                ).codeDescription
                              : commDetail.commMethod
                          }
                          readOnly
                        />
                        <Form.Input
                          label={t("SenderType")}
                          value={
                            commDetail.standardLetterRecipient.recipientDesc
                          }
                          readOnly
                        />
                        <Form.Input
                          label={t("Sender")}
                          value={v(
                            commDetail,
                            "senderServiceDefinition.person.fullName",
                            v(commDetail, "addresseeSenderName", "")
                          )}
                          readOnly
                        />
                        <Form.Input
                          label={t("Case")}
                          value={v(commDetail, "pupil.fullName", "")}
                          readOnly
                        />
                        <Form.TextArea
                          label={t("Summary")}
                          value={
                            commDetail.summary
                              ? commDetail.summary.replace(
                                  /<\/?[^>]+(>|$)/g,
                                  ""
                                )
                              : ""
                          }
                          readOnly
                        />
                      </Segment>

                      <Button
                        className={"orange"}
                        onClick={() => setShowInDetails(false)}
                      >
                        <Icon name="arrow circle left" />
                        {t("Back")}
                      </Button>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <Segment attached="top">
                        <h3>{t("Attachments")}</h3>
                      </Segment>
                      <Segment attached="bottom">
                        <Table>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>{t("ID")}</Table.HeaderCell>
                              <Table.HeaderCell>{t("File")}</Table.HeaderCell>
                              <Table.HeaderCell>{t("Type")}</Table.HeaderCell>
                              <Table.HeaderCell>{t("Size")}</Table.HeaderCell>
                              <Table.HeaderCell>
                                {t("Actions")}
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            {v(commDetail, "communicationDocuments", []).map(
                              (document) => (
                                <Table.Row key={document.id}>
                                  <Table.Cell>{document.files.id}</Table.Cell>
                                  <Table.Cell>
                                    {document.files.filename}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {document.files.application}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {formatSize(document.files.docSize)}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Button
                                      style={{
                                        backgroundColor: "#00AEEF",
                                        color: "#FFF",
                                      }}
                                      className={"light-blue"}
                                      onClick={() => {
                                        createAndDownloadBlobFile(
                                          base64ToArrayBuffer(
                                            document.files.base64
                                          ),
                                          document.files.filename,
                                          document.files.mimeType
                                        );
                                      }}
                                    >
                                      {t("Download")}
                                    </Button>
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            )}

            {showOutDetails && !detailsIsNew && (
              <Grid>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Segment attached="top">
                      <h3>{t("Communication Details")}</h3>
                    </Segment>
                    <Segment attached="bottom">
                      <div>
                        <Form>
                          <Form.Input
                            label={t("Date")}
                            value={commDetail.commDate}
                            type="date"
                            readOnly
                          />
                          <Form.Input
                            label={t("Communication")}
                            value={
                              data.lookupCodes.find(
                                (lc) =>
                                  lc.codeType === 6068 &&
                                  lc.codeValue === commDetail.commInType
                              ) !== undefined
                                ? data.lookupCodes.find(
                                    (lc) =>
                                      lc.codeType === 6068 &&
                                      lc.codeValue === commDetail.commInType
                                  ).codeDescription
                                : commDetail.commInType
                            }
                            readOnly
                          />
                          <Form.Input
                            label={t("Method")}
                            value={
                              data.lookupCodes.find(
                                (lc) =>
                                  lc.codeType === 6067 &&
                                  lc.codeValue === commDetail.commMethod
                              ) !== undefined
                                ? data.lookupCodes.find(
                                    (lc) =>
                                      lc.codeType === 6067 &&
                                      lc.codeValue === commDetail.commMethod
                                  ).codeDescription
                                : commDetail.commMethod
                            }
                            readOnly
                          />
                          <Form.Input
                            label={t("Recipient Type")}
                            value={v(
                              commDetail,
                              "standardLetterRecipient.recipientDesc",
                              ""
                            )}
                            readOnly
                          />
                          <Form.Input
                            label={t("Recipient")}
                            value={v(
                              commDetail,
                              "senderServiceDefinition.person.fullName",
                              v(commDetail, "addresseeSenderName", "")
                            )}
                            readOnly
                          />
                          <Form.Input
                            label={t("Case")}
                            value={v(commDetail, "pupil.fullName", "")}
                            readOnly
                          />
                          <Form.TextArea
                            label={t("Summary")}
                            value={
                              commDetail.summary
                                ? commDetail.summary.replace(
                                    /<\/?[^>]+(>|$)/g,
                                    ""
                                  )
                                : ""
                            }
                            readOnly
                          />
                          <Button
                            onClick={() => {
                              setShowOutDetails(false);
                              setDetailsIsNew(false);
                            }}
                            className={"orange"}
                          >
                            <Icon name="arrow circle left" />
                            {t("Back")}
                          </Button>
                        </Form>
                      </div>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Segment attached="top">
                      <h3>{t("Attachments")}</h3>
                    </Segment>
                    <Segment attached="bottom">
                      <Table fluid>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>{t("ID")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("File")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("Type")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("Size")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("Actions")}</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          {v(commDetail, "communicationDocuments", []).map(
                            (document) => (
                              <Table.Row key={document.id}>
                                <Table.Cell>{document.files.id}</Table.Cell>
                                <Table.Cell>
                                  {document.files.filename}
                                </Table.Cell>
                                <Table.Cell>
                                  {document.files.application}
                                </Table.Cell>
                                <Table.Cell>
                                  {formatSize(document.files.docSize)}
                                </Table.Cell>
                                <Table.Cell>
                                  <Button
                                    style={{
                                      backgroundColor: "#00AEEF",
                                      color: "#FFF",
                                    }}
                                    onClick={() => {
                                      createAndDownloadBlobFile(
                                        base64ToArrayBuffer(
                                          document.files.base64
                                        ),
                                        document.files.filename,
                                        document.files.mimeType
                                      );
                                    }}
                                    className={"light-blue"}
                                  >
                                    {t("Download")}
                                  </Button>
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
            {showOutDetails && detailsIsNew && (
              <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <Segment attached="top">
                          <h3>{t("Communication Details")}</h3>
                        </Segment>
                        <Segment attached="bottom">
                          <div>
                            <Dropdowns
                              fluid
                              selection
                              label={t("Communication")}
                              name="commInType"
                              options={data?.lookupCodes
                                .filter(({ codeType }) => codeType === 6068)
                                .map((lookupCode) => ({
                                  key: `${lookupCode.codeType}-${lookupCode.codeValue}`,
                                  value: lookupCode.codeValue,
                                  text: t(lookupCode.codeDescription),
                                }))}
                            />
                            <Dropdowns
                              fluid
                              selection
                              label={t("Recipient Type")}
                              name="recipientCode"
                              options={data?.standardLetterRecipients.map(
                                (slr) => ({
                                  value: slr.recipientCode,
                                  text: slr.recipientDesc,
                                })
                              )}
                            />

                            <Dropdowns
                              fluid
                              selection
                              label={t("Case")}
                              name="pupilId"
                              options={data?.portalUser.person.pupilProfsList.map(
                                (pupilProf) => ({
                                  value: pupilProf.pupilId,
                                  text: pupilProf.pupil.fullName,
                                })
                              )}
                            />
                            <TextArea label={t("Summary")} name="summary" />
                            <Button
                              className={"orange"}
                              onClick={() => {
                                setShowOutDetails(false);
                                setDetailsIsNew(false);
                              }}
                            >
                              <Icon name="arrow circle left" />
                              {t("Back")}
                            </Button>
                            <Button
                              className={"green"}
                              type="submit"
                              disabled={isCreating}
                            >
                              {t("Submit")}
                            </Button>
                          </div>
                        </Segment>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <Segment attached="top">
                          <h3>{t("Attachments")}</h3>
                        </Segment>
                        <Segment attached="bottom">
                          <FileInput name="parts" label={t("File")} />
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </FormProvider>
            )}
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default Home;
