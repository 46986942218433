import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormField, Segment } from "semantic-ui-react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import styled from "styled-components";
import { ContentState, convertToRaw, EditorState } from "draft-js";

const StyledSegment = styled(Segment)`
  height: ${(props) => (props.height ? props.height : "auto")};
  margin: 0rem;
  padding: 0rem !important;
`;

// Assuming v is a utility function for accessing nested properties
const v = (object, path, defaultValue) => {
  let result = object;
  for (const key of path) {
    if (result && typeof result === "object" && key in result) {
      result = result[key];
    } else {
      return defaultValue;
    }
  }
  return result || defaultValue;
};

function RichTextEditor({
  label,
  name,
  segmentHeight,
  containerStyle,
  readOnly = false,
  onChange,
  required = false,
  path = [],
}) {
  const { control, errors } = useFormContext();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [dirty, setDirty] = useState(false);

  const hasErrors = !!v(errors, !!path.length ? path : [name], "");

  const errorMessage = v(
    errors,
    !!path.length ? path.concat(["message"]) : [name, "message"],
    ""
  );

  const onEditorChange = (editorState) => {
    setEditorState(editorState);
    setDirty(true);
    onChange({
      target: {
        value: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        name,
      },
    });
  };

  const getFormattedValue = (value) => {
    const contentBlock = htmlToDraft(!!value ? value : "");
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    return EditorState.createWithContent(contentState);
  };
  const containerStyles = containerStyle || {};
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ onChange, value }) => (
        <FormField error={hasErrors} required={required}>
          {label && <label>{label}</label>}
          <StyledSegment
            height={segmentHeight}
            style={
              containerStyles && hasErrors
                ? { background: "#fff6f6", borderColor: "#e0b4b4!important" }
                : {}
            }
            className={"rich-text-editor-container"}
          >
            <div
              style={{
                maxHeight: "350px",
                minHeight: "300px",
                overflowY: "scroll",
                padding: "10px",
              }}
            >
              <Editor
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                    "colorPicker",
                    "remove",
                    "history",
                  ],
                }}
                spellCheck
                editorState={dirty ? editorState : getFormattedValue(value)}
                onEditorStateChange={(editorState) => {
                  setEditorState(editorState);
                  setDirty(true);
                  onChange(
                    draftToHtml(convertToRaw(editorState.getCurrentContent()))
                  );
                  onEditorChange(editorState);
                }}
                readOnly={readOnly}
              />
            </div>
          </StyledSegment>
          {hasErrors && (
            <span className="text-red-700 text-xs">{errorMessage}</span>
          )}
        </FormField>
      )}
    />
  );
}

export default RichTextEditor;
