import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Grid,
  Icon,
  Item,
  Message,
  Segment,
} from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import impulseNexusWheel from "./assets/impulse_nexus_wheel.png";
import request from "./request";
import { useTranslation } from "react-i18next";
import HelpText from "./components/HelpText";
import { usePublicPortalContent } from "./hooks/usePublicPortalContent";
import ConfirmationMessage from "./ConfirmationMessage";

const STATUS = {
  INIT: 0,
  IN_PROG: 1,
  DONE: 2,
};

function InviteRegistrationScreen() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [status, setStatus] = useState(STATUS.INIT);
  const [pupilBirthdate, setPupilBirthdate] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const [agencyList, setAgencyList] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [estabServiceId, setEstabServiceId] = useState(0);
  const [agencyName, setAgencyName] = useState("");
  const [activatingSuccess, setActivatingSuccess] = useState(false);
  const [email, setEmail] = useState(location.state.email);

  const { data: contactFooter } = usePublicPortalContent({
    contentModule: "Request Access",
    contentType: "HELP",
    contentArea: "Registration",
    contentItem: "Contact Us Help Text Footer",
    accessLevel: "Public",
  });

  const handleAgencyDropdownChange = (event, { value }) => {
    const agency = agencyList.find((agencyD) => agencyD.value === value);
    if (agency) {
      setEstabServiceId(agency.value);
      setAgencyName(agency.text);
    }
  };

  const agencyNameSearch = (value) => {
    request
      .get(`/api/search/agency-by-name?query=${value}`)
      .then((response) => {
        if (response.data) {
          const agencies = response.data;
          const agencyList = agencies.map((agency) => ({
            key: agency.agencyId,
            value: agency.serviceId,
            text: agency.agencyName,
            content: (
              <Item key={agency.agencyId}>
                <Item.Content>
                  <Item.Header>
                    <b>{t("Name")}:</b> {agency.agencyName}
                  </Item.Header>
                  <Item.Meta>
                    <b>{t("Impulse ID")}:</b> {agency.agencyId}
                  </Item.Meta>
                  <Item.Meta>
                    <b>{t("Department")}:</b> {agency.department}
                  </Item.Meta>
                  <Item.Meta>
                    <b>{t("UKPRN")}:</b> {agency.ukprn}
                  </Item.Meta>
                  <Item.Meta>
                    <b>{t("Type")}:</b> {agency.agencyTypeDesc}
                  </Item.Meta>
                </Item.Content>
              </Item>
            ),
          }));
          setAgencyList(agencyList);
        }
      })
      .catch(() => {
        console.log("Failed to get agency details");
      });
  };

  const submitRegistration = () => {
    setStatus(STATUS.IN_PROG);

    const data = {
      email: location.state.email,
      personId: location.state.personId,
      activationKey: location.state.activationKey,
      password: password,
      benefitClaimant: "N",
      agreeInd: location.state.agreeInd,
      pupilBirthdate: pupilBirthdate,
      linkedOrganisation: agencyName,
      estabServiceId: estabServiceId,
    };

    request
      .post("/api/activation/verify", data)
      .then((response) => {
        if (response.data === true) {
          setActivatingSuccess(true);
          setVerificationError("");
        } else {
          setVerificationError("The details you have entered cannot be verified");
        }
        setStatus(STATUS.DONE);
      })
      .catch((error) => {
        setStatus(STATUS.DONE);
        setVerificationError(error.response.data.error);
      });
  };

  useEffect(() => {
    if (activatingSuccess) {
      //re-directs to the login screen after successful registration
      const timeout = setTimeout(() => history.push("/login"), 5000);
      return () => clearTimeout(timeout);
    }
  }, [activatingSuccess, history]);

  if (activatingSuccess) {
    return <ConfirmationMessage />;
  }

  return (
    <div className="flex min-h-screen">
      <Segment attached="top">
        <div className="flex-grow text-center">
          <Grid centered columns={4}>
            <Grid.Column>
              <div className="flex items-center p-10">
                <img src={impulseNexusWheel} alt="" />
              </div>
            </Grid.Column>
          </Grid>
          <div className="text-4xl font-bold mb-6">
            {t("Professional's Portal - Registration - Confirm Professional Information")}
          </div>
          <Divider />
        </div>
        <Grid centered columns={3}>
          <Grid.Column>
            <Segment padded="very" attached="top">
              <div className="flex-grow text-center">
                <div className="text-1xl mb-6 font-bold ">
                  {t("To continue your registration, please provide the following information")}
                </div>
              </div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitRegistration();
                }}
              >
                <Message
                  verificationError
                  hidden={!verificationError}
                  onDismiss={() => setVerificationError("")}
                >
                  {t(verificationError)}
                </Message>
                <Form.Input
                  required
                  icon="user circle outline"
                  iconPosition="left"
                  label={t("Email Address")}
                  type="email"
                  value={email}
                  disabled
                  placeholder={t("DrProfessional@CACISchool.co.uk")}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Input
                  icon
                  label="Organisation Name Search"
                  name="orgName"
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                  placeholder="Enter Name..."
                >
                  <input />
                  <Icon
                    name="search"
                    link
                    onClick={() => agencyNameSearch(orgName)}
                  />
                </Form.Input>
                <Button
                  size="medium"
                  type="submit"
                  positive
                  onClick={() => agencyNameSearch(orgName)}
                >
                  <Icon name="search" link />
                  {t("Find Organisation")}
                </Button>
                <Form.Field style={{ marginBottom: "0rem" }}>
                  <label>{t("Select Organisation")}</label>
                </Form.Field>
                <Dropdown
                  fluid
                  selection
                  search
                  value={estabServiceId}
                  name="agencyList"
                  placeholder="Select From List"
                  onChange={handleAgencyDropdownChange}
                  options={agencyList}
                />
                <Form.Input
                  required
                  type="date"
                  label={t("Date of Birth of a Case You Are Responsible For")}
                  value={pupilBirthdate}
                  placeholder={t("Birthdate")}
                  onChange={(e) => setPupilBirthdate(e.target.value)}
                />
                <Form.Input
                  required
                  label={
                    <>
                      {t("Please Enter a Password")}{" "}
                      <HelpText
                        contentArea="Registration"
                        contentModule="Request Access"
                        contentType="HELP"
                        contentItem="Password Rules Help Text"
                        accessLevel="Public"
                      />
                    </>
                  }
                  value={password}
                  placeholder={t("Add password...")}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Form.Input
                  required
                  label={t("Confirm Password")}
                  value={confirmPassword}
                  placeholder={t("Re-type password...")}
                  type="password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    e.target.value && e.target.value !== password
                      ? setPasswordError("Passwords must match")
                      : setPasswordError("");
                  }}
                />
                <Message
                  passwordError
                  hidden={!passwordError}
                  onDismiss={() => setPasswordError("")}
                >
                  {t(passwordError)}
                </Message>
                <Button
                  fluid
                  type="submit"
                  positive
                  disabled={status === STATUS.IN_PROG || passwordError}
                  loading={status === STATUS.IN_PROG}
                >
                  {t("Confirm")}
                </Button>
              </Form>
            </Segment>
            <Segment>
              <div
                dangerouslySetInnerHTML={{
                  __html: contactFooter?.contentValue,
                }}
              />
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    </div>
  );
}

export default InviteRegistrationScreen;
