import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { Switch, Route, Link } from "react-router-dom";
import { Message, Table, Icon, Button } from "semantic-ui-react";
import LookupCode from "../LookupCode";
import dateFormatter from "../DateFormatter";
import LoadingMessage from "../LoadingMessage";
import {gql} from "apollo-boost";
import { useTranslation } from "react-i18next";
import HelpText from "../components/HelpText";

const QUERY = gql`
  query($pupilId: Int!) {
    lookupCodes(codeTypes: [6017]) {
      codeType
      codeValue
      codeDescription
    }
    pupil(pupilId: $pupilId) {
      pupilPlans {
        id
        issueDate
        type
        title
      }
    }
  }
`;

function PlanList() {
  const { pupilId } = useParams();
  const { t } = useTranslation();
  const { loading, data } = useQuery(QUERY, {
    variables: { pupilId }
  });

  if (loading) return <LoadingMessage />;

  if (data.pupil.pupilPlans.length <= 0)
    return (
        <Message info icon color='purple'>
          <Icon name="info" />
          <Message.Header>{t("No records have been found")}</Message.Header>
        </Message>
    );

  return (
    <React.Fragment>
      <Switch>
        <Route>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t('Title')}</Table.HeaderCell>
                <Table.HeaderCell>{t('Type')}</Table.HeaderCell>
                <Table.HeaderCell>{t('Date Issued')}</Table.HeaderCell>
                <Table.HeaderCell>
                  {t('Actions')}
                  <HelpText
                    contentModule="Cases"
                    contentType="HELP"
                    contentArea="Case"
                    contentItem="Plans List"
                    accessLevel="Private"
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.pupil.pupilPlans.map(pupilPlan => (
                <Table.Row key={pupilPlan.id}>
                  <Table.Cell>{pupilPlan.title}</Table.Cell>
                  <Table.Cell>
                    <LookupCode codeType={6017} codeValue={pupilPlan.type} />
                  </Table.Cell>
                  <Table.Cell>{dateFormatter(pupilPlan.issueDate)}</Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      style={{backgroundColor:'#0054A4', color:'#FFF'}}
                      as={Link}
                      className={"blue"}
                      to={`/cases/${pupilId}/plan/${pupilPlan.id}`}
                    >
                      {t('View')}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default PlanList;
