import React, { useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Menu,
  Message,
  Pagination,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { lookupToOptions, v } from "../utils";
import dateFormatter from "../DateFormatter";
import LookupCode from "../LookupCode";
import YesNoFormatter from "../YesNoFormatter";
import LoadingMessage from "../LoadingMessage";
import { useTranslation } from "react-i18next";
import HelpText from "../components/HelpText";
import { TableWrapper } from "../home/Home";
import Input from "../Input";
import { FormProvider, useForm } from "react-hook-form";
import { mergeDeepRight } from "ramda";

const GET_DATA = gql`
  query GetReferralData(
    $page: Int!
    $size: Int!
    $referralType: String
    $referralDate: LocalDate
    $childId: Int
  ) {
    portalReferralByReferralType {
      codeType
      codeDescription
      codeValue
    }
    portalChildByPortalReferral {
      id
      surname
      forename
      middleNames
    }
    portalContent(
      contentModule: "Referrals"
      contentType: "MENU"
      contentArea: "Referrals"
    ) {
      contentValue
      contentItem
      mappedLookup
    }
    portalUser {
      person {
        personId
        portalReferrals(
          page: $page
          size: $size
          referralType: $referralType
          referralDate: $referralDate
          childId: $childId
        ) {
          number
          totalPages
          content {
            ... on PortalReferral {
              id
              referralDate
              referralType
              completedInd
              portalChild {
                fullName
              }
              portalHubProcessQueue {
                message
              }
            }
          }
        }
      }
    }
  }
`;

function Referrals({ Dropdowns }) {
  const searchForm = useForm();
  const [showList, setShowList] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const { t } = useTranslation();

  const submitSearch = ({ referralType, referralDate, childId }) => {
    fetchMore({
      variables: {
        page: 0,
        size: pageSize,
        childId: childId || null,
        referralType: referralType ? referralType : "",
        referralDate: referralDate ? referralDate : null,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return mergeDeepRight(prev, {
          portalUser: {
            person: {
              portalReferrals:
                fetchMoreResult.portalUser.person.portalReferrals,
            },
          },
        });
      },
    });
  };

  const { loading, data, fetchMore } = useQuery(GET_DATA, {
    variables: {
      page: 0,
      size: pageSize,
      referralType: "",
      referralDate: null,
      childId: null,
    },
  });

  if (loading) return <LoadingMessage />;

  return (
    <div>
      <Menu pointing secondary>
        <Menu.Item onClick={() => setShowList(false)} active={!showList}>
          {t("New Referral")}
        </Menu.Item>
        <Menu.Item onClick={() => setShowList(true)} active={showList}>
          {t("Referrals")}
        </Menu.Item>
      </Menu>
      {showList && (
        <dvi>
          <FormProvider {...searchForm}>
            <Form onSubmit={searchForm.handleSubmit(submitSearch)}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Input
                      label={t("Referral Date")}
                      name={"referralDate"}
                      type="date"
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Dropdowns
                      name="referralType"
                      label={t("Referral Type")}
                      options={lookupToOptions(729)(v(data, "portalReferralByReferralType", []))}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Dropdowns
                      name="childId"
                      label={t("Case")}
                      options={v(data, "portalChildByPortalReferral", []).map(
                        ({ id, surname, forename, middleNames }) => ({
                          value: id,
                          text: forename + " " + surname + " (" + id + ")",
                        })
                      )}
                    />
                  </Grid.Column>
                  <Grid.Column width={4} style={{ marginTop: "5" }}>
                    <br />
                    <Button icon color={"green"} type={"submit"}>
                      <Icon name="search" />
                    </Button>
                    <Popup
                      trigger={
                        <Button
                          icon
                          color={"red"}
                          type="submit"
                          onClick={() => {
                            searchForm.reset({
                              referralDate: "",
                              referralType: "",
                              childId: null,
                            });
                          }}
                        >
                          <Icon name="remove circle" />
                        </Button>
                      }
                      content={t("Clear")}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </FormProvider>
          <br />
          
          {v(data, "portalUser.person.portalReferrals.content", []).length <=
          0 ? (
            <Message info icon color='purple'>
              <Icon name="info" />
              <Message.Header>{t("No records have been found")}</Message.Header>
            </Message>
          ) : (
            <Segment attached>
              <TableWrapper>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>{t("ID")}</Table.HeaderCell>
                      <Table.HeaderCell>{t("Referral Date")}</Table.HeaderCell>
                      <Table.HeaderCell>{t("Referral Type")}</Table.HeaderCell>
                      <Table.HeaderCell>{t("Case")}</Table.HeaderCell>
                      <Table.HeaderCell>{t("Completed")}</Table.HeaderCell>
                      <Table.HeaderCell>{t("Process State")}</Table.HeaderCell>
                      <Table.HeaderCell>
                        {t("Actions")}
                        <HelpText
                          contentModule="Referrals"
                          contentType="HELP"
                          contentArea="Referrals"
                          contentItem="Referrals List"
                          accessLevel="Private"
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {v(data, "portalUser.person.portalReferrals.content", [])
                      // .sort((a, b) => {
                      //   return new Date(a.referralDate) - new Date(b.referralDate);
                      // })
                      .map((pr) => (
                        <Table.Row key={`${pr.id}`}>
                          <Table.Cell>{pr.id}</Table.Cell>
                          <Table.Cell>
                            {dateFormatter(pr.referralDate)}
                          </Table.Cell>
                          <Table.Cell>
                            <LookupCode
                              codeType={729}
                              codeValue={pr.referralType}
                            />
                          </Table.Cell>
                          <Table.Cell>{pr.portalChild.fullName}</Table.Cell>
                          <Table.Cell>
                            <YesNoFormatter value={pr.completedInd} />
                          </Table.Cell>
                          <Table.Cell>
                            {pr.portalHubProcessQueue
                              ? pr.portalHubProcessQueue.message
                              : "Incomplete"}
                          </Table.Cell>
                          <Table.Cell collapsing>
                            {pr.completedInd === "Y" && (
                              <Button
                                as={Link}
                                className={"blue"}
                                to={`/referral/${pr.id}/${pr.referralType}`}
                              >
                                {t("View")}
                              </Button>
                            )}
                            {pr.completedInd === "N" && (
                              <Button
                                style={{backgroundColor:'#0054A4', color:'#FFF'}}
                                as={Link}
                                className={'blue'}
                                to={`/referral/${pr.id}/${pr.referralType}`}
                              >
                                {t("Resume")}
                              </Button>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </TableWrapper>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={14}>
                    <br />
                    <br />
                    <Pagination
                      boundaryRange={1}
                      activePage={
                        data.portalUser?.person.portalReferrals.number + 1
                      }
                      siblingRange={1}
                      totalPages={
                        data.portalUser?.person.portalReferrals.totalPages
                      }
                      onPageChange={(e, { activePage }) =>
                        fetchMore({
                          variables: {
                            page: activePage - 1,
                          },
                          updateQuery: (prev, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return prev;

                            return mergeDeepRight(prev, {
                              portalUser: {
                                person: {
                                  portalReferrals:
                                    fetchMoreResult.portalUser.person
                                      .portalReferrals,
                                },
                              },
                            });
                          },
                        })
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={2} style={{ textAlign: "center" }}>
                    <label style={{ fontSize: "0.9rem", color: "#81929B" }}>
                      {t("Record per Page")}
                    </label>
                    <Segment style={{ padding: "10px" }}>
                      <Dropdown
                        value={pageSize}
                        name="pageSize"
                        options={[
                          { value: 10, text: "10" },
                          { value: 25, text: "25" },
                          { value: 50, text: "50" },
                          { value: 100, text: "100" },
                        ]}
                        onChange={(_, { value }) => setPageSize(value)}
                      />
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          )}
        </dvi>
      )}
      {!showList && (
        <div>
          {v(data, "portalContent", []).map((pc) => (
            <div
              className="border rounded bg-white"
              key={`${pc.portalId}`}
              style={{ marginBottom: "1rem" }}
            >
              <div className="text-2xl p-4 tracking-tight leading-10 font-black">
                {t(pc.contentItem)}
                <HelpText
                  contentModule="Referrals"
                  contentType="HELP"
                  contentArea="Referrals"
                  contentItem="New Referral"
                  accessLevel="Private"
                />
              </div>
              <div className="p-4">
                <div className="leading-5 mb-6">{t(pc.contentValue)}</div>
              </div>
              <div className="p-4">
                <Button as={Link} to={`/referral/new/${pc.mappedLookup}`} style={{backgroundColor:'#00AEEF', color: '#fff',textShadow:'none'}}>
                  {t("Make Request")}
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Referrals;
