import React from "react";
import { Table } from "semantic-ui-react";
import { v } from "../../utils";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../../LoadingMessage";
import dateFormatter from "../../DateFormatter";
import LookupCode from "../../LookupCode";
import { useTranslation } from "react-i18next";
import HelpText from "../../components/HelpText";
const GET_DATA = gql`
  {
    portalUser {
      person {
        forename
        surname
        personAddresses {
          addressStartDate
          addressEndDate
          mainAddressInd
          addressType
          address {
            addressId
            formatted
          }
        }
      }
    }
  }
`;

function AddressList() {
  const { data, loading } = useQuery(GET_DATA);
  const { t } = useTranslation();
  if (loading) return <LoadingMessage />;

  const currentIndicator = (mainAddressInd) => {
    switch (mainAddressInd) {
      case "M":
        return "Main";
      case "P":
        return "Previous";
      case "F":
        return "Future";
      default:
        return mainAddressInd;
    }
  };

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t("Start Date")}</Table.HeaderCell>
          <Table.HeaderCell>{t("End Date")}</Table.HeaderCell>
          <Table.HeaderCell>{t("Current Indicator")}</Table.HeaderCell>
          <Table.HeaderCell>{t("Type")}</Table.HeaderCell>
          <Table.HeaderCell>
            {t("Address")}
            <HelpText
              contentModule="Profile"
              contentType="HELP"
              contentArea="Profile"
              contentItem="Address"
              accessLevel="Private"
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {v(data, "portalUser.person.personAddresses", []).map(
          ({
            addressStartDate,
            addressEndDate,
            mainAddressInd,
            address,
            addressType,
          }) => (
            <Table.Row key={address.addressId}>
              <Table.Cell>{dateFormatter(addressStartDate)} </Table.Cell>
              <Table.Cell>{dateFormatter(addressEndDate)}</Table.Cell>
              <Table.Cell>{currentIndicator(mainAddressInd)}</Table.Cell>
              <Table.Cell>
                <LookupCode codeType={5002} codeValue={addressType} />{" "}
              </Table.Cell>
              <Table.Cell>{address.formatted}</Table.Cell>
            </Table.Row>
          )
        )}
      </Table.Body>
    </Table>
  );
}

export default AddressList;
