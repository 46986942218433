import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

function Sidebar() {
  const { t } = useTranslation();
  return (
    <ul>
      <li>
        <NavLink
          to="/"
          exact
          className="ml-2 mt-2 block py-2 px-4 text-gray-600 hover:text-gray-900"
          activeClassName="text-blue-800 font-black"
        >
          <Icon name="home" />
          <span className="ml-2">{t("Home")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/cases"
          className="ml-2 mt-2 block py-2 px-4 text-gray-600 hover:text-gray-900"
          activeClassName="text-blue-800 font-black"
        >
          <Icon name="child" />
          <span className="ml-2">{t("Cases")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/tasks"
          className="ml-2 mt-2 block py-2 px-4 text-gray-600 hover:text-gray-900"
          activeClassName="text-blue-800 font-black"
        >
          <Icon name="id card" />
          <span className="ml-2">{t("Tasks")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/referral"
          className="ml-2 mt-2 block py-2 px-4 text-gray-600 hover:text-gray-900"
          activeClassName="text-blue-800 font-black"
        >
          <Icon name="sign in" />
          <span className="ml-2">{t("Referrals")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/profile"
          className="ml-2 mt-2 block py-2 px-4 text-gray-600 hover:text-gray-900"
          activeClassName="text-blue-800 font-black"
        >
          <Icon name="user" />
          <span className="ml-2">{t("Profile")}</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default Sidebar;
