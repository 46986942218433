import React, { useState } from "react";
import { Button, Form, Grid, Segment, Table } from "semantic-ui-react";
import { useToasts } from "react-toast-notifications";
import { gql } from "apollo-boost";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../LoadingMessage";
import {
  base64ToArrayBuffer,
  createAndDownloadBlobFile,
  formatSize,
  lookupFormatter,
  lookupToOptions,
  lookupToOptionsTranslation,
  v,
} from "../utils";
import dateFormatter from "../DateFormatter";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextArea from "../TextArea";
import Dropdown from "../Dropdown";
import FileInput from "../FileInput";
import Input from "../Input";
import AdviceSubmitModal from "../AdviceSubmitModal";
import { useTranslation } from "react-i18next";
import HelpText from "../components/HelpText";

const QUERY = gql`
  query ($pupilId: Int!, $adviceId: Int!) {
    lookupCodes(codeTypes: [6062, 111, 6054]) {
      codeType
      codeValue
      codeDescription
    }
    pupil(pupilId: $pupilId) {
      senProcessAdvices(adviceId: $adviceId) {
        id
        processStage
        adviceType
        dateRequested
        dateReceived
        dateDue
        dateReminderSent
        adviceDesc
        contributionType
        adviceResponse
        adviceSubmittedInd
        senProcessAdviceDocuments {
          file {
            filename
            base64
            docSize
            id
            mimeType
            application
          }
        }
      }
    }
  }
`;

const MUTATION = gql`
  mutation (
    $adviceId: Int!
    $contributionType: String!
    $adviceResponse: String!
    $adviceSubmittedInd: String!
    $files: [Upload]!
  ) {
    submitAdviceRequestResponse(
      adviceId: $adviceId
      contributionType: $contributionType
      adviceSubmittedInd: $adviceSubmittedInd
      adviceResponse: $adviceResponse
      files: $files
    ) {
      id
      dateReceived
      contributionType
      adviceSubmittedInd
      adviceResponse
      senProcessAdviceDocuments {
        file {
          filename
          base64
          docSize
          id
          mimeType
          application
        }
      }
    }
  }
`;

const schema = yup.object({
  contributionType: yup.string().required("Required"),
  adviceResponse: yup.string().required("Required"),
});

function Advice() {
  const { pupilId, adviceId } = useParams();
  const [adviceSubmittedInd, setAdviceSubmittedInd] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const { addToast } = useToasts();
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const { loading, data } = useQuery(QUERY, {
    variables: { pupilId, adviceId },
    onCompleted: (data) => {
      const senProcessAdvice = v(data, "pupil.senProcessAdvices.0", {});
      methods.reset({
        contributionType: senProcessAdvice.contributionType,
        adviceResponse: senProcessAdvice.adviceResponse,
        dateReceived: senProcessAdvice.dateReceived,
      });
    },
  });

  const [submitAdviceRequestResponse, { loading: isSaving }] = useMutation(
    MUTATION,
    {
      refetchQueries: [
        {
          query: QUERY,
          variables: {
            pupilId,
            adviceId,
          },
        },
      ],
      onCompleted: (data) => {
        const senProcessAdvice = v(data, "submitAdviceRequestResponse", {});
        methods.setValue(
          "dateReceived",
          dateFormatter(senProcessAdvice.dateReceived)
        );
        addToast(t("Advice sent"), { appearance: "success" });
      },
      onError: (error) => {
        error.graphQLErrors.forEach((error) => {
          addToast(t("Advice not sent"), { appearance: "error" });
        });
      },
    }
  );

  if (adviceSubmittedInd) {
    methods.setValue("adviceSubmittedInd", "Y");
  }

  const onSubmit = ({ contributionType, adviceResponse, files = [] }) => {
    submitAdviceRequestResponse({
      variables: {
        adviceId,
        adviceSubmittedInd:
          methods.getValues().adviceSubmittedInd === "Y"
            ? "Y"
            : v(data, "pupil.senProcessAdvices.0.adviceSubmittedInd", {}),
        contributionType,
        adviceResponse,
        files: files.map(({ file }) => file).filter((file) => file !== ""),
      },
    });
  };

  if (loading) return <LoadingMessage />;

  const senProcessAdvice = v(data, "pupil.senProcessAdvices.0", {});

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={6}>
          <Segment attached="top">
            {t("Advice Request Details")}
            <HelpText
              contentModule="Cases"
              contentType="HELP"
              contentArea="Case"
              contentItem="Advice Request Details"
              accessLevel="Private"
            />
          </Segment>
          <Segment attached>
            <Form>
              <Form.Input label={t("Advice ID")} value={senProcessAdvice.id} />
              <Form.Input
                label={t("Advice Stage")}
                value={lookupFormatter(
                  data.lookupCodes,
                  senProcessAdvice.processStage,
                  6062
                )}
              />
              <Form.Input
                label={t("Advice Type")}
                value={lookupFormatter(
                  data.lookupCodes,
                  senProcessAdvice.adviceType,
                  111
                )}
              />
              <Form.TextArea
                label={t("Advice Description")}
                value={senProcessAdvice.adviceDesc || ""}
              />
              <Form.Input
                label={t("Requested Date")}
                value={dateFormatter(senProcessAdvice.dateRequested)}
              />
              <Form.Input
                label={t("Date Due")}
                value={dateFormatter(senProcessAdvice.dateDue)}
              />
              <Form.Input
                label={t("Reminder Date")}
                value={dateFormatter(senProcessAdvice.dateReminderSent)}
              />
            </Form>
            <br />
            <Button
              className={"orange"}
              as={Link}
              to={`/cases/${pupilId}/advice`}
            >
              {t("Back")}
            </Button>
          </Segment>
        </Grid.Column>
        <Grid.Column width={10}>
          <Segment attached="top">
            {t("Advice Request Response")}
            <HelpText
              contentModule="Cases"
              contentType="HELP"
              contentArea="Case"
              contentItem="Advice Request Response"
              accessLevel="Private"
            />
          </Segment>
          <Segment attached>
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <input type="text" name="adviceSubmittedInd" ref={methods.register} hidden />
                <Input readOnly label={t("Response Date")} name="dateReceived" />
                {senProcessAdvice.adviceSubmittedInd === "N" ? (
                  <Dropdown
                    required
                    name="contributionType"
                    label={t("Contribution Type")}
                    options={lookupToOptionsTranslation(
                      6054,
                      t
                    )(data.lookupCodes)}
                  />
                ) : (
                  <Form.Input
                    label={t("Contribution Type")}
                    value={lookupFormatter(
                      data.lookupCodes,
                      senProcessAdvice.contributionType,
                      6054
                    )}
                  />
                )}
                {senProcessAdvice.adviceSubmittedInd === "N" ? (
                <TextArea
                  required
                  name="adviceResponse"
                  label={t("Advice Text")}
                  readOnly={senProcessAdvice.adviceSubmittedInd === "Y"}
                />
                ) : (
                <Form.TextArea
                    style={{height:'12em'}}
                    label={t("Advice Text")}
                    value={senProcessAdvice.adviceResponse}
                />
                    )}

                <div>
                  <FileInput
                    name="files"
                    label={t("Files")}
                    disabled={senProcessAdvice.adviceSubmittedInd === "Y"}
                  />

                  <Button
                    className={'green'}
                    type="submit"
                    onClick={() => {
                      methods.handleSubmit(onSubmit);
                    }}
                    disabled={
                      isSaving || senProcessAdvice.adviceSubmittedInd === "Y"
                    }
                    loading={isSaving}
                  >
                    {t("Save")}
                  </Button>

                  <Button
                    color="green"
                    type="button"
                    onClick={() => {
                      setOpen(true);
                    }}
                    disabled={
                      isSaving || senProcessAdvice.adviceSubmittedInd === "Y"
                    }
                    loading={isSaving}
                  >
                    {t("Submit")}
                  </Button>

                  <AdviceSubmitModal
                    open={open}
                    setAdviceSubmittedInd={() => {
                      setAdviceSubmittedInd(true);
                    }}
                    closeModal={() => {
                      setOpen(false);
                    }}
                    onSubmit={methods.handleSubmit(onSubmit)}
                  />
                </div>
              </Form>
            </FormProvider>
          </Segment>
          <Segment attached>{t("Files")}</Segment>
          <Segment attached="bottom">
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t("ID")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("File")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Type")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Size")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Actions")}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {v(senProcessAdvice, "senProcessAdviceDocuments", []).map(
                  (document) => (
                    <Table.Row key={document.id}>
                      <Table.Cell>{document.file.id}</Table.Cell>
                      <Table.Cell>{document.file.filename}</Table.Cell>
                      <Table.Cell>{document.file.mimeType}</Table.Cell>
                      <Table.Cell>
                        {formatSize(document.file.docSize)}
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          style={{ backgroundColor: "#00AEEF", color: "#fff" }}
                          size="mini"
                          className={'light-blue'}
                          onClick={() => {
                            createAndDownloadBlobFile(
                              base64ToArrayBuffer(document.file.base64),
                              document.file.filename,
                              document.file.mimeType
                            );
                          }}
                        >
                          {t('Download')}
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  )
                )}
              </Table.Body>
            </Table>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default Advice;
