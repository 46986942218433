import React from "react";
import { Form, Grid, Segment, Table } from "semantic-ui-react";
import { gql } from "apollo-boost";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../LoadingMessage";
import dateFormatter from "../DateFormatter";
import { lookupFormatter, mainAddressIndFormatter } from "../utils";
import LookupCode from "../LookupCode";
import { useTranslation } from "react-i18next";
import HelpText from "../components/HelpText";

const QUERY = gql`
  query($pupilId: Int!) {
    lookupCodes(codeTypes: [6187, 5010]) {
      codeType
      codeValue
      codeDescription
    }
    pupil(pupilId: $pupilId) {
      forenames
      surname
      birthdate
      gender
      pupilAddresses {
        addressStartDate
        addressEndDate
        mainAddressInd
        addressType
        address {
          formatted
        }
      }
    }
  }
`;


function PersonalDetails() {
  const { pupilId } = useParams();
  const { t } = useTranslation();
  const { loading, data } = useQuery(QUERY, {
    variables: {
      pupilId
    }
  });

  if (loading) return <LoadingMessage />;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={6}>
          <Segment attached="top">
            <h4>
              {t('Personal Details')}
              <HelpText
                contentModule="Cases"
                contentType="HELP"
                contentArea="Case"
                contentItem="Personal"
                accessLevel="Private"
              />
            </h4>
          </Segment>
          <Segment attached>
            <Form>
              <Form.Input label={t("First Name")} value={data.pupil.forenames} />
              <Form.Input label={t("Surname")} value={data.pupil.surname} />
              <Form.Input
                label={t("Birthdate")}
                value={dateFormatter(data.pupil.birthdate)}
              />
              <Form.Input
                label={t("Sex")}
                value={lookupFormatter(
                  data.lookupCodes.filter(({ codeType }) => codeType === 6187),
                  data.pupil.gender
                )}
              />
            </Form>
          </Segment>
        </Grid.Column>
        <Grid.Column width={10}>
          <Segment attached="top">
            <h4>
              {t('Address History')}
              <HelpText
                contentModule="Cases"
                contentType="HELP"
                contentArea="Case"
                contentItem="Personal - Address information"
                accessLevel="Private"
              />
            </h4>
          </Segment>
          <Segment attached>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('Type')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('Start Date')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('End Date')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('Address Indicator')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('Address')}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.pupil.pupilAddresses.map(pupilAddress => (
                  <Table.Row id={pupilAddress.id}>
                    <Table.Cell>
                      <LookupCode
                        codeType={5010}
                        codeValue={pupilAddress.addressType}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {dateFormatter(pupilAddress.addressStartDate)}
                    </Table.Cell>
                    <Table.Cell>
                      {dateFormatter(pupilAddress.addressEndDate)}
                    </Table.Cell>
                    <Table.Cell>
                      {mainAddressIndFormatter(pupilAddress.mainAddressInd)}
                    </Table.Cell>
                    <Table.Cell>{pupilAddress.address.formatted}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default PersonalDetails;
