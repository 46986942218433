import React from "react";
import { Container, Grid, Icon, Message } from "semantic-ui-react";
import { Route, Switch, useHistory } from "react-router-dom";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { ApolloProvider } from "@apollo/react-hooks";
import { createUploadLink } from "apollo-upload-client";
import Home from "./home/Home";
import Profile from "./profile/Profile";
import ReferralsScreen from "./referrals/ReferralsScreen";
import Tasks from "./tasks/Tasks";
import Referrals from "./referrals/Referrals";
import CaseList from "./cases/CaseList";
import Case from "./cases/Case";
import TopMenu from "./menu/TopMenu";
import Sidebar from "./Sidebar";
import Dropdown from "../src/Dropdown";
import './css/custom.css';

export const client = (history) => {
  return new ApolloClient({
    connectToDevTools: true,
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) {
          if (networkError.statusCode === 403) {
            sessionStorage.removeItem("token");
            history.push("/login?auto=true");
          }
          console.log(`[Network error]: ${networkError}`);
        }
      }),
      createUploadLink({
        uri: "/graphql",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    ]),
    cache: new InMemoryCache(),
  });
};

function App() {
  const history = useHistory();
  return (
    <ApolloProvider client={client(history)}>
      <TopMenu />
      <Container fluid>
        <Grid>
          <Grid.Row>
            <Grid.Column
              width={3}
              className="border-r min-h-screen"
              style={{ backgroundColor: "#f5f5f5" }}
            >
              <Sidebar />
            </Grid.Column>
            <Grid.Column width={13}>
              <Container className="pt-4 pr-4">
                <Switch>
                  <Route path="/" exact>
                    <Home Dropdowns={Dropdown} />
                  </Route>
                  <Route path="/cases" exact>
                    <CaseList />
                  </Route>
                  <Route path="/cases/:pupilId">
                    <Case />
                  </Route>
                  <Route path="/tasks">
                    <Tasks Dropdowns={Dropdown} />
                  </Route>
                  <Route path="/referral" exact>
                    <Referrals  Dropdowns={Dropdown}/>
                  </Route>
                  <Route path="/referral/:referralId/:referralType">
                    <ReferralsScreen />
                  </Route>
                  <Route path="/profile">
                    <Profile />
                  </Route>
                  <Route>
                    <Message icon error>
                      <Icon name="warning" />
                      <Message.Content>
                        <Message.Header>404</Message.Header>
                        Page not found
                      </Message.Content>
                    </Message>
                  </Route>
                </Switch>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </ApolloProvider>
  );
}

export default App;
