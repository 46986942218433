import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import {
  Button,
  Dropdown,
  Grid,
  Menu,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import dateFormatter from "../DateFormatter";
import LookupCode from "../LookupCode";
import LoadingMessage from "../LoadingMessage";
import { useTranslation } from "react-i18next";
import HelpText from "../components/HelpText";
import { mergeDeepRight } from "ramda";
import { base64ToArrayBuffer, createAndDownloadBlobFile, v } from "../utils";

const QUERY = gql`
  query ($planId: Int!, $page: Int!, $size: Int!) {
    lookupCodes(codeTypes: [6017]) {
      codeType
      codeValue
      codeDescription
    }
    pupilPlan(planId: $planId) {
      id
      issueDate
      title
      type
      planHistory {
        id
        url
        tag {
          timestamp
          reason
          version
        }
      }
      pupilPlanDocumentPage(page: $page, size: $size) {
        number
        totalPages
        content {
          ... on PupilPlanDocument {
            id
            pupilPlanId
            fileId
            files {
              id
              filename
              mimeType
              docSize
              base64
              createdDate
              createdUser
              application
            }
          }
        }
      }
    }
  }
`;

function Plan() {
  const { planId, pupilId } = useParams();
  const [pageSize, setPageSize] = useState(10);
  const { t } = useTranslation();
  const { loading, data, fetchMore } = useQuery(QUERY, {
    variables: { planId, page: 0, size: pageSize },
  });

  if (loading) return <LoadingMessage />;

  return (
    <React.Fragment>
      <div className="bg-white rounded p-8">
        <div className="font-black text-xl mb-6">{t("Summary")}</div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={1} />
            <Grid.Column width={5}>
              <div className="border-l-4 border-purple-600">
                <div className="ml-4 font-bold">{data?.pupilPlan.title}</div>
                <div className="ml-4 text-sm font-semibold text-gray-700">
                  {t("Title")}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={5}>
              <div className="border-l-4 border-teal-600">
                <div className="ml-4 font-bold">
                  {data?.pupilPlan && (
                    <LookupCode
                      codeType={6017}
                      codeValue={data?.pupilPlan.type}
                    />
                  )}
                </div>
                <div className="ml-4 text-sm font-semibold text-gray-700">
                  {t("Type")}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={5}>
              <div className="border-l-4 border-pink-600">
                <div className="ml-4 font-bold">
                  {dateFormatter(data?.pupilPlan.issueDate)}
                </div>
                <div className="ml-4 text-sm font-semibold text-gray-700">
                  {t("Issue Date")}
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div>
        <Menu pointing secondary>
          <Menu.Item
            as={NavLink}
            to={`/children/${pupilId}/plans/${planId}`}
            exact
          >
            {t("History")}
          </Menu.Item>
        </Menu>
      </div>
      <div className="mt-4">
        <div>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t("Version")}</Table.HeaderCell>
                <Table.HeaderCell>{t("Date")}</Table.HeaderCell>
                <Table.HeaderCell>{t("Reason")}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  {t("Actions")}
                  <HelpText
                    contentModule="Cases"
                    contentType="HELP"
                    contentArea="Case"
                    contentItem="Plan Details"
                    accessLevel="Private"
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {(data?.pupilPlan.planHistory || []).map((planHistory) => (
                <Table.Row key={planHistory.id}>
                  <Table.Cell>{planHistory.tag.version}</Table.Cell>
                  <Table.Cell>
                    {dateFormatter(planHistory.tag.timestamp)}
                  </Table.Cell>
                  <Table.Cell>{planHistory.tag.reason}</Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      className={"blue"}
                      as="a"
                      target="_blank"
                      href={planHistory.url}
                    >
                      {t("View")}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>

        <div>
          <Menu pointing secondary>
            <Menu.Item
              as={NavLink}
              to={`/children/${pupilId}/plans/${planId}`}
              exact
            >
              {t("Document")}
              <HelpText
                contentModule="Cases"
                contentType="HELP"
                contentArea="Case"
                contentItem="Plan Details"
                accessLevel="Private"
              />
            </Menu.Item>
          </Menu>
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("Document")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Created Date")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Created User")}</Table.HeaderCell>
              <Table.HeaderCell>
                {t("Actions")}
                <HelpText
                  contentModule="Cases"
                  contentType="HELP"
                  contentArea="Case"
                  contentItem="Plan Details"
                  accessLevel="Private"
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {v(data, "pupilPlan.pupilPlanDocumentPage.content", []).map(
              (pupilDocumentV) => (
                <Table.Row key={pupilDocumentV.pupilPlanId}>
                  <Table.Cell>{pupilDocumentV.files.filename}</Table.Cell>
                  <Table.Cell>
                    {dateFormatter(pupilDocumentV.files.createdDate)}
                  </Table.Cell>
                  <Table.Cell>{pupilDocumentV.files.createdUser}</Table.Cell>
                  <Table.Cell>
                    <Button
                      style={{ backgroundColor: "#00AEEF", color: "#fff" }}
                      onClick={() => {
                        createAndDownloadBlobFile(
                          base64ToArrayBuffer(pupilDocumentV.files.base64),
                          pupilDocumentV.files.filename,
                          pupilDocumentV.files.mimeType
                        );
                      }}
                      className={"light-blue"}
                    >
                      {t("Download")}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              )
            )}
          </Table.Body>
        </Table>
        <Grid>
          <Grid.Row>
            <Grid.Column width={14}>
              <br />
              <br />
              <Pagination
                boundaryRange={1}
                activePage={data?.pupilPlan.pupilPlanDocumentPage.number + 1}
                siblingRange={1}
                totalPages={data?.pupilPlan.pupilPlanDocumentPage.totalPages}
                onPageChange={(e, { activePage }) =>
                  fetchMore({
                    variables: {
                      page: activePage - 1,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;

                      return mergeDeepRight(prev, {
                        pupilPlan: {
                          pupilPlanDocumentPage:
                            fetchMoreResult.pupilPlan.pupilPlanDocumentPage,
                        },
                      });
                    },
                  })
                }
              />
            </Grid.Column>
            <Grid.Column width={2} style={{ textAlign: "center" }}>
              <label style={{ fontSize: "0.9rem", color: "#81929B" }}>
                {t("Record per Page")}
              </label>
              <Segment style={{ padding: "10px" }}>
                <Dropdown
                  value={pageSize}
                  name="pageSize"
                  options={[
                    { value: 10, text: "10" },
                    { value: 25, text: "25" },
                    { value: 50, text: "50" },
                    { value: 100, text: "100" },
                  ]}
                  onChange={(_, { value }) => setPageSize(value)}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default Plan;
