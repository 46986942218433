import { pathOr, split, map, test } from "ramda";
import { useLocation } from "react-router-dom";
import dateFormatter from "./DateFormatter";

export const v = (probe, path, defaultValue) => {
  if (probe === null || probe === undefined) return defaultValue;

  if (Array.isArray(path)) {
    return pathOr(defaultValue, path, probe);
  }

  return pathOr(
    defaultValue,
    map((s) => (test(/\d+/, s) ? parseInt(s) : s), split(".", path)),
    probe
  );
};

export const yesNoOptions = [
  { key: "Y", value: "Y", text: "Yes" },
  { key: "N", value: "N", text: "No" },
];

export const lookupToOptions = (codeType) => (lookupCodes = []) => {
  return lookupCodes
    .filter((lookupCode) => codeType === lookupCode.codeType)
    .map((lookupCode) => ({
      key: `${lookupCode.codeType}-${lookupCode.codeValue}`,
      value: lookupCode.codeValue,
      text: lookupCode.codeDescription,
    }));
};

export const lookupToOptionsTranslation = (codeType, t) => (lookupCodes = []) => {
  return lookupCodes
      .filter((lookupCode) => codeType === lookupCode.codeType)
      .map((lookupCode) => ({
        key: `${lookupCode.codeType}-${lookupCode.codeValue}`,
        value: lookupCode.codeValue,
        text: t(lookupCode.codeDescription),
      }));
};

export function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

export function createAndDownloadBlobFile(body, fileName, type) {
  const blob = new Blob([body], { type });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function formatSize(docSize) {
  const k = 1024;
  const dm = 2;
  const sizes = ["Bytes", "KB", "MB"];
  const i = Math.floor(Math.log(docSize) / Math.log(k));
  return parseFloat((docSize / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function lookupFormatter(lookups = [], codeValue = "", codeType) {
  const found = lookups
    .filter((lookup) => !codeType || lookup.codeType === codeType)
    .find((lookup) => lookup.codeValue === codeValue);
  if (!found) return codeValue;
  return found.codeDescription;
}

export function mainAddressIndFormatter(mainAddressInd = "") {
  switch (mainAddressInd) {
    case "M":
      return "Main";
    case "P":
      return "Previous";
    case "F":
      return "Future";
    default:
      return mainAddressInd;
  }
}

export function isFormValidationError(error) {
  return (
    v(error, "extensions.classification", "") === "ValidationError" &&
    !!v(error, "extensions.field", "")
  );
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const capitalise = (str) => {
  if(str !== null) {
    let parts = str.split(' ');
    for (let i = 0; i < parts.length; i++) {
      parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].substr(1).toLowerCase();
    }
    return parts.join(' ');
  }
  else return str;
}

export const calculateDateDifference = (targetDate, completed) => {
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

  const present = new Date();
  const start = new Date(targetDate);

  const timeDifference = !completed ? start - present : 0; // Set time difference to 0 for completed tasks
  const daysDifference = Math.floor(timeDifference / oneDayInMilliseconds);

  if (completed) {
    return "#000000"; // Task is completed
  } else if (daysDifference < 0) {
    return "#FF0000"; // Task is overdue
  } else if (daysDifference < 7) {
    return "#FF0000"; // Target date is within a week
  } else if (daysDifference >= 7) {
    return "#FFA500"; // Target date is more than a week
  }
};
