function MainEstabInd({ value }) {
    switch (value) {
        case "M":
            return "Main";
        case "P":
            return "Previous";
        case "D":
            return "Dual";
        case "I":
            return "Integrated";
        case "G":
            return "Guest";
        default:
            return value;
    }
}

export default MainEstabInd;
