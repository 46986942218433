import React from "react";
import { Form, Segment } from "semantic-ui-react";
import Dropdown from "../Dropdown";
import TextArea from "../TextArea";
import { Trans, useTranslation } from 'react-i18next';
import HelpText from "../components/HelpText";

function Consent({ lookupCodes }) {
const { t } = useTranslation();
  return (
    <React.Fragment>
      <h5>
          <Trans t={t} i18nKey="All fields marked with a * are mandatory, you will not be able to submit the referral until these fields have been completed.">
              All fields marked with a <span style={{color: 'red'}}>*</span> are mandatory, you will not be able to submit the referral until these fields have been completed.
          </Trans>
      </h5>
      <Segment attached="top">
        <h3>
          {t("Consent")}
          <HelpText
            contentModule="Referrals"
            contentType="HELP"
            contentArea="Referrals"
            contentItem="Consent Details"
            accessLevel="Private"
          />
        </h3>
      </Segment>
      <Segment attached>
        <HelpText
          className="float-none"
          contentModule="Referrals"
          contentType="HELP"
          contentArea="Referrals"
          contentItem="Consent Disclaimer"
          accessLevel="Private"
        />
        <Form className="mt-5">
          <Dropdown
            required
            name="consentGiven"
            label={t("Consent Given")}
            options={lookupCodes
              .filter(({ codeType }) => codeType === 5132)
              .map((lookupCode) => ({
                key: `${lookupCode.codeType}-${lookupCode.codeValue}`,
                value: lookupCode.codeValue,
                text: t(lookupCode.codeDescription),
              }))}
          />
          <TextArea name="consentConditions" label="Consent Conditions" />
        </Form>
      </Segment>
    </React.Fragment>
  );
}

export default Consent;
