import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Button, FormField } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';


function FileInput({ label, name, disabled, removeAllFilesButton = true }) {
  const { t } = useTranslation();
  const { control, errors } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <React.Fragment>
      {fields.map((field, index) => {
        return (
          <Controller
            key={field.id}
            name={`${name}[${index}].file`}
            control={control}
            defaultValue=""
            dis
            render={({ onChange }) => (
              <FormField error={!!errors[name]}>
                <label>{t(label)}</label>
                <div className="ui action input">
                  <input
                    type="file"
                    onChange={({
                      target: {
                        files: [file],
                      },
                    }) => onChange(file)}
                  />
                  <button className="ui button" onClick={() => remove(index)} style={{backgroundColor:'#0054A4',color:'#fff'}}>
                    {t('Remove')}
                  </button>
                </div>
                {!!errors[name] && (
                  <span className="text-red-700 text-xs">
                    {errors[name].message}
                  </span>
                )}
              </FormField>
            )}
          />
        );
      })}
      <Button className={'purple'} type="button" onClick={append} disabled={disabled}>
        {t('Add File')}
      </Button>
      {removeAllFilesButton && (
        <Button
          type="button"
          disabled={fields.length <= 0}
          onClick={() => remove()}
          className={'red'}
        >
          {t('Remove All Files')}
        </Button>
      )}
    </React.Fragment>
  );
}

export default FileInput;
