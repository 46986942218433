import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

function TextArea({
  label = "",
  name = "",
  readOnly = false,
  required = false,
}) {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ onChange, value }) => (
        <FormField error={!!errors[name]} required={required}>
          <label>{t(label)}</label>
          <textarea
            readOnly={readOnly}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
          {!!errors[name] && (
            <span className="text-red-700 text-xs">{errors[name].message}</span>
          )}
        </FormField>
      )}
    />
  );
}

export default TextArea;
