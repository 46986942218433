import React from "react";
import { Container, Message, Segment, Grid, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import impulseNexusWheel from "./assets/impulse_nexus_wheel.png";
import { useTranslation } from "react-i18next";

function ConfirmationMessage() {
  const { t } = useTranslation();

  return (
    <div className="flex min-h-screen">
      <Segment attached="top">
        <Grid centered columns={4}>
          <Grid.Column>
            <div className="flex items-center p-10">
              <img src={impulseNexusWheel} alt="" />
            </div>
          </Grid.Column>
        </Grid>
        <Divider />
        <div className="flex-grow text-center">
          <Container as={Link} to="/login" className="mt-5">
            <Message icon success>
              <Message.Content>
                <Message.Header>{t("Account Activated")}</Message.Header>
                {t("Please click here to go back to the login screen.")}
              </Message.Content>
            </Message>
          </Container>
        </div>
      </Segment>
    </div>
  );
}

export default ConfirmationMessage;
