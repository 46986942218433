import React from "react";
import { Form, Segment } from "semantic-ui-react";
import Input from "../Input";
import Dropdown from "../Dropdown";
import TextArea from "../TextArea";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import {lookupToOptions, lookupToOptionsTranslation, v} from "../utils";
import LoadingMessage from "../LoadingMessage";
import { useTranslation, Trans } from "react-i18next";
import HelpText from "../components/HelpText";

const GET_DATA = gql`
  query {
    lookupCodes(codeTypes: [1001, 1126]) {
      codeType
      codeValue
      codeDescription
    }
  }
`;

function ReferralDetails({ referralTime, setReferralTime }) {
  const { t } = useTranslation();
  const { loading, data } = useQuery(GET_DATA);

  if (loading) return <LoadingMessage />;

  return (
    <React.Fragment>
      <h5>
          <Trans t={t} i18nKey="All fields marked with a * are mandatory, you will not be able to submit the referral until these fields have been completed.">
              All fields marked with a <span style={{color: 'red'}}>*</span> are mandatory, you will not be able to submit the referral until these fields have been completed.
          </Trans>
      </h5>
      <Segment attached="top">
        <h3>
          {t("Referral Details")}
          <HelpText
            contentModule="Referrals"
            contentType="HELP"
            contentArea="Referrals"
            contentItem="Referral Details"
            accessLevel="Private"
          />
        </h3>
      </Segment>
      <Segment attached>
        <Form>
          <Input label={t("Referral Date")} type="date" name="referralDate" />
          <Input
            required
            label={t("Referral Time")}
            type="time"
            name="referralTime"
            value={referralTime}
            onChange={(e) => setReferralTime(e.target.value)}
          />
          <Dropdown
            required
            label={t("Referral Reason")}
            name="referralReason"
            options={lookupToOptionsTranslation(1001,t)(v(data, "lookupCodes", [])).concat(
                lookupToOptionsTranslation(1126,t)(v(data, "lookupCodes", []))
            )}
          />
          <TextArea label={t("Please outline your involvement with this learner")} name="initialAction" required/>
        </Form>
      </Segment>
    </React.Fragment>
  );
}

export default ReferralDetails;
