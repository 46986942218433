import React from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import LoadingMessage from "../../LoadingMessage";
import { Button, Icon, Ref, Table } from "semantic-ui-react";
import { Link, Route, Switch } from "react-router-dom";
import { uniq } from "ramda";
import classNames from "classnames";
import { v } from "../../utils";
import CommunicationPreferenceDetails from "../CommunicationPreferenceDetails";
import LookupCode from "../../LookupCode";
import DeleteConfirmationModal from "../../DeleteConfirmationModal";
import { useTranslation } from "react-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import HelpText from "../../components/HelpText";

const GET_DATA = gql`
  {
    portalUser {
      person {
        serviceDefinition {
          commsPreferredMethods {
            id
            prefType
            prefOrder
            lookupCodeType
          }
        }
        personContacts {
          contactCode
          startDate
          endDate
        }
        personAddresses {
          isActive
          addressType
          address {
            addressId
            formatted
          }
        }
      }
    }
  }
`;

const REFETCH_QUERY = gql`
  {
    portalUser {
      person {
        serviceDefinition {
          commsPreferredMethods {
            id
            prefOrder
            prefType
            lookupCodeType
          }
        }
        personContacts {
          commsPreferredMethodOrder
          contactCode
          contactValue
          preferred
          alternative
          startDate
          endDate
        }
      }
    }
  }
`;

function CommunicationPreferences() {
  const { data, loading } = useQuery(GET_DATA);
  const { t } = useTranslation();

  const [deleteCommsPreferredMethod, { loading: deleting }] = useMutation(
    gql`
      mutation DeleteCommsPreferredMethod($id: Int!) {
        deleteCommsPreferredMethod(id: $id) {
          id
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: REFETCH_QUERY,
        },
      ],
    }
  );

  const [reorganizeCommsPreferredMethod] = useMutation(
    gql`
      mutation ReorganizeCommsPreferredMethod(
        $id: Int!
        $sourceIndex: Int!
        $destinationIndex: Int!
      ) {
        reorganizeCommsPreferredMethod(
          id: $id
          sourceIndex: $sourceIndex
          destinationIndex: $destinationIndex
        ) {
          id
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: REFETCH_QUERY,
        },
      ],
    }
  );

  if (loading) return <LoadingMessage />;

  const prefTypes = uniq(
    v(
      data,
      "portalUser.person.serviceDefinition.commsPreferredMethods",
      []
    ).map(({ prefType, lookupCodeType }) => `${lookupCodeType}-${prefType}`)
  );

  const onDragEnd = (result) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    reorganizeCommsPreferredMethod({
      variables: {
        id: result.draggableId,
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index,
      },
    });
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>{t("Type")}</Table.HeaderCell>
              <Table.HeaderCell style={{ width: '100px' }}>
                {t("Actions")}
                <HelpText
                  contentModule="Profile"
                  contentType="HELP"
                  contentArea="Profile"
                  contentItem="Communication Preferences"
                  accessLevel="Private"
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Droppable droppableId="droppable">
            {(provided) => (
              <Ref innerRef={provided.innerRef}>
                <Table.Body>
                  {v(
                    data,
                    "portalUser.person.serviceDefinition.commsPreferredMethods",
                    []
                  ).map(({ id, prefType, lookupCodeType }, index) => (
                    <Draggable
                      key={id}
                      draggableId={id.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Ref innerRef={provided.innerRef}>
                          <Table.Row
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classNames({
                              table: snapshot.isDragging,
                              shadow: snapshot.isDragging,
                              "bg-gray-100": snapshot.isDragging,
                            })}
                          >
                            <Table.Cell collapsing>
                              <Icon name="ellipsis vertical" />
                            </Table.Cell>
                            <Table.Cell>
                              <LookupCode
                                codeValue={prefType}
                                codeType={lookupCodeType}
                              />
                            </Table.Cell>
                            <Table.Cell collapsing textAlign="center">
                              <DeleteConfirmationModal
                                onDelete={() =>
                                  deleteCommsPreferredMethod({
                                    variables: { id: id },
                                  })
                                }
                              >
                                <Button
                                  icon
                                  size="tiny"
                                  disabled={deleting}
                                  color="red"
                                >
                                  <Icon name="trash" />
                                </Button>
                              </DeleteConfirmationModal>
                            </Table.Cell>
                          </Table.Row>
                        </Ref>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Table.Body>
              </Ref>
            )}
          </Droppable>
        </Table>
      </DragDropContext>

      <Switch>
        <Route path="/profile/communication-preferences" exact>
          <Button className={'purple'} as={Link} to="/profile/communication-preferences/new">
            {t("New")}
          </Button>
        </Route>
      </Switch>

      <Switch>
        <Route path="/profile/communication-preferences/:commsPreferredMethodId">
          <CommunicationPreferenceDetails
            contacts={v(data, "portalUser.person.personContacts", [])
              .filter(({ endDate }) => !endDate)
              .map(({ contactCode }) => contactCode)
              .filter(
                (contactCode, index, self) =>
                  self.indexOf(contactCode) === index
              )
              .filter(
                (contactCode) => !prefTypes.includes(`5001-${contactCode}`)
              )}
            addresses={v(data, "portalUser.person.personAddresses", [])
              .filter(({ isActive }) => isActive)
              .map(({ addressType }) => addressType)
              .filter(
                (addressType) => !prefTypes.includes(`5002-${addressType}`)
              )}
          />
        </Route>
      </Switch>
    </>
  );
}

export default CommunicationPreferences;
