import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormField, Select } from "semantic-ui-react";
import { v } from "./utils";
import {useTranslation} from "react-i18next";

function Dropdown({
  placeholder = "",
  name,
  label,
  defaultValue,
  options = [],
  cb = () => {},
  path = [],
  clearValue = true,
  loading = false,
  required = false,
}) {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();
  const hasErrors = !!v(errors, !!path.length ? path : [name], "");

  const errorMessage = v(
    errors,
    !!path.length ? path.concat(["message"]) : [name, "message"],
    ""
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : ""}
      render={({ onChange, value }) => (
        <FormField error={hasErrors} required={required}>
          <label>{label}</label>
          <Select
            placeholder={loading ? "Loading..." : placeholder}
            loading={loading}
            options={
              clearValue
                ? [{ value: "", text: t("Clear Value") }].concat(options)
                : options
            }
            search
            selection
            autoComplete="off"
            onChange={(_, { value }) => {
              onChange(value);
              cb(value);
            }}
            value={value}
          />
          {hasErrors && (
            <span className="text-red-700 text-xs">{errorMessage}</span>
          )}
        </FormField>
      )}
    />
  );
}

export default Dropdown;
