import { gql } from "apollo-boost";

export const PLANS = gql`
  query Plans($pupilId: Int!) {
    pupil(pupilId: $pupilId) {
      pupilPlans {
        id
        issueDate
        type
        title
      }
    }
  }
`;

export const LOOKUP_CODES = gql`
  query LookupCode($codeTypes: [Int!]!) {
    lookupCodes(codeTypes: $codeTypes) {
      codeType
      codeValue
      codeDescription
    }
  }
`;
