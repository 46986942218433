import React, { useEffect, useCallback } from "react";
import { Popup, Icon } from "semantic-ui-react";
import { usePublicPortalContent } from "../hooks/usePublicPortalContent";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

const GET_DATA = gql`
  query PortalContent(
    $contentModule: String!
    $contentType: String!
    $contentArea: String!
  ) {
    portalContent(
      contentModule: $contentModule
      contentType: $contentType
      contentArea: $contentArea
    ) {
      contentValue
      contentItem
    }
  }
`;

const PLACE_HOLDER_TEXT = "Content has not been defined.";

function HelpText({ accessLevel, ...restProps }) {
  if (accessLevel === "Public") {
    return <PublicHelpText {...restProps} />;
  } else {
    return <PrivateHelpText {...restProps} />;
  }
}

function PublicHelpText({
  contentModule,
  contentType,
  contentArea,
  contentItem,
  className = "float-none blue",
  position = "top right",
}) {
  const { data } = usePublicPortalContent({
    contentModule,
    contentType,
    contentArea,
    contentItem,
    accessLevel: "Public",
  });
  const { t } = useTranslation();

  return (
    <Popup
      trigger={
        <Icon
          name="info circle"
          className={className}
          style={{ margin: "2px 0 0 5px", fontSize: "15px" }}
        />
      }
      content={t(data?.contentValue || PLACE_HOLDER_TEXT)}
      position={position}
    />
  );
}

function PrivateHelpText({
  contentModule,
  contentType,
  contentArea,
  contentItem,
  className = "float-none blue",
  position = "top right",
}) {
  const { t } = useTranslation();
  const [getPortalContents, { data: gqlData }] = useLazyQuery(GET_DATA);
  const gqlCb = useCallback(() => {
    if (!!sessionStorage.getItem("token")) {
      getPortalContents({
        variables: { contentModule, contentType, contentArea },
      });
    }
  }, [contentModule, contentType, contentArea, getPortalContents]);

  useEffect(() => {
    gqlCb();
  }, [gqlCb]);

  return (
    <Popup
      trigger={
        <Icon
          name="info circle"
          className={className}
          style={{ margin: "2px 0 0 5px", fontSize: "15px" }}
        />
      }
      content={t(
        gqlData?.portalContent.find((f) => f.contentItem === contentItem)
          ?.contentValue || PLACE_HOLDER_TEXT
      )}
      position={position}
    />
  );
}

const MemorisedHelpText = React.memo(HelpText);

export default MemorisedHelpText;
