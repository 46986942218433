import React, { useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Message,
  Pagination,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import LoadingMessage from "../LoadingMessage";
import {
  lookupToOptions,
  v,
  calculateTargetColor,
  calculateDateDifference,
} from "../utils";
import dateFormatter from "../DateFormatter";
import LookupCode from "../LookupCode";
import { useTranslation } from "react-i18next";
import HelpText from "../components/HelpText";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../Input";
import { mergeDeepRight } from "ramda";

const GET_DATA = gql`
  query GetTask(
    $page: Int!
    $size: Int!
    $taskCode: String
    $targetDate: LocalDate
    $serviceId: Int
  ) {
    lookupCodes(codeTypes: [5158]) {
      codeType
      codeDescription
      codeValue
    }
    pupilByTasks {
      pupilId
      fullName
    }
    taskTemplateByTask {
      taskDescription
      taskCode
    }
    portalUser {
      person {
        taskContributors(
          page: $page
          size: $size
          taskCode: $taskCode
          targetDate: $targetDate
          serviceId: $serviceId
        ) {
          number
          totalPages
          content {
            ... on TaskContributor {
              contributorId
              tasks {
                taskId
                startDate
                endDate
                targetDate
                priority
                taskContributor(contributorType: "OWNER") {
                  person {
                    fullName
                  }
                }
                taskTemplate {
                  taskDescription
                }
                caseServiceDefinition {
                  pupil {
                    fullName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const UPDATE_TASK = gql`
  mutation UpdateTasks($taskId: Int!) {
    updateTasks(taskId: $taskId) {
      taskId
    }
  }
`;

function Tasks({ Dropdowns }) {
  const searchForm = useForm();
  const [pageSize, setPageSize] = useState(10);

  const submitSearch = ({ taskCode, targetDate, serviceId }) => {
    fetchMore({
      variables: {
        page: 0,
        size: pageSize,
        taskCode: taskCode || null,
        targetDate: targetDate || null,
        serviceId: serviceId || null,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return mergeDeepRight(prev, {
          portalUser: {
            person: {
              taskContributors:
                fetchMoreResult.portalUser.person.taskContributors,
            },
          },
        });
      },
    });
  };

  const { loading, data, fetchMore, refetch } = useQuery(GET_DATA, {
    variables: {
      page: 0,
      size: pageSize,
      taskCode: null,
      targetDate: null,
      serviceId: null,
    },
  });

  const { t } = useTranslation();

  const [updateTask] = useMutation(UPDATE_TASK, {
    refetchQueries: [
      {
        query: GET_DATA,
        variables: {
          page:
            data?.portalUser?.person?.taskContributors?.content?.number || 0,
          size: pageSize,
          taskCode: null,
          targetDate: null,
          serviceId: null,
        },
      },
    ],
  });

  if (loading) return <LoadingMessage />;
  if (data?.portalUser?.person?.taskContributors?.content?.length <= 0)
    return (
      <div>
        <FormProvider {...searchForm}>
          <Form onSubmit={searchForm.handleSubmit(submitSearch)}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Input
                    label={t("Target Date")}
                    name={"targetDate"}
                    type="date"
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Dropdowns
                    name="taskCode"
                    label={t("Task")}
                    options={v(data, "taskTemplateByTask", []).map(
                      ({ taskCode, taskDescription }) => ({
                        value: taskCode,
                        text: taskDescription,
                      })
                    )}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Dropdowns
                    name="serviceId"
                    label={t("Case")}
                    options={v(data, "pupilByTasks", []).map(
                      ({ pupilId, fullName }) => ({
                        value: pupilId,
                        text: fullName,
                      })
                    )}
                  />
                </Grid.Column>
                <Grid.Column width={4} style={{ marginTop: "5" }}>
                  <br />
                  <Button icon color={"green"} type={"submit"}>
                    <Icon name="search" />
                  </Button>
                  <Popup
                    trigger={
                      <Button
                        icon
                        color={"red"}
                        type="submit"
                        onClick={() => {
                          searchForm.reset({
                            targetDate: "",
                            taskCode: "",
                            serviceId: null,
                          });
                        }}
                      >
                        <Icon name="remove circle" />
                      </Button>
                    }
                    content={t("Clear")}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormProvider>
        <Message info icon color="purple">
          <Icon name="info" />
          <Message.Header>{t("No records have been found")}</Message.Header>
        </Message>
      </div>
    );

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Segment attached="top">
            <h3>{t("Tasks")}</h3>
            <FormProvider {...searchForm}>
              <Form onSubmit={searchForm.handleSubmit(submitSearch)}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Input
                        label={t("Target Date")}
                        name={"targetDate"}
                        type="date"
                      />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Dropdowns
                        name="taskCode"
                        label={t("Task")}
                        options={v(data, "taskTemplateByTask", []).map(
                          ({ taskCode, taskDescription }) => ({
                            value: taskCode,
                            text: taskDescription,
                          })
                        )}
                      />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Dropdowns
                        name="serviceId"
                        label={t("Case")}
                        options={v(data, "pupilByTasks", []).map(
                          ({ pupilId, fullName }) => ({
                            value: pupilId,
                            text: fullName,
                          })
                        )}
                      />
                    </Grid.Column>
                    <Grid.Column width={4} style={{ marginTop: "5" }}>
                      <br />
                      <Button icon color={"green"} type={"submit"}>
                        <Icon name="search" />
                      </Button>
                      <Popup
                        trigger={
                          <Button
                            icon
                            color={"red"}
                            type="submit"
                            onClick={() => {
                              searchForm.reset({
                                targetDate: "",
                                taskCode: "",
                                serviceId: null,
                              });
                            }}
                          >
                            <Icon name="remove circle" />
                          </Button>
                        }
                        content={t("Clear")}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </FormProvider>
          </Segment>

          <Segment attached>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t("Task ID")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Task")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Case")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Created Date")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Priority")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Target Date")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Completed Date")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Owner")}</Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("Actions")}
                    <HelpText
                      contentModule="Tasks"
                      contentType="HELP"
                      contentArea="Tasks"
                      contentItem="Tasks list"
                      accessLevel="Private"
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {v(data, "portalUser.person.taskContributors.content", []).map(
                  (task) => (
                    <Table.Row key={`${task.tasks.taskId}`}>
                      <Table.Cell>{task.tasks.taskId}</Table.Cell>
                      <Table.Cell>
                        {task.tasks.taskTemplate.taskDescription}
                      </Table.Cell>
                      <Table.Cell>
                        {task.tasks.caseServiceDefinition.pupil.fullName}
                      </Table.Cell>
                      <Table.Cell>
                        {dateFormatter(task.tasks.startDate)}
                      </Table.Cell>
                      <Table.Cell>
                        <LookupCode
                          codeType={5158}
                          codeValue={task.tasks.priority}
                        />
                      </Table.Cell>
                      <Table.Cell
                        style={{
                          color: calculateDateDifference(
                            task.tasks.targetDate,
                            task.tasks.endDate
                          ),
                        }}
                      >
                        {dateFormatter(task.tasks.targetDate)}
                      </Table.Cell>
                      <Table.Cell>
                        {dateFormatter(task.tasks.endDate)}
                      </Table.Cell>
                      <Table.Cell>
                        {task.tasks.taskContributor.person.fullName}
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Button
                          disabled={task.tasks.endDate}
                          className={"green"}
                          onClick={() =>
                            updateTask({
                              variables: {
                                taskId: task.tasks.taskId,
                              },
                            })
                          }
                        >
                          {t("Complete")}
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  )
                )}
              </Table.Body>
            </Table>
          </Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column width={14}>
                <br />
                <br />
                <Pagination
                  activePage={
                    data?.portalUser?.person.taskContributors.number + 1
                  }
                  siblingRange={1}
                  totalPages={
                    data?.portalUser?.person.taskContributors.totalPages
                  }
                  onPageChange={(e, { activePage }) =>
                    fetchMore({
                      variables: {
                        page: activePage - 1,
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;

                        return mergeDeepRight(prev, {
                          portalUser: {
                            person: {
                              taskContributors:
                                fetchMoreResult.portalUser.person
                                  .taskContributors,
                            },
                          },
                        });
                      },
                    })
                  }
                />
              </Grid.Column>
              <Grid.Column width={2} style={{ textAlign: "center" }}>
                <label style={{ fontSize: "0.9rem", color: "#81929B" }}>
                  {t("Record per Page")}
                </label>
                <Segment style={{ padding: "10px" }}>
                  <Dropdown
                    value={pageSize}
                    name="pageSize"
                    options={[
                      { value: 10, text: "10" },
                      { value: 25, text: "25" },
                      { value: 50, text: "50" },
                      { value: 100, text: "100" },
                    ]}
                    onChange={(_, { value }) => setPageSize(value)}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default Tasks;
