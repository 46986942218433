import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import React from "react";
import { usePublicPortalContent } from "./hooks/usePublicPortalContent";
import { useTranslation } from "react-i18next";

const ConfidentialityStatement = ({
  inviteRegistration: {
    email,
    isBenefitClaimant,
    personId,
    activationKey,
    agreeInd,
  },
}) => {
  const { data: statementHeader } = usePublicPortalContent({
    contentModule: "Request Access",
    contentType: "HEADER",
    contentArea: "Registration",
    contentItem: "Statement Header",
    accessLevel: "Public",
  });

  const { data: agreementFooter } = usePublicPortalContent({
    contentModule: "Request Access",
    contentType: "HELP",
    contentArea: "Registration",
    contentItem: "Agreement Footer Text",
    accessLevel: "Public",
  });

  const { t } = useTranslation();

  return (
    <Container className="pt-12">
      <Grid centered>
        <Grid.Row>
          <Grid.Column style={{ maxWidth: 650 }}>
            <Header as="h1" attached="top">
              {t(statementHeader?.contentValue || "")}
            </Header>
            <Segment attached="bottom">
              <div className="confidentialityStmtContainer">
                {t("Professionals Confidentiality Statement")}
              </div>
              <strong>{t(agreementFooter?.contentValue || "")}</strong>
              <div style={{ marginTop: "10px" }}>
                <Button color="red" as={Link} to="/login">
                  <Icon name="remove circle" />
                  {t("Cancel Registration")}
                </Button>
                <Button
                  color="blue"
                  as={Link}
                  to={{
                    pathname: "/invite-registration",
                    state: {
                      agreeInd: agreeInd,
                      email: email,
                      isBenefitClaimant: isBenefitClaimant,
                      personId: personId,
                      activationKey: activationKey,
                    },
                  }}
                  style={{ marginLeft: "5px" }}
                >
                  <Icon name="check" />
                  {t("Agree & Proceed")}
                </Button>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default ConfidentialityStatement;
