import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Grid,
  Message,
  Segment,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import impulseNexusWheel from "./assets/impulse_nexus_wheel.png";
import request from "./request";
import { useTranslation } from "react-i18next";
import { usePublicPortalContent } from "./hooks/usePublicPortalContent";
import ConfidentialityStatement from "./ConfidentialityStatement";

const STATUS = {
  INIT: 0,
  IN_PROG: 1,
  DONE: 2,
};

function CodeActivationScreen() {
  const { t } = useTranslation();
  const history = useHistory();

  const { data: contactFooter } = usePublicPortalContent({
    contentModule: "Request Access",
    contentType: "HELP",
    contentArea: "Registration",
    contentItem: "Contact Us Help Text Footer",
    accessLevel: "Public",
  });

  const [state, setState] = useState({
    status: STATUS.INIT,
    isCodeValid: false,
    email: "",
    isBenefitClaimant: "",
    personId: "",
    activationKey: "",
    displayStatement: false,
    error: "",
  });

  const {
    status,
    isCodeValid,
    email,
    isBenefitClaimant,
    personId,
    activationKey,
    displayStatement,
    error,
  } = state;

  const inviteRegistrationData = {
    agreeInd: "Y",
    email: email,
    isBenefitClaimant: isBenefitClaimant,
    personId: personId,
    activationKey: activationKey,
  };

  const setActivationCode = (value) => {
    setState({ ...state, activationKey: value });
  };

  const setEmailValue = (value) => {
    setState({ ...state, email: value });
  };

  const setErrorState = (message) => {
    setState({ ...state, error: message });
  };

  const submitActivationCode = () => {
    setState({ ...state, status: STATUS.IN_PROG });
    const data = {
      email: email,
      activationKey: activationKey,
    };

    request
      .post("/api/activation/code", data)
      .then((response) => {
        if (displayStatement) {
          setState({
            ...state,
            isCodeValid: true,
            isBenefitClaimant: response.data.benefitClaimant,
            personId: response.data.personId,
            error: "",
          });
        } else {
          history.push({
            pathname: "/invite-registration",
            state: {
              agreeInd: "N",
              email: email,
              isBenefitClaimant: response.data.benefitClaimant,
              personId: response.data.personId,
              activationKey: activationKey,
            },
          });
        }
      })
      .catch(() => {
        setState({
          ...state,
          status: STATUS.DONE,
          error: "Incorrect Activation Code and/or Email Address.",
        });
      });
  };

  useEffect(() => {
    request
      .get("/api/info/PRO_REG_CHK")
      .then(({ data: { active } }) =>
        setState({ ...state, displayStatement: active === "Y" })
      )
      .catch(console.log);
  }, []);

  if (displayStatement && isCodeValid)
    return (
      <ConfidentialityStatement inviteRegistration={inviteRegistrationData} />
    );

  return (
    <div className="flex min-h-screen">
      <Segment attached="top">
        <div className="flex-grow text-center">
          <Grid centered columns={4}>
            <Grid.Column>
              <div className="flex items-center p-10">
                <img src={impulseNexusWheel} alt="" />
              </div>
            </Grid.Column>
          </Grid>
          <div className="text-4xl font-bold mb-6">
            {t("Professional Portal - Registration - Activation Code")}
          </div>
          <Divider />
        </div>

        <Grid centered columns={3}>
          <Grid.Column>
            <Segment padded="very" attached="top">
              <div className="flex-grow text-center">
                <div className="text-1xl font-bold">{t("Welcome!")}</div>
                <div className="text-1xl mb-6 font-bold ">
                  {t(
                    "Please provide the activation code supplied in your email invite."
                  )}
                </div>
              </div>
              <Message
                error
                hidden={!error}
                onDismiss={() => setErrorState("")}
              >
                {t(error)}
              </Message>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitActivationCode();
                }}
              >
                <Form.Input
                  required
                  label={t("Activation Code")}
                  icon="calculator"
                  iconPosition="left"
                  value={activationKey}
                  placeholder={t("Please enter your activation code...")}
                  onChange={(e) => setActivationCode(e.target.value)}
                />
                <Form.Input
                  required
                  icon="user circle outline"
                  iconPosition="left"
                  label={t("Email")}
                  value={email}
                  placeholder={t("Please enter your email...")}
                  onChange={(e) => setEmailValue(e.target.value)}
                />
                <Button
                  fluid
                  type="submit"
                  positive
                  disabled={
                    status === STATUS.IN_PROG || !email || !activationKey
                  }
                  loading={status === STATUS.IN_PROG}
                >
                  {t("Confirm")}
                </Button>
              </Form>
            </Segment>
            <Segment>
              <div
                dangerouslySetInnerHTML={{
                  __html: contactFooter?.contentValue,
                }}
              />
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    </div>
  );
}

export default CodeActivationScreen;
