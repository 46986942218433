import React, { useEffect, useState } from "react";
import { Button, Form, Grid, Icon, Popup, Segment } from "semantic-ui-react";
import { useFormContext } from "react-hook-form";
import Dropdown from "../Dropdown";
import Input from "../Input";
import { lookupToOptionsTranslation, v } from "../utils";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { Trans, useTranslation } from "react-i18next";
import HelpText from "../components/HelpText";

const GET_DATA = gql`
  query {
    lookupCodes(codeTypes: [6187, 101]) {
      codeType
      codeValue
      codeDescription
    }
    info(infoId: "DEF_ETH") {
      active
      infoText
    }
  }
`;

function ReferralCase({
  searchCAV,
  searching,
  cavData,
  getHasUprn,
  getDfeeNo,
  referralId,
  methods,
  searchEstab,
  searchingEstab,
  estabChildData,
  reset,
  setReset,
}) {
  const [postcode, setPostcode] = useState("");
  const [query, setQuery] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const { data } = useQuery(GET_DATA);
  const { register, setValue } = useFormContext();
  const { t } = useTranslation();
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const chCurrEstabStartDate = date.toLocaleDateString("en-CA");

  const onSubmit = () => {
    searchCAV({
      variables: {
        houseNo,
        postcode,
      },
    });
  };

  const onSubmitEstab = () => {
    searchEstab({
      variables: {
        query,
      },
    });
  };

  if (referralId === "new") {
    let infoCode = v(data, "info", []);
    if (infoCode && infoCode.active === "Y") {
      methods.setValue("ethnicity", infoCode.infoText);
    }
  }

  if (reset) {
    methods.reset({
      chCurrEstabStartDate: null,
    });
    setReset(false);
  }

  useEffect(() => {
    if (referralId === "new") {
      methods.setValue("chCurrEstabStartDate", chCurrEstabStartDate);
    }
  }, []);

  return (
    <React.Fragment>
      <Grid>
        <Grid.Row style={{ paddingBottom: "0px" }}>
          <Grid.Column>
            <h5>
              <Trans
                t={t}
                i18nKey="All fields marked with a * are mandatory, you will not be able to submit the referral until these fields have been completed."
              >
                All fields marked with a <span style={{ color: "red" }}>*</span>{" "}
                are mandatory, you will not be able to submit the referral until
                these fields have been completed.
              </Trans>
            </h5>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            <Segment attached="top">
              <h3>
                {t("Child Details")}
                <HelpText
                  contentModule="Referrals"
                  contentType="HELP"
                  contentArea="Referrals"
                  contentItem="Case Details - Child"
                  accessLevel="Private"
                />
              </h3>
            </Segment>
            <Segment attached>
              <Form>
                <Input
                  required
                  name="chForename"
                  label={t("First Name")}
                  autoComplete="off"
                />
                <Input
                  required
                  name="chSurname"
                  label={t("Surname")}
                  autoComplete="off"
                />
                <Input
                  name="chMiddleNames"
                  label={t("Middle Names")}
                  autoComplete="off"
                />
                <Input
                  required
                  name="chBirthdate"
                  label={t("Birthdate")}
                  type="date"
                />
                <Dropdown
                  required
                  name="gender"
                  label={t("Sex")}
                  options={lookupToOptionsTranslation(
                    6187,
                    t
                  )(v(data, "lookupCodes", []))}
                />
                <Dropdown
                  name="ethnicity"
                  label={t("Ethnicity")}
                  options={lookupToOptionsTranslation(
                    101,
                    t
                  )(v(data, "lookupCodes", []))}
                />

                <input name="referralType" hidden ref={register} />
                <input name="referralId" hidden ref={register} />
                <input name="childId" hidden ref={register} />
                <input name="chPortalAddressId" hidden ref={register} />
              </Form>
            </Segment>
          </Grid.Column>
          <Grid.Column width={10}>
            <Segment attached="top">
              <h3>
                {t("Address")}
                <HelpText
                  contentModule="Referrals"
                  contentType="HELP"
                  contentArea="Referrals"
                  contentItem="Case Details - Address"
                  accessLevel="Private"
                />
              </h3>
            </Segment>
            <Segment attached>
              <Form onSubmit={onSubmit}>
                <Form.Input
                  label={t("House/Unit Number")}
                  value={houseNo}
                  onChange={(e) => setHouseNo(e.target.value)}
                />
                <Form.Field style={{ marginBottom: "0rem" }}>
                  <label>{t("Postcode")}</label>
                </Form.Field>
                <Form.Group inline>
                  <Form.Input
                    value={postcode}
                    onChange={(e) => setPostcode(e.target.value.toUpperCase())}
                  />
                  <Popup
                    trigger={
                      <Form.Button
                        icon="search"
                        type="submit"
                        style={{ backgroundColor: "#00AEEF", color: "#FFF" }}
                        disabled={searching}
                        loading={searching}
                      />
                    }
                    content={t("Search address")}
                  />
                </Form.Group>

                  <Dropdown
                      required
                      label={t("Address")}
                      name="chUprn"
                      options={v(cavData, "searchCAV", []).map(
                          ({ formatted, uprn }) => ({
                            value: uprn,
                            text: formatted,
                          })
                      )}
                      cb={(value) => {
                        const found = v(cavData, "searchCAV", []).find(
                            ({ uprn }) => uprn === value
                        );
                        if (!!found) {
                          setValue(`chHouseNo`, found.houseNo);
                          setValue(`chUnitNo`, found.unitNo);
                          setValue(`chUnitName`, found.unitName);
                          setValue(`chStreet`, found.street);
                          setValue(`chDistrict`, found.district);
                          setValue(`chTown`, found.town);
                          setValue(`chCounty`, found.county);
                          setValue(`chPostcode`, found.postcode);
                          setValue(`chEasting`, found.easting);
                          setValue(`chNorthing`, found.northing);
                          setValue(`chLeaNo`, found.leaNo);
                          setValue(`chOsapr`, found.osapr);
                          setValue(`chWardCode`, found.wardCode);
                          setValue(`chWardName`, found.wardName);
                          setValue(`chUprn`, found.uprn);
                          setValue(`chCountry`, found.country);
                          setValue(`chAzRefs`, found.azRef);
                        }
                        getHasUprn();
                      }}
                  />
                  <input name={`chHouseNo`} hidden ref={register} />
                  <input name={`chUnitNo`} hidden ref={register} />
                  <input name={`chUnitName`} hidden ref={register} />
                  <input name={`chStreet`} hidden ref={register} />
                  <input name={`chDistrict`} hidden ref={register} />
                  <input name={`chTown`} hidden ref={register} />
                  <input name={`chCounty`} hidden ref={register} />
                  <input name={`chPostcode`} hidden ref={register} />
                  <input name={`chEasting`} hidden ref={register} />
                  <input name={`chNorthing`} hidden ref={register} />
                  <input name={`chLeaNo`} hidden ref={register} />
                  <input name={`chOsapr`} hidden ref={register} />
                  <input name={`chWardCode`} hidden ref={register} />
                  <input name={`chWardName`} hidden ref={register} />
                  <input name={`chCountry`} hidden ref={register} />
                  <input name={`chAzRefs`} hidden ref={register} />
                </Form>
              </Segment>

              <Segment attached="top">
                <h3>
                  {t("School")}
                  <HelpText
                      contentModule="Referrals"
                      contentType="HELP"
                      contentArea="Referrals"
                      contentItem="Case Details - School"
                      accessLevel="Private"
                  />
                </h3>
              </Segment>
              <Segment attached>
                <Form onSubmit={onSubmitEstab}>
                  <Form.Field style={{ marginBottom: "0rem" }}>
                    <label>{t("Main School/Institution Name")}</label>
                  </Form.Field>

                  <Form.Input
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                  />
                  <Button
                      type="submit"
                      disabled={searchingEstab}
                      loading={searchingEstab}
                      style={{backgroundColor:'#00AEEF', color: '#fff',textShadow:'none'}}
                  >
                    <Icon name="search" />
                    {t("Find School")}
                  </Button>

                  <br />
                  <br />
                  <Dropdown
                      label={t("Main School/Institution")}
                      name="currEstabDfeeNo"
                      options={v(estabChildData, "searchEstab", []).map(
                          ({ dfeeNo, estabName, leaNo, establishmentUnitV }) => ({
                            value: dfeeNo,
                            text: `${estabName} LA:${leaNo} Dfe:${dfeeNo} Unit Type:${
                                establishmentUnitV
                                    ? establishmentUnitV.unitType
                                    : "MAIN"
                            }`,
                          })
                      )}
                      cb={(value) => {
                        const found = v(estabChildData, "searchEstab", []).find(
                            ({ dfeeNo }) => dfeeNo === value
                        );
                        if (!!found) {
                          setValue(`chCurrEstabDfeeNo`, found.dfeeNo);
                          setValue(
                              `chCurrEstabLeaNo`,
                              found.leaNo
                          );
                          setValue(
                              `chCurrEstabUnitType`,
                              found.establishmentUnitV
                                  ? found.establishmentUnitV.unitType
                                  : "MAIN"
                          );
                        }
                        getDfeeNo();
                      }}
                  />
                  <input name={`chCurrEstabDfeeNo`} hidden ref={register} />
                  <input name={`chCurrEstabLeaNo`} hidden ref={register} />
                  <input name={`chCurrEstabUnitType`} hidden ref={register} />
                  <Input name="chCurrEstabStartDate" label={t("Start Date")} type="date" />
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
  );
}

export default ReferralCase;
