import React, { useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import ordinal from "ordinal";
import { Button, Form, Popup, Segment } from "semantic-ui-react";
import {lookupToOptions, lookupToOptionsTranslation, v} from "../utils";
import Dropdown from "../Dropdown";
import Input from "../Input";
import { useTranslation } from 'react-i18next';

function ProfessionalInvolvedDetails({
  index,
  field,
  register,
  remove,
  setValue,
  lookupCodes,
}) {
  const { t } = useTranslation();
  const [postcode, setPostcode] = useState("");
  const [searchCAV, { loading: searching, data }] = useLazyQuery(
    gql`
      query($houseNo: String, $postcode: String!) {
        searchCAV(houseNo: $houseNo, postcode: $postcode) {
          formatted
          houseNo
          unitNo
          unitName
          street
          district
          town
          county
          postcode
          easting
          northing
          leaNo
          osapr
          wardCode
          wardName
          uprn
          country
          azRef
        }
      }
    `
  );

  const onSubmit = () => {
    searchCAV({
      variables: {
        postcode,
        index,
      },
    });
  };

  return (
    <React.Fragment>
      <Segment attached="top" color="blue">
        <h5>{ordinal(index + 1)} {t("Professional")}</h5>
      </Segment>
      <Segment attached>
        <Form key={field.id} onSubmit={onSubmit}>
          <input
            type="text"
            name={`professionals[${index}].houseNo`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].unitNo`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].unitName`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].street`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].district`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].town`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].county`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].postcode`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].easting`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].northing`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].leaNo`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].osapr`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].wardCode`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].wardName`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].uprn`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].country`}
            hidden
            ref={register}
          />
          <input
            type="text"
            name={`professionals[${index}].azRef`}
            hidden
            ref={register}
          />

          <Dropdown
            required
            label={t("Type")}
            options={lookupToOptionsTranslation(6080,t)(lookupCodes)}
            name={`professionals[${index}].type`}
            path={["professionals", index, "type"]}
          />

          <Input
            required
            label={t("Name")}
            name={`professionals[${index}].name`}
            path={["professionals", index, "name"]}
          />

          <Input
            required
            label={t("Last Seen Date")}
            name={`professionals[${index}].dateLastSeen`}
            path={["professionals", index, "dateLastSeen"]}
            type="date"
          />

          <Form.Field style={{ marginBottom: "0rem" }}>
            <label>{t("Postcode")}</label>
          </Form.Field>
          <Form.Group inline>
            <Form.Input
              value={postcode}
              onChange={(e) => setPostcode(e.target.value.toUpperCase())}
            />
            <Popup
              trigger={
                <Form.Button
                  icon="search"
                  type="submit"
                  disabled={searching}
                  loading={searching}
                  style={{backgroundColor:'#00AEEF', color:'#FFF'}}
                />
              }
              content={t("Search address")}
            />
          </Form.Group>

          <Dropdown
            label={t("Address")}
            name={`professionals[${index}].uprn`}
            options={v(data, "searchCAV", []).map(({ formatted, uprn }) => ({
              value: uprn,
              text: formatted,
            }))}
            cb={(value) => {
              const found = v(data, "searchCAV", []).find(
                ({ uprn }) => uprn === value
              );
              if (!!found) {
                setValue(`professionals[${index}].houseNo`, found.houseNo);
                setValue(`professionals[${index}].unitNo`, found.unitNo);
                setValue(`professionals[${index}].unitName`, found.unitName);
                setValue(`professionals[${index}].street`, found.street);
                setValue(`professionals[${index}].districtNo`, found.district);
                setValue(`professionals[${index}].town`, found.town);
                setValue(`professionals[${index}].county`, found.county);
                setValue(`professionals[${index}].postcode`, found.postcode);
                setValue(`professionals[${index}].easting`, found.easting);
                setValue(`professionals[${index}].northing`, found.northing);
                setValue(`professionals[${index}].leaNo`, found.leaNo);
                setValue(`professionals[${index}].osapr`, found.osapr);
                setValue(`professionals[${index}].wardCode`, found.wardCode);
                setValue(`professionals[${index}].wardName`, found.wardName);
                setValue(`professionals[${index}].uprn`, found.uprn);
                setValue(`professionals[${index}].country`, found.country);
                setValue(`professionals[${index}].azRefs`, found.azRef);
              }
            }}
          />

          <Button type="button" onClick={() => remove(index)} className={'red'}>
            {t("Remove")}
          </Button>
        </Form>
      </Segment>
    </React.Fragment>
  );
}

export default ProfessionalInvolvedDetails;
