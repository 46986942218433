import React, {useState} from "react";
import {Button, Dropdown, Form, Grid, Icon, Message, Pagination, Popup, Segment, Table,} from "semantic-ui-react";
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import {useParams} from "react-router-dom";
import classNames from "classnames";
import LoadingMessage from "../LoadingMessage";
import {useTranslation} from "react-i18next";
import HelpText from "../components/HelpText";
import {FormProvider, useForm} from "react-hook-form";
import Input from "../Input";
import {v} from "../utils";
import MainEstabInd from "../MainEstabInd";

const GET_ED_HISTORY = gql`
  query GetEdHistory(
    $pupilId: Int!
    $page: Int!
    $size: Int!
    $query: String!
  ) {
    edHistory(pupilId: $pupilId, page: $page, size: $size, query: $query) {
      number
      totalPages
      content {
        ... on EdHistory {
          leaNo
          dfeeNo
          isCurrent
          leaveDate
          mainEstabInd
          unitType
          establishmentUnitV {
            name
             address {
              addressId
              formatted
            }
          }
        }
      }
    }
  }
`;

function EducationHistory() {
  const searchForm = useForm();
  const { pupilId } = useParams();
  const [pageSize, setPageSize] = useState(10);
  const [query, setQuery] = useState("");
  const { t } = useTranslation();
  const { loading, data, fetchMore } = useQuery(GET_ED_HISTORY, {
    variables: { pupilId, page: 0, size: pageSize, query: query },
  });

  const submitSearch = ({ query }) => {
    setQuery(query);

    fetchMore({
      variables: {
        query: query,
        size: pageSize,
        page: 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          edHistory: fetchMoreResult.edHistory,
        });
      },
    });
  };

  const search = () => {
    return (
      <FormProvider {...searchForm}>
        <Form
          onSubmit={searchForm.handleSubmit(submitSearch)}
          style={{ marginBottom: 14 }}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Input name="query" label={t("School/Institution Name")} />
              </Grid.Column>
              <Grid.Column width={4} style={{ marginTop: "5" }}>
                <br />
                <Button icon color={"green"} type={"submit"}>
                  <Icon name="search" />
                </Button>
                <Popup
                    trigger={
                      <Button
                          icon
                          color={"red"}
                          type="submit"
                          onClick={() => {
                            searchForm.reset({
                              query: "",
                            });
                          }}
                      >
                        <Icon name="remove circle" />
                      </Button>
                    }
                    content={t("Clear")}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </FormProvider>
    );
  };

  const [filter, setFilter] = useState("");

  if (loading) return <LoadingMessage />;

  if (v(data, "edHistory.content", []).length <= 0)
    return (
      <div>
        <FormProvider {...searchForm}>
          <Form
            onSubmit={searchForm.handleSubmit(submitSearch)}
            style={{ marginBottom: 14 }}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Input name="query" label={t("School/Institution Name")} />
                </Grid.Column>
                <Grid.Column width={4} style={{ marginTop: "5" }}>
                  <br />
                  <Button icon color={"green"} type={"submit"}>
                    <Icon name="search" />
                  </Button>
                  <Popup
                      trigger={
                        <Button
                            icon
                            color={"red"}
                            type="submit"
                            onClick={() => {
                              searchForm.reset({
                                query: "",
                              });
                            }}
                        >
                          <Icon name="remove circle" />
                        </Button>
                      }
                      content={t("Clear")}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormProvider>
        <Message info icon color="purple">
          <Icon name="info" />
          <Message.Header>{t("No records have been found")}</Message.Header>
        </Message>
      </div>
    );

  return (
    <React.Fragment>
      <FormProvider {...searchForm}>
        <Form
          onSubmit={searchForm.handleSubmit(submitSearch)}
          style={{ marginBottom: 14 }}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Input name="query" label={t("School/Institution Name")} />
              </Grid.Column>
              <Grid.Column width={4} style={{ marginTop: "5" }}>
                <br />
                <Button icon color={"green"} type={"submit"}>
                  <Icon name="search" />
                </Button>
                <Popup
                    trigger={
                      <Button
                          icon
                          color={"red"}
                          type="submit"
                          onClick={() => {
                            searchForm.reset({
                              query: "",
                            });
                          }}
                      >
                        <Icon name="remove circle" />
                      </Button>
                    }
                    content={t("Clear")}
                />
                <HelpText
                  contentArea="Child Record"
                  contentModule="Children"
                  contentType="HELP"
                  contentItem="Education History"
                  accessLevel="Private"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </FormProvider>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("School/Institution Name")}</Table.HeaderCell>
            <Table.HeaderCell>
              {t("School/Institution Address")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("School/Institution Status")}
            </Table.HeaderCell>
            <Table.HeaderCell>{t("Is Current")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.edHistory.content
            .filter((eh) =>
              eh.establishmentUnitV.name
                .toLowerCase()
                .includes(filter.toLowerCase())
            )
            .map((eh) => (
              <Table.Row key={`${eh.leaNo}-${eh.dfeeNo}`}>
                <Table.Cell>{eh.establishmentUnitV.name}</Table.Cell>
                <Table.Cell>{eh.establishmentUnitV?.address?.formatted}</Table.Cell>
                <Table.Cell>
                  {" "}
                  <MainEstabInd value={eh.mainEstabInd} />
                </Table.Cell>
                <Table.Cell>
                  <div className="font-bold text-white">
                    <span
                      className={classNames("mr-10 text-sm rounded p-2", {
                        "bg-green-400": eh.isCurrent,
                        "bg-red-400": !eh.isCurrent,
                      })}
                    >
                      {eh.isCurrent ? t("Yes") : t("No")}
                    </span>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <br />
            <br />
            <Pagination
              boundaryRange={1}
              activePage={data.edHistory.number + 1}
              siblingRange={1}
              totalPages={data.edHistory.totalPages}
              onPageChange={(e, { activePage }) =>
                fetchMore({
                  variables: {
                    page: activePage - 1,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return Object.assign({}, prev, {
                      edHistory: fetchMoreResult.edHistory,
                    });
                  },
                })
              }
            />
          </Grid.Column>
          <Grid.Column width={2} style={{ textAlign: "center" }}>
            <label style={{ fontSize: "0.9rem", color: "#81929B" }}>
              {t("Record per Page")}
            </label>
            <Segment style={{ padding: "10px" }}>
              <Dropdown
                value={pageSize}
                name="pageSize"
                options={[
                  { value: 10, text: "10" },
                  { value: 25, text: "25" },
                  { value: 50, text: "50" },
                  { value: 100, text: "100" },
                ]}
                onChange={(_, { value }) => setPageSize(value)}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
}

export default EducationHistory;
