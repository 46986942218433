import React from "react";
import { Button, Table } from "semantic-ui-react";
import { v } from "../../utils";
import LookupCode from "../../LookupCode";
import YesNoFormatter from "../../YesNoFormatter";
import dateFormatter from "../../DateFormatter";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../../LoadingMessage";
import { gql } from "apollo-boost";
import { useToasts } from "react-toast-notifications";
import ordinal from "ordinal";
import { useTranslation } from "react-i18next";
import HelpText from "../../components/HelpText";

const GET_DATA = gql`
  {
    portalUser {
      person {
        forename
        surname
        personContacts {
          commsPreferredMethodOrder
          contactCode
          contactValue
          preferred
          alternative
          startDate
          endDate
        }
      }
    }
  }
`;

const END_DATE_PERSON_CONTACT = gql`
  mutation EndDatePersonContact(
    $contactCode: String!
    $contactValue: String!
    $startDate: LocalDate!
  ) {
    endDatePersonContact(
      contactCode: $contactCode
      contactValue: $contactValue
      startDate: $startDate
    ) {
      endDate
    }
  }
`;

function ContactList() {
  const { data, loading } = useQuery(GET_DATA);
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [endDatePersonContact, { loading: isEndDating }] = useMutation(
    END_DATE_PERSON_CONTACT,
    {
      notifyOnNetworkStatusChange: true,
      refetchQueries: [
        {
          query: gql`
            {
              portalUser {
                person {
                  personContacts {
                    commsPreferredMethodOrder
                    contactCode
                    contactValue
                    preferred
                    alternative
                    startDate
                    endDate
                  }
                }
              }
            }
          `,
        },
      ],
      onCompleted: () => {
        addToast(t("Contact end dated"), { appearance: "success" });
      },
      onError: (error) => {
        error.graphQLErrors.forEach((error) => {
          addToast(t("Contact not updated"), { appearance: "error" });
        });
      },
    }
  );

  if (loading) return <LoadingMessage />;

  return (
    <React.Fragment>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("Code")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Value")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Communication Method")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Preferred")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Alternative")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Start Date")}</Table.HeaderCell>
            <Table.HeaderCell>
              {t("End Date")}
              <HelpText
                contentModule="Profile"
                contentType="HELP"
                contentArea="Profile"
                contentItem="Contact Details"
                accessLevel="Private"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {v(data, "portalUser.person.personContacts", []).map(
            ({
              commsPreferredMethodOrder,
              contactCode,
              contactValue,
              preferred,
              alternative,
              startDate,
              endDate,
            }) => (
              <Table.Row key={`${contactCode}-${contactValue}`}>
                <Table.Cell>
                  <LookupCode codeType={5001} codeValue={contactCode} />
                </Table.Cell>
                <Table.Cell>{contactValue}</Table.Cell>
                <Table.Cell textAlign="center">
                  {!!commsPreferredMethodOrder && (
                    <span className="inline-block rounded p-1 bg-purple-400 w-12 text-center text-white font-bold tracking-wide">
                      {ordinal(commsPreferredMethodOrder)}
                    </span>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <YesNoFormatter value={preferred} />
                </Table.Cell>
                <Table.Cell>
                  <YesNoFormatter value={alternative} />
                </Table.Cell>
                <Table.Cell>{dateFormatter(startDate)}</Table.Cell>
                <Table.Cell>
                  {!!dateFormatter(endDate) ? (
                    dateFormatter(endDate)
                  ) : (
                    <Button
                      color={"red"}
                      disabled={isEndDating}
                      onClick={() =>
                        endDatePersonContact({
                          variables: {
                            contactCode,
                            contactValue,
                            startDate,
                          },
                        })
                      }
                    >
                      {t("End")}
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
      <Button className={"purple"} as={Link} to="/profile/contacts/new">
        {t("New")}
      </Button>
    </React.Fragment>
  );
}

export default ContactList;
