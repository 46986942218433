import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

function AdviceSubmitModal({
  open,
  closeModal,
  onSubmit,
  setAdviceSubmittedInd,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Modal size="small" open={open} onClose={() => closeModal(false)}>
        <Modal.Header>{t("Submit Advice")}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              {t(
                "You are about to Submit an Advice record, once this has been submitted, you will not be able to edit this record."
              )}
            </p>
            <p>{t("Do you wish to Continue?")}</p>
          </Modal.Description>
        </Modal.Content>

        <Modal.Actions>
          <Button color={"orange"} onClick={() => closeModal(false)}>
            {t("Cancel")}
          </Button>
          <Button
            color={"green"}
            onClick={() => {
              setAdviceSubmittedInd(true);
              onSubmit();
              closeModal(false);
            }}
          >
            {t("Continue")}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default AdviceSubmitModal;
