import React, { useEffect } from "react";
import { Button, Form, Segment, Table } from "semantic-ui-react";
import Input from "../Input";
import TextArea from "../TextArea";
import FileInput from "../FileInput";
import {
  base64ToArrayBuffer,
  createAndDownloadBlobFile,
  formatSize,
  v,
} from "../utils";
import { Trans, useTranslation } from "react-i18next";
import HelpText from "../components/HelpText";

function Notes({ files, additionalInfo }) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <h5>
        <Trans
          t={t}
          i18nKey="All fields marked with a * are mandatory, you will not be able to submit the referral until these fields have been completed."
        >
          All fields marked with a <span style={{ color: "red" }}>*</span> are
          mandatory, you will not be able to submit the referral until these
          fields have been completed.
        </Trans>
      </h5>
      <Segment attached="top">
        <h3>
          {t("Notes")}
          <HelpText
            contentModule="Referrals"
            contentType="HELP"
            contentArea="Referrals"
            contentItem="Referral Notes"
            accessLevel="Private"
          />
        </h3>
      </Segment>
      <Segment attached>
        <Form>
          <Input
            required={additionalInfo?.info?.active === "Y"}
            name="noteTitle"
            label={t("Title")}
          />
          <TextArea
            required={additionalInfo?.info?.active === "Y"}
            name="noteHtml"
            label={t("Notes/Comments")}
          />
          <Segment attached="bottom">
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t("ID")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("File")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Type")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Size")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("Actions")}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {v(files, "portalNoteDocuments", []).map((document) => (
                  <Table.Row key={document.id}>
                    <Table.Cell>{document.files.id}</Table.Cell>
                    <Table.Cell>{document.files.filename}</Table.Cell>
                    <Table.Cell>{document.files.application}</Table.Cell>
                    <Table.Cell>
                      {formatSize(document.files.docSize)}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        style={{ backgroundColor: "#00AEEF", color: "#FFF" }}
                        onClick={() => {
                          createAndDownloadBlobFile(
                            base64ToArrayBuffer(document.files.base64),
                            document.files.filename,
                            document.files.mimeType
                          );
                        }}
                        className={"light-blue"}
                      >
                        {t("Download")}
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Segment>
          <FileInput name="files" label="File" removeAllFilesButton={false} />
        </Form>
      </Segment>
    </React.Fragment>
  );
}

export default Notes;
