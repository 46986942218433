import React from "react";
import { Menu } from "semantic-ui-react";
import { NavLink, Route, Switch } from "react-router-dom";
import ContactDetailsScreen from "./contacts/ContactDetailsScreen";
import CommunicationPreferences from "./communications/CommunicationPreferences";
import AddressList from "./address/AddressList";
import ProfileDetails from "./profile/ProfileDetails";
import {useTranslation} from "react-i18next";

function Profile() {
  const { t } = useTranslation();
  return (
    <>
      <Menu pointing secondary>
        <Menu.Item as={NavLink} to="/profile" exact>
          {t("Profile")}
        </Menu.Item>
        <Menu.Item as={NavLink} to="/profile/address" exact>
          {t("Address")}
        </Menu.Item>
        <Menu.Item as={NavLink} to="/profile/contacts">
          {t("Contact Details")}
        </Menu.Item>
        <Menu.Item as={NavLink} to="/profile/communication-preferences">
          {t("Communication Preferences")}
        </Menu.Item>
      </Menu>
      <Switch>
        <Route path="/profile" exact>
          <ProfileDetails />
        </Route>
        <Route path="/profile/address">
          <AddressList />
        </Route>
        <Route path="/profile/contacts">
          <ContactDetailsScreen />
        </Route>
        <Route path="/profile/communication-preferences">
          <CommunicationPreferences />
        </Route>
      </Switch>
    </>
  );
}

export default Profile;
