import { useContext } from "react";
import { PublicPortalContext } from "../PortalContent";

export const usePublicPortalContent = ({
  contentModule,
  contentType,
  contentArea,
  contentItem,
  accessLevel
}) => {
  const { publicContent, fetchPortalContent } = useContext(PublicPortalContext);
  const key = `${contentModule}-${contentType}-${contentArea}-${contentItem}`;
  const content = { data: {} };

  if (accessLevel === "Public") {
    if (key in publicContent) {
      return { data: publicContent[key] };
    } else if (!!fetchPortalContent) {
      fetchPortalContent({
        contentModule,
        contentType,
        contentArea
      });
    }
  }
  return content;
};
