import React, { useState } from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { useParams, Route, NavLink } from "react-router-dom";
import LoadingMessage from "../LoadingMessage";
import {
  Grid,
  Button,
  Icon,
  Message,
  Segment,
  Table,
  Pagination,
  Dropdown,
  Form,
  Popup,
} from "semantic-ui-react";
import { lookupToOptions, v } from "../utils";
import LookupCode from "../LookupCode";
import { mergeDeepRight, sort } from "ramda";
import ContributionForm from "./ContributionForm";
import { useTranslation } from "react-i18next";
import HelpText from "../components/HelpText";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../Input";

const GET_CONTRIBUTIONS = gql`
  query Contributions(
    $pupilId: Int!
    $page: Int!
    $size: Int!
    $name: String
    $contributionDate: LocalDate
  ) {
    pupil(pupilId: $pupilId) {
      serviceDefinition {
        contributions {
          id
          contributionDate
          type
          method
          module
          text
          files {
            id
            filename
            base64
            docSize
            mimeType
            application
          }
        }
        contributionGroupListPage(
          page: $page
          size: $size
          name: $name
          contributionDate: $contributionDate
        ) {
          number
          totalPages
          content {
            ... on ContributionDTO {
              name
              contributionDate
              contributionGroupId
            }
          }
        }
      }
    }
    contributionGroups {
      id
      name
      description
      method
      module
      contributionGroupItems {
        id
        contributionGroupId
        orderSeq
        contributionType
        allowAttach
        allowText
      }
    }
    lookupCodes(codeTypes: [6051, 6050]) {
      codeValue
      codeDescription
    }
  }
`;

function ContributionGroups({
  pageSize,
  setPageSize,
  contributionGroups,
  fetchMore,
  submitSearch,
  searchForm,
  Dropdowns,
  contributionGroupsList,
}) {
  const { pupilId } = useParams();
  const { t } = useTranslation();

  // Transforms a collection of contributions into a
  // collection of items uniquely identified by the group ID
  // and contribution date.

  return (
    <div>
      <FormProvider {...searchForm}>
        <Form
          onSubmit={searchForm.handleSubmit(submitSearch)}
          style={{ marginBottom: 14 }}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Dropdowns
                  name="name"
                  label={t("Contribution Group")}
                  options={contributionGroupsList.map(({ name }) => ({
                    value: name,
                    text: name,
                  }))}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Input
                  label={t("Contribution Date")}
                  name={"contributionDate"}
                  type="date"
                />
              </Grid.Column>
              <Grid.Column width={4} style={{ marginTop: "5" }}>
                <br />
                <Button icon color={"green"} type={"submit"}>
                  <Icon name="search" />
                </Button>
                <Popup
                  trigger={
                    <Button
                      icon
                      color={"red"}
                      type="submit"
                      onClick={() => {
                        searchForm.reset({
                          name: "",
                          contributionDate: "",
                        });
                      }}
                    >
                      <Icon name="remove circle" />
                    </Button>
                  }
                  content={t("Clear")}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </FormProvider>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("Contribution Group")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Contribution Date")}</Table.HeaderCell>
            <Table.HeaderCell>
              {t("Actions")}
              <HelpText
                contentModule="Cases"
                contentType="HELP"
                contentArea="Case"
                contentItem="Contributions List"
                accessLevel="Private"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {contributionGroups?.content.map((group) => {
            return (
              <Table.Row
                key={`${group.contributionGroupId}-${group.contributionDate}`}
              >
                <Table.Cell>{group.name}</Table.Cell>
                <Table.Cell>
                  {new Date(group.contributionDate).toLocaleDateString("en-GB")}
                </Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    style={{ backgroundColor: "#0054A4", color: "#FFF" }}
                    as={NavLink}
                    className={"blue"}
                    to={`/cases/${pupilId}/contributions/${group.contributionGroupId}/${group.contributionDate}`}
                    activeClassName="primary disabled"
                  >
                    {t("View")}
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <br />
            <br />
            <Pagination
              boundaryRange={1}
              activePage={contributionGroups?.number + 1}
              siblingRange={1}
              totalPages={contributionGroups?.totalPages}
              onPageChange={(e, { activePage }) =>
                fetchMore({
                  variables: {
                    page: activePage - 1,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;

                    return mergeDeepRight(prev, {
                      pupil: {
                        serviceDefinition: {
                          contributionGroupListPage:
                            fetchMoreResult.pupil.serviceDefinition
                              .contributionGroupListPage,
                        },
                      },
                    });
                  },
                })
              }
            />
          </Grid.Column>
          <Grid.Column width={2} style={{ textAlign: "center" }}>
            <label style={{ fontSize: "0.9rem", color: "#81929B" }}>
              {t("Record per Page")}
            </label>
            <Segment style={{ padding: "10px" }}>
              <Dropdown
                value={pageSize}
                name="pageSize"
                options={[
                  { value: 10, text: "10" },
                  { value: 25, text: "25" },
                  { value: 50, text: "50" },
                  { value: 100, text: "100" },
                ]}
                onChange={(_, { value }) => setPageSize(value)}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

function Contributions({ contributions }) {
  const { contributionGroupId, contributionDate } = useParams();

  const matchingContributions = contributions
    .filter(
      (contribution) =>
        contribution.contributionGroupId === parseInt(contributionGroupId)
    )
    .filter(
      (contribution) => contribution.contributionDate === contributionDate
    );

  const sortedContributions = sort(
    (a, b) => a.order - b.order,
    matchingContributions
  );

  return (
    <Grid>
      {sortedContributions.map((contribution) => (
        <Grid.Row key={contribution.id}>
          <Grid.Column>
            <Segment attached="top" style={{ backgroundColor: "#f9fafb" }}>
              <span className="font-bold">
                <LookupCode codeType={6050} codeValue={contribution.type} />
              </span>
            </Segment>
            <Segment attached>
              <ContributionForm contribution={contribution} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  );
}

function ContributionsScreen({ Dropdowns }) {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const [name, setName] = useState("");
  const [contributionDate, setContributionDate] = useState(null);

  const { pupilId } = useParams();
  const searchForm = useForm();

  const submitSearch = ({ name, contributionDate }) => {
    setName(name);
    setContributionDate(contributionDate);
    fetchMore({
      variables: {
        pupilId,
        size: pageSize,
        page: 0,
        name: name ? name : "",
        contributionDate: contributionDate ? contributionDate : null,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return mergeDeepRight(prev, {
          pupil: {
            serviceDefinition: {
              contributionGroupListPage:
                fetchMoreResult.pupil.contributionGroupListPage,
            },
          },
        });
      },
      notifyOnNetworkStatusChange: true,
    });
  };

  const { loading, data, fetchMore } = useQuery(GET_CONTRIBUTIONS, {
    variables: {
      pupilId,
      size: pageSize,
      page: 0,
      name: name,
      contributionDate: contributionDate ? contributionDate : null,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading) return <LoadingMessage />;

  if (
    v(data, "pupil.serviceDefinition.contributionGroupListPage.content", [])
      .length <= 0
  )
    return (
      <div>
        <FormProvider {...searchForm}>
          <Form
            onSubmit={searchForm.handleSubmit(submitSearch)}
            style={{ marginBottom: 14 }}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Dropdowns
                    name="name"
                    label={t("Contribution Group")}
                    options={v(data, "contributionGroups", []).map(
                      ({ name }) => ({
                        value: name,
                        text: name,
                      })
                    )}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Input
                    label={t("Contribution Date")}
                    name={"contributionDate"}
                    type="date"
                  />
                </Grid.Column>
                <Grid.Column width={4} style={{ marginTop: "5" }}>
                  <br />
                  <Button icon color={"green"} type={"submit"}>
                    <Icon name="search" />
                  </Button>
                  <Popup
                    trigger={
                      <Button
                        icon
                        color={"red"}
                        type="submit"
                        onClick={() => {
                          searchForm.reset({
                            name: "",
                            contributionDate: "",
                          });
                        }}
                      >
                        <Icon name="remove circle" />
                      </Button>
                    }
                    content={t("Clear")}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormProvider>
        <Message info icon color="purple">
          <Icon name="info" />
          <Message.Header>{t("No records have been found")}</Message.Header>
        </Message>
      </div>
    );

  const contributionGroups = v(data, "contributionGroups", []);

  // Append the group id (0 if not in a group)
  // Append the group name (No Group if not in a group)
  // Append allow text (Y if not in a group)
  // Append allow attach (Y if not in a group)
  const contributions = v(
    data,
    "pupil.serviceDefinition.contributions",
    []
  ).map((contribution) => {
    const contributionGroupItem = contributionGroups
      .filter((cg) => cg.module === contribution.module)
      .filter((cg) => cg.method === contribution.method)
      .flatMap((cg) => cg.contributionGroupItems)
      .find((cgi) => cgi.contributionType === contribution.type);

    return Object.assign(
      {
        contributionGroupId: !!contributionGroupItem
          ? contributionGroupItem.contributionGroupId
          : 0,
        order: !!contributionGroupItem ? contributionGroupItem.orderSeq : 0,
        allowText: !!contributionGroupItem
          ? contributionGroupItem.allowText
          : "Y",
        allowAttach: !!contributionGroupItem
          ? contributionGroupItem.allowAttach
          : "Y",
        groupName: !!contributionGroupItem
          ? contributionGroups.find(
              (cg) =>
                parseInt(cg.id) === contributionGroupItem.contributionGroupId
            ).name
          : "No Group",
      },
      contribution
    );
  });

  return (
    <>
      <ContributionGroups
        contributions={contributions}
        contributionGroupsList={v(data, "contributionGroups", [])}
        contributionGroups={v(
          data,
          "pupil.serviceDefinition.contributionGroupListPage",
          []
        )}
        setPageSize={setPageSize}
        pageSize={pageSize}
        fetchMore={fetchMore}
        submitSearch={submitSearch}
        searchForm={searchForm}
        Dropdowns={Dropdowns}
      />
      <Route path="/cases/:pupilId/contributions/:contributionGroupId/:contributionDate">
        <Contributions contributions={contributions} />
      </Route>
    </>
  );
}

export default ContributionsScreen;
