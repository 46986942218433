import React, { useState } from "react";
import {Icon, Modal} from "semantic-ui-react";
import { useTranslation } from 'react-i18next';

function DeleteConfirmationModal({ children, onDelete }) {
  const [open, setOpen] = useState(false);
    const { t } = useTranslation();

  return (
    <>
      <Modal
        size="mini"
        open={open}
        onClose={() => setOpen(false)}
        trigger={<span onClick={() => setOpen(true)}>{children}</span>}
      >
        <div className="bg-white p-8 text-xl font-black tracking-wide">
            {t("Are you sure you want to delete this record ?")}
        </div>
        <div className="flex justify-end bg-gray-100 p-5">
          <div
            className="p-2 mr-6 bg-orange-500 text-white hover:bg-orange-600 rounded cursor-pointer shadow"
            onClick={() => setOpen(false)}
          >
              <Icon name="remove circle" />
              {t("Cancel")}
          </div>
          <div
            className="p-2 rounded bg-red-500 text-white hover:bg-red-600 cursor-pointer shadow"
            onClick={() => {
              onDelete();
              setOpen(false);
            }}
          >
              <Icon name="trash alternate" />
              {t("Delete")}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DeleteConfirmationModal;
