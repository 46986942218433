import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import LookupCode from "../LookupCodeTranslation";
import { useTranslation } from "react-i18next";

function CommunicationPreferenceDetails({ contacts, addresses }) {
  const { t } = useTranslation();
  const [contact, setContact] = useState("");

  const [createCommsPreferredMethod, { loading: creating }] = useMutation(
    gql`
      mutation CreateCommsPreferredMethod(
        $prefType: String!
        $lookupCodeType: Int!
      ) {
        createCommsPreferredMethod(
          prefType: $prefType
          lookupCodeType: $lookupCodeType
        ) {
          id
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: gql`
            {
              portalUser {
                person {
                  serviceDefinition {
                    commsPreferredMethods {
                      id
                      prefOrder
                      prefType
                      lookupCodeType
                    }
                  }
                }
              }
            }
          `,
        },
      ],
    }
  );

  const contactOptions = contacts.map((contact) => ({
    key: `5001-${contact}`,
    text: <LookupCode codeValue={contact} codeType={5001} t={t} />,
    value: `5001-${contact}`,
  }));

  const addressOptions = addresses.map((address) => ({
    key: `5002-${address}`,
    text: <LookupCode codeValue={address} codeType={5002} t={t} />,
    value: `5002-${address}`,
  }));

  return (
    <div className="bg-white p-4 rounded mt-4">
      <Form
        onSubmit={(e) => {
          e.preventDefault();

          const parts = contact.split("-");
          const lookupCodeType = parseInt(parts[0], 10);
          const prefType = parts[1];

          createCommsPreferredMethod({
            variables: { prefType, lookupCodeType },
          });
        }}
      >
        <Form.Dropdown
          selection
          search
          label={t("Contact Type")}
          options={contactOptions.concat(addressOptions)}
          value={contact}
          onChange={(_, { value }) => setContact(value)}
        />
        <Button
          as={Link}
          className={'orange'}
          to="/profile/communication-preferences"
          disabled={creating}
        >
          {t("Cancel")}
        </Button>
        <Button className={'green'} disabled={creating || !contact} loading={creating}>
          {t("Save")}
        </Button>
      </Form>
    </div>
  );
}

export default CommunicationPreferenceDetails;
