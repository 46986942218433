import React, { useEffect, useState } from "react";
import Input from "./Input";
import {
  Button,
  Container,
  Divider,
  Form,
  Icon,
  Message,
  Modal,
  Table,
} from "semantic-ui-react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";
import { capitalise, lookupToOptionsTranslation } from "./utils";
import request from "./request";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import LanguageList from "./LanguageList";
import HelpText from "./components/HelpText";
import { useLocation, useHistory } from "react-router-dom";

const searchEstabValidations = yup.object({
  estabNameQuery: yup
    .string()
    .required("Please enter a School name")
    .min(2, "Please enter at least 2 characters"),
});

const searchAddressValidations = yup.object({
  postcode: yup
    .string()
    .required("Please enter a valid postcode")
    .min(3, "The postcode must be at least 3 characters long"),
});

const searchAgencyValidations = yup.object({
  agencyName: yup
    .string()
    .required("Please enter an organisation name")
    .min(2, "Please enter at least 2 characters"),
});

const validations = yup.object({
  title: yup.string().required("Required"),
  firstName: yup
    .string()
    .required("Required")
    .max(30, "30 character limit exceeded"),
  lastName: yup
    .string()
    .required("Required")
    .max(30, "30 character limit exceeded"),
  organisationName: yup.string().required("Required"),
  role: yup.string().required("Required"),
  startDate: yup.string().required("Required"),
  address: yup.string().required("Please search an address by postcode"),
  email: yup.string().required("Required").email("Please enter a valid email"),
  mobileNumber: yup
      .string()
      .required("Please enter a mobile number")
      .test(
          "is-integer",
          "Digits only, no spaces or special characters",
          (value) => /^\d+$/.test(value)
      )
      .length(11, "Must be 11 digits long"),
  password: yup
    .string()
    .required("Required")
    .min(6, "Your password must be at least 6 characters long"),
  confirmPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

function SearchAddress({ setAddresses }) {
  const { t } = useTranslation();
  const searchCAVForm = useForm({
    resolver: yupResolver(searchAddressValidations),
  });

  const [isSearchingCAV, setIsSearchingCAV] = useState(false);

  const searchCAV = ({ postcode }) => {
    setIsSearchingCAV(true);
    request
      .get("/api/cav", { params: { postcode } })
      .then(({ data }) => {
        setIsSearchingCAV(false);
        setAddresses(data);
      })
      .catch((error) => {
        setIsSearchingCAV(false);
        console.error(error);
      });
  };

  return (
    <FormProvider {...searchCAVForm}>
      <Form
        onSubmit={searchCAVForm.handleSubmit(searchCAV)}
        style={{ marginBottom: 14 }}
      >
        <Input name="postcode" label={t("Postcode")} />
        <Button style={{backgroundColor:'#00AEEF', color: '#fff',textShadow:'none'}} disabled={isSearchingCAV} loading={isSearchingCAV}>
          <Icon name="search" />
          {t("Find Address")}
        </Button>
      </Form>
    </FormProvider>
  );
}

function SearchAgency({ setAgencies }) {
  const { t } = useTranslation();
  const searchAgencyForm = useForm({
    resolver: yupResolver(searchAgencyValidations),
  });

  const [isSearchingAgencies, setIsSearchingAgencies] = useState(false);

  const searchAgency = ({ agencyName }) => {
    setIsSearchingAgencies(true);
    request
      .get("/api/agencies", { params: { search: agencyName } })
      .then(({ data }) => {
        setIsSearchingAgencies(false);
        setAgencies(data);
      })
      .catch((error) => {
        setIsSearchingAgencies(false);
        console.error(error);
      });
  };

  return (
    <FormProvider {...searchAgencyForm}>
      <Form
        onSubmit={searchAgencyForm.handleSubmit(searchAgency)}
        style={{ marginBottom: 14 }}
      >
        <Input name="agencyName" label={t("Organisation Name")} />
        <Button style={{backgroundColor:'#00AEEF', color: '#fff',textShadow:'none'}} disabled={isSearchingAgencies} loading={isSearchingAgencies}>
          <Icon name="search" />
          {t("Find Organisation")}
        </Button>
      </Form>
    </FormProvider>
  );
}

function SearchEstab({ setEstabs, setEstabListModalOpen }) {
  const { t } = useTranslation();
  const searchEstabForm = useForm({
    resolver: yupResolver(searchEstabValidations),
  });

  const [isSearchingEstabs, setIsSearchingEstabs] = useState(false);

  const searchEstab = ({ estabNameQuery }) => {
    setIsSearchingEstabs(true);
    request
      .get("/api/search-estab", { params: { query: estabNameQuery } })
      .then(({ data }) => {
        setIsSearchingEstabs(false);
        setEstabs(data);
        setEstabListModalOpen(true);
      })
      .catch((error) => {
        setIsSearchingEstabs(false);
        console.error(error);
      });
  };

  return (
    <FormProvider {...searchEstabForm}>
      <Form
        onSubmit={searchEstabForm.handleSubmit(searchEstab)}
        style={{ marginBottom: 14 }}
      >
        <Input name="estabNameQuery" label={t("School/Institution Name")} />
        <Button style={{backgroundColor:'#00AEEF', color: '#fff',textShadow:'none'}} disabled={isSearchingEstabs} loading={isSearchingEstabs}>
          <Icon name="search" />
          {t("Find School")}
        </Button>
      </Form>
    </FormProvider>
  );
}

const STATUS = {
  INIT: 0,
  IN_PROG: 1,
  DONE: 2,
  ERROR: 3,
};

function RegisterScreen() {
  const { t } = useTranslation();
  const form = useForm({
    resolver: yupResolver(validations),
  });

  const [titles, setTitles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [status, setStatus] = useState(STATUS.INIT);
  const [isLoadingAgencies, setIsLoadingAgencies] = useState(false);
  const [estabs, setEstabs] = useState([]);
  const [isEstabListModalOpen, setEstabListModalOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const transformData = (rows) => {
    return rows.map((row) => {
      return {
        leaNo: row.leaNo,
        dfeeNo: row.dfeeNo,
        estabName: row.estabName,
        alternativeName: row.alternativeName,
        serviceId: row.serviceId,
        addressText:
          capitalise(
            (row.unitNo ? row.unitNo + ", " : "") +
              (row.unitName ? row.unitName + ", " : "") +
              (row.houseNo ? row.houseNo + " " : "") +
              (row.street ? row.street : "") +
              (row.mainRoad ? ", " + row.mainRoad : "") +
              (row.district ? ", " + row.district : "") +
              (row.town ? ", " + row.town : "") +
              (row.county ? ", " + row.county : "")
          ) + (row.postcode ? ", " + row.postcode : ""),
      };
    });
  };

  useEffect(() => {
    request
      .get("/api/info/PRO_REG_CHK")
      .then(({ data: { active } }) => {
        if (active === 'Y' && location.state?.agreeInd !== 'Y') {
          history.push('/registration-confidentiality');
        }
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    request
      .get("/api/lookup-codes/103")
      .then(({ data }) => setTitles(data))
      .catch(console.error);

    request
      .get("/api/lookup-codes/111")
      .then(({ data }) =>
        setRoles(data.filter((role) => role.redundant === "N"))
      )
      .catch(console.error);
  }, []);

  const onSubmit = (values) => {
    setStatus(STATUS.IN_PROG);
    request
      .post("/api/register", { ...values, agreeInd: location.state?.agreeInd || 'N' })
      .then(() => {
        setStatus(STATUS.DONE);
      })
      .catch((e) => {
        if (
          e.response.status !== 400 &&
          e.response.data.field === undefined &&
          e.response.data.error === undefined
        ) {
          setStatus(STATUS.ERROR);
        } else {
          setStatus(STATUS.INIT);
          form.setError(e.response.data.field, {
            type: "server",
            message: e.response.data.error,
          });
        }
      });
  };

  if (status === STATUS.DONE) {
    return (
      <Container as={Link} to="/login" className="mt-5">
        <Message icon success>
          <Icon name="check" />
          <Message.Content>
            <Message.Header>{t("Account Created")}</Message.Header>
            {t(" Please check your email for an activation link.")}
          </Message.Content>
          <Divider />
          <LanguageList />
        </Message>
      </Container>
    );
  }

  return (
    <div className="flex">
      <div className="flex items-center min-h-screen bg-white w-1/3">
        <div className="flex-grow p-16">
          <div className="text-4xl font-bold mb-6">
            {t("Professionals Portal")}
            <HelpText
              contentModule="Login"
              contentType="HELP"
              contentArea="Login"
              contentItem="Registration"
              accessLevel="Public"
            />
          </div>
          {status === STATUS.ERROR && (
            <Message icon error>
              <Icon name="warning" />
              <Message.Content>
                <Message.Header>
                  {t("Account Registration Failed")}
                </Message.Header>
                {t("Unable to register an account, please try again.")}
              </Message.Content>
            </Message>
          )}
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
              <input
                type="text"
                hidden
                ref={form.register}
                name="estabServiceId"
              />
              <Dropdown
                name="title"
                label={t("Title")}
                options={lookupToOptionsTranslation(103, t)(titles)}
              />
              <Input name="firstName" label={t("First Name")} />
              <Input name="lastName" label={t("Surname")} />
              <button type="submit" hidden />
            </Form>
          </FormProvider>
          <SearchAgency setAgencies={setAgencies} />
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
              <Dropdown
                loading={isLoadingAgencies}
                name="organisationName"
                label={t("Organisation")}
                options={agencies.map((agency) => ({
                  value: agency.agencyId,
                  text: `${agency.agencyName}${
                    agency.address ? ` (${agency.address.formatted})` : ""
                  }`,
                }))}
              />
              <Dropdown
                name="role"
                label={t("Role")}
                options={lookupToOptionsTranslation(111, t)(roles)}
              />
              <Input name="startDate" label={t("Start Date")} type="date" />
              <button type="submit" hidden />
            </Form>
          </FormProvider>
          <SearchAddress setAddresses={setAddresses} />
          <FormProvider {...form}>
            <Form
              style={{ marginBottom: "14px" }}
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <Dropdown
                name="address"
                label={t("Address")}
                options={addresses.map(({ formatted, uprn }) => ({
                  value: uprn,
                  text: formatted,
                }))}
              />
            </Form>
          </FormProvider>
          <SearchEstab
            setEstabs={setEstabs}
            setEstabListModalOpen={setEstabListModalOpen}
          />
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
              <Input
                name="estabName"
                ref={form.register}
                label={t("School/Institution")}
                type="text"
                readOnly
              />
              <Input name="email" label={t("Email")} />
              <Input name="mobileNumber" label={t("Mobile Number")} />
              <Input name="password" label={t("Password")} type="password" />
              <Input
                name="confirmPassword"
                label={t("Confirm Password")}
                type="password"
              />
              <Button
                disabled={status === STATUS.IN_PROG}
                loading={status === STATUS.IN_PROG}
                style={{backgroundColor:'#0054A4', color:'#FFF'}}
              >
                {t("Send Request")}
              </Button>
              <Button className={'orange'} as={Link} to="/login">
                {t("Cancel")}
              </Button>
              <Modal open={isEstabListModalOpen} size="fullscreen" scrolling>
                <Modal.Header>{t("Select School/Institution")}</Modal.Header>
                <Modal.Content scrolling>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                        <Table.HeaderCell>
                          {t("Alternative Name")}
                        </Table.HeaderCell>
                        <Table.HeaderCell>{t("Lea No")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("DfE No")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Address")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Select")}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {transformData(estabs).map((estab) => (
                        <Table.Row key={estab.serviceId}>
                          <Table.Cell>{estab.estabName}</Table.Cell>
                          <Table.Cell>{estab.alternativeName}</Table.Cell>
                          <Table.Cell>{estab.leaNo}</Table.Cell>
                          <Table.Cell>{estab.dfeeNo}</Table.Cell>
                          <Table.Cell>{estab.addressText}</Table.Cell>
                          <Table.Cell collapsing>
                            <Button
                              icon
                              onClick={() => {
                                form.setValue(
                                  "estabServiceId",
                                  estab.serviceId
                                );
                                form.setValue("estabName", estab.estabName);
                                setEstabListModalOpen(false);
                              }}
                              positive
                            >
                              <Icon name="check" />
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    onClick={() => {
                      setEstabListModalOpen(false);
                    }}
                    className={"orange"}
                  >
                    <Icon name="arrow circle left" />
                    {"Cancel"}
                  </Button>
                </Modal.Actions>
              </Modal>
            </Form>
          </FormProvider>
          <Divider />
          <LanguageList />
        </div>
      </div>
      <div className="flex items-center bg-purple-100 w-2/3 p-16 min-h-screen">
        <img src="/fatherhood.svg" alt="" />
      </div>
    </div>
  );
}

export default RegisterScreen;
